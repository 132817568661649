import React, { useEffect, useRef, useCallback } from 'react';
import ReactDOM from 'react-dom';
import BasePortal, { BasePortalProps } from './base';
import { getAnimation, AnimationConfig } from '@utils/animation-util';

let _rootDom: HTMLElement | null = null;
const noop = () => null;

export type PortalProps = BasePortalProps & {
	content?: React.ReactNode | ((onDestroy: () => void) => React.ReactNode);
	animateType?: string;
	animationClass?: { start: string; end: string };
	getContainer?: () => HTMLElement | null | undefined;
};

function getRootDom() {
	if (!_rootDom) {
		_rootDom = document.getElementById('app');
	}
	return _rootDom as HTMLElement;
}

function createContainer() {
	const div = document.createElement('div');
	return div;
}

function usePortal(props: PortalProps): [React.MutableRefObject<HTMLElement | null>, () => void] {
	const target = useRef<HTMLElement | null>(null);
	const destroy = useCallback(() => {
		typeof props.onDestroy === 'function' && props.onDestroy();
		if (target.current) {
			target.current.remove && target.current.remove();
			target.current = null;
		}
	}, [props.onDestroy]);

	if (!target.current) {
		target.current = createContainer();
	}

	useEffect(() => {
		const rootDom = props.getContainer ? props.getContainer() || getRootDom() : getRootDom();
		if (rootDom && target.current) {
			rootDom.appendChild(target.current);
		}
		return destroy;
	}, []);

	return [target, destroy];
}

/**
 * 渲染组件至 root，参数见 BasePortal.propTypes
 */
export default function Portal(props: PortalProps) {
	const [container, destroy] = usePortal(props);
	if (!container.current) {
		return null;
	}

	return ReactDOM.createPortal(<BasePortal {...props} onDestroy={destroy} />, container.current);
}

/**
 * 命令式地展示一个 portal，返回销毁方法，参数见 BasePortal.propTypes
 * @param {ReactNode} content
 * @param {function} onDestroy
 * @returns {function} destroy
 */
Portal.show = (props: PortalProps) => {
	props = props || {};
	props.children = props.content;
	let div: HTMLElement | null = createContainer();

	const rootDom = props.getContainer ? props.getContainer() || getRootDom() : getRootDom();
	const destroy = (params?: any) => {
		typeof props.onDestroy === 'function' && props.onDestroy(params);
		if (div) {
			ReactDOM.unmountComponentAtNode(div);
			try {
				div.remove && div.remove();
			} catch (err) {
				console.error('portal remove error', err);
			}
		}
		div = null;
	};
	ReactDOM.render(<BasePortal {...props} onDestroy={destroy} />, rootDom.appendChild(div));
	return destroy;
};

const hyperCreate = <T extends PortalProps>(showFunc: (props: T) => () => void) => () => {
	let _destroy: (() => void) | null = null;
	const destroy = () => {
		if (typeof _destroy === 'function') {
			_destroy();
			_destroy = null;
		}
	};

	const show = (props: T) => {
		destroy();
		_destroy = showFunc(props || {});
	};

	return {
		show,
		destroy,
	};
};

/**
 * 命令式地创建一个 portal 对象，返回 show 和 destroy 两个方法
 */
Portal.create = hyperCreate(Portal.show);

/** 自动注入的 props */
type InjectedProps = {
	onDestroy: () => void;
	animationClass: AnimationConfig;
};

/** 被包裹的组件自身的 props */
type OwnProps<T> = Omit<T, keyof InjectedProps>;

/**
 * 将任意一个组件转为 portal 形式，同时支持 jsx 声明式、命令式两种调用方式
 */
export function withPortal<U>(Component: React.ComponentType<OwnProps<U>>) {
	function WithPortal(props: OwnProps<U> & PortalProps) {
		const { animateType = '' } = props;
		const animationClass = getAnimation(animateType);
		return (
			<Portal onDestroy={props.onDestroy || noop}>
				{(onDestroy) => <Component onDestroy={onDestroy} animationClass={animationClass} {...props} />}
			</Portal>
		);
	}

	WithPortal.show = (props: OwnProps<U> & PortalProps) => {
		props = props || {};
		props.children = props.content;
		const { animateType = '' } = props;
		const animationClass = getAnimation(animateType);

		return Portal.show({
			onDestroy: props.onDestroy,
			content: (onDestroy) => <Component {...props} animationClass={animationClass} onDestroy={onDestroy} />,
			withStore: props.withStore,
		});
	};

	WithPortal.create = hyperCreate(WithPortal.show);
	return WithPortal;
}
