import { State } from 'jumpstate';

/**
 * effect 请求 loading态,error态
 */
export const EFFECT_STATUS = {
	start: 'START',
	success: 'SUCCESS',
	loading: 'LOADING',
	error: 'ERROR',
	empty: 'EMPTY',
};

export type EffectStatusState = Record<string, string>;

const initial: EffectStatusState = {};

const effectStatus = State('effectStatus', {
	initial,
	setStatus(state: EffectStatusState, payload: EffectStatusState) {
		return {
			...state,
			...payload,
		};
	},
});

export default effectStatus;

const setName = (name: string) => (val: string) => effectStatus.setStatus({ [name]: val });

// 保存所有func名 重名提示
const globalFuncName = new Set();

/**
 *
 * @param {*} funcName 请求function名字 全局要求不能重复 如重复覆盖执行
 * @param {*} fn 方法主体 不能写try catch
 * @param {*} logger 替换原生log方法
 */
export function EffectStatus(funcName: string, fn: Function, logger: any = console, delay = 250) {
	const log = logger || console;

	if (!funcName) {
		log.error('func name is require');
		return fn;
	}

	if (globalFuncName.has(funcName)) {
		log.error('func name is same', funcName);
	}

	globalFuncName.add(funcName);

	const setState = setName(funcName);
	initial[funcName] = 'START';
	return async (...arg: any[]) => {
		let timer = 0;

		let res;
		try {
			setState(EFFECT_STATUS.start);
			timer = window.setTimeout(() => {
				setState(EFFECT_STATUS.loading);
			}, delay);
			res = await fn(...arg);
			setState(EFFECT_STATUS.success);
		} catch (error) {
			log.error(`error EffectStatus ${funcName}`, error);
			res = false;
			setState(EFFECT_STATUS.error);
		}
		clearTimeout(timer);
		return res;
	};
}
