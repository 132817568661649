/* 注册测试页弹窗数据配置文件 */
const OPT_INIT = 'OPT_INIT'; // 初始化学习弹窗
const OPT_EXAM = 'exam'; // 考试
const OPT_SUBJECT = 'subject'; // 科目
const OPT_GRADE = 'grade'; // 年级
const OPT_AGE = 'age'; // 年龄
const OPT_FEEDBACK = 'feedback'; // 反馈收集

export interface IGrade {
	gradeCode: string; // 年级code码
	gradeName: string; // 年级名称
}
export interface ISubject {
	subjectCode: string; // 科目code码
	subjectName: string; // 科目名称
	icon: string; // 科目图标
}
export interface IOption {
	type: string; // 类型
	title: string; // 标题
	list: unknown[];
	nextList?: string[];
}
export const optionData: { [key: string]: any } = {
	init: {
		type: OPT_INIT,
		title: 'Which is your main purpose of tutoring?',
		list: ['Exam preparation', 'Better grades', 'Kids fun learning', 'Something else'],
		nextList: [OPT_EXAM, OPT_GRADE, OPT_SUBJECT, OPT_FEEDBACK],
	},
	exam: {
		type: OPT_EXAM,
		title: 'Which exam are you preparing for?',
		list: ['GCSE', 'A-level', 'AP', '7+', '11+', '13+', 'Others'],
	},
	grade: {
		type: OPT_GRADE,
		title: 'What is the year of your study?',
		list: [
			{
				gradeCode: 'UK_PRESCHOOL',
				gradeName: 'Preschool',
			},
			{
				gradeCode: 'UK_Y1',
				gradeName: 'Y1',
			},
			{
				gradeCode: 'UK_Y2',
				gradeName: 'Y2',
			},
			{
				gradeCode: 'UK_Y3',
				gradeName: 'Y3',
			},
			{
				gradeCode: 'UK_Y4',
				gradeName: 'Y4',
			},
			{
				gradeCode: 'UK_Y5',
				gradeName: 'Y5',
			},
			{
				gradeCode: 'UK_Y6',
				gradeName: 'Y6',
			},
			{
				gradeCode: 'UK_Y7',
				gradeName: 'Y7',
			},
			{
				gradeCode: 'UK_Y8',
				gradeName: 'Y8',
			},
			{
				gradeCode: 'UK_Y9',
				gradeName: 'Y9',
			},
			{
				gradeCode: 'UK_Y10',
				gradeName: 'Y10',
			},
			{
				gradeCode: 'UK_Y11',
				gradeName: 'Y11',
			},
			{
				gradeCode: 'UK_Y12',
				gradeName: 'Y12',
			},
			{
				gradeCode: 'UK_Y13',
				gradeName: 'Y13',
			},
			{
				gradeCode: 'SENIOR_MIDDLE_SCHOOL_4',
				gradeName: 'University',
			},
		],
	},
	age: {
		type: OPT_AGE,
		title: 'How old is your kid?',
		list: ['3~4', '4~5', '5~6', '6~7', '7~8', '8~9', '9~10', '10~11', '11~12', '12~13', '13~14', '14~15', '15~16', '16~17', '17~18'],
	},
	feedback: {
		type: OPT_FEEDBACK,
		title: 'Tell us your needs',
	},
	subject: {
		type: OPT_SUBJECT,
		title: 'Which subject do you need help?',
		list: [
			{
				subjectCode: 'UK_ENGLISH',
				subjectName: 'English',
				icon: 'cover_english',
			},
			{
				subjectCode: 'UK_MATHS',
				subjectName: 'Maths',
				icon: 'cover_math',
			},
			{
				subjectCode: 'SEA_CHINESE',
				subjectName: 'Chinese',
				icon: 'cover_chinese',
			},
			{
				subjectCode: 'ARTS',
				subjectName: 'Art',
				icon: 'cover_drawing',
			},
			{
				subjectCode: 'UK_CODING',
				subjectName: 'Coding',
				icon: 'cover_coding',
			},
			{
				subjectCode: 'UK_MUSIC',
				subjectName: 'Music',
				icon: 'cover_music',
			},
			{
				subjectCode: 'UK_BIOLOGY',
				subjectName: 'Biology',
				icon: 'cover_biology',
			},
			{
				subjectCode: 'UK_CHEMISTRY',
				subjectName: 'Chemistry',
				icon: 'cover_chemistry',
			},
			{
				subjectCode: 'UK_PHYSICS',
				subjectName: 'Physics',
				icon: 'covere_physics',
			},
			{
				subjectCode: 'UK_HISTORY',
				subjectName: 'History',
				icon: 'cover_history',
			},
			{
				subjectCode: 'UK_ECONOMICS',
				subjectName: 'Economics',
				icon: 'cover_economics',
			},
			{
				subjectCode: 'UK_GEOGRAPHY',
				subjectName: 'Geography',
				icon: 'cover_geography',
			},
			{
				subjectCode: 'UK_PHILOSOPHY',
				subjectName: 'Philosophy',
				icon: 'cover_philosophy',
			},
			{
				subjectCode: 'UK_ACCOUNTING',
				subjectName: 'Accounting',
				icon: 'cover_accounting',
			},
			{
				subjectCode: 'UK_COMPUTING',
				subjectName: 'Computing',
				icon: 'cover_computing',
			},
			{
				subjectCode: 'UK_SCIENCE',
				icon: 'cover_science',
				subjectName: 'Science',
			},
			{
				subjectCode: 'UK_LAW',
				subjectName: 'Law',
				icon: 'cover_law',
			},
			{
				subjectCode: 'UK_FRENCH',
				subjectName: 'French',
				icon: 'cover_french',
			},
			{
				subjectCode: 'UK_SPANISH',
				subjectName: 'Spanish',
				icon: 'cover_spanish',
			},
			{
				subjectCode: 'UK_GERMAN',
				subjectName: 'German',
				icon: 'cover_german',
			},
			{
				subjectCode: 'UK_ITALIAN',
				subjectName: 'Italian',
				icon: 'cover_italian',
			},
			{
				subjectCode: 'UK_OTHER_SUBJECTS',
				subjectName: 'Other Subjects',
				icon: 'cover_other',
			},
		],
	},
};

export interface IInitFormData {
	subjectCode?: string;
	gradeCode?: string;
	init: string;
	age?: string;
	feedback?: string;
}

export interface IProps {
	visibleEnTestFormModal?: boolean;
	onClose?: () => void;
	title?: string; // 标题
}
