import React, { useEffect } from 'react';
import { withPortal } from 'components/portal';
import cx from 'classnames';

const prefix = 'zmsp-popup';
const noop = () => null;
const empty = {};

export type PopupProps = {
	onDestroy?: () => void;
	children?: React.ReactNode | ((onDestroy: () => void) => React.ReactNode);
	wrapperClassName?: string; // 最外层样式
	className?: string;
	audio?: string; // 弹窗同时播放的音频地址，允许接收一组地址
	width?: string; // 宽度
	mask?: boolean; // 是否展示背景蒙层，默认 true
	maskClosable?: boolean; // 点击蒙层是否可以关闭，默认 false
	style?: React.CSSProperties | undefined;
};

/** 通用 Popup，不带样式，带样式的请看 Modal */
export function BasePopup({
	onDestroy,
	wrapperClassName = '',
	className = '',
	audio = '',
	mask = true,
	maskClosable = false,
	children = null,
	style = empty,
}: PopupProps) {
	return (
		<div
			className={cx(prefix, wrapperClassName, {
				point: !mask,
			})}>
			{!!mask && <div className={cx(`${prefix}-mask`)} onClick={maskClosable ? onDestroy : noop}></div>}
			<div className={cx(`${prefix}-inner`, className)} style={style}>
				{typeof children === 'function' ? children(onDestroy!) : children}
			</div>
		</div>
	);
}

/** 除支持直接渲染 <Popup /> 外
 * 还支持两种命令式调用：
 * - show，马上弹出一个 popup，并返回销毁方法
 * ```
 * const destroy = Popup.show({
 *   onDestroy: func, // 关闭时回调
 *   content: (onDestroy: func) => ReactNode, // 弹窗主体
 *   ...popupProps, // 其它 popup props
 * })
 * ```
 *
 * - create，创建一个对象 x，并返回 x.show，x.destroy 方法
 * ```
 * const popup = Popup.create()
 * popup.show({ onDestroy: func, content: func | ReactNode })
 * popup.destroy()
 * ```
 */
export const Popup = withPortal<PopupProps>(BasePopup);
