/**
 * 国家Code
 */
export const COUNTRY_CODE: Record<number | string, string[]> = {
	1: [
		'US',
		'AG',
		'AS',
		'BB',
		'BM',
		'BS',
		'CA',
		'DM',
		'DO',
		'GD',
		'GU',
		'JM',
		'KN',
		'KY',
		'LC',
		'MP',
		'MS',
		'PR',
		'SX',
		'TC',
		'TT',
		'VC',
		'VG',
		'VI',
	],
	7: ['RU', 'KZ'],
	20: ['EG'],
	27: ['ZA'],
	30: ['GR'],
	31: ['NL'],
	32: ['BE'],
	33: ['FR'],
	34: ['ES'],
	36: ['HU'],
	39: ['IT', 'VA'],
	40: ['RO'],
	41: ['CH'],
	43: ['AT'],
	44: ['GB', 'GG', 'IM', 'JE'],
	45: ['DK'],
	46: ['SE'],
	47: ['NO', 'SJ'],
	48: ['PL'],
	49: ['DE'],
	51: ['PE'],
	52: ['MX'],
	54: ['AR'],
	55: ['BR'],
	56: ['CL'],
	57: ['CO'],
	60: ['MY'],
	61: ['AU', 'CC', 'CX'],
	62: ['ID'],
	63: ['PH'],
	64: ['NZ'],
	65: ['SG'],
	66: ['TH'],
	81: ['JP'],
	82: ['KR'],
	84: ['VN'],
	86: ['CN'],
	90: ['TR'],
	91: ['IN'],
	92: ['PK'],
	93: ['AF'],
	94: ['LK'],
	95: ['MM'],
	211: ['SS'],
	212: ['MA', 'EH'],
	213: ['DZ'],
	216: ['TN'],
	218: ['LY'],
	220: ['GM'],
	221: ['SN'],
	222: ['MR'],
	223: ['ML'],
	224: ['GN'],
	225: ['CI'],
	226: ['BF'],
	227: ['NE'],
	228: ['TG'],
	229: ['BJ'],
	230: ['MU'],
	231: ['LR'],
	232: ['SL'],
	233: ['GH'],
	234: ['NG'],
	235: ['TD'],
	236: ['CF'],
	237: ['CM'],
	238: ['CV'],
	239: ['ST'],
	240: ['GQ'],
	241: ['GA'],
	242: ['CG'],
	243: ['CD'],
	244: ['AO'],
	245: ['GW'],
	246: ['IO'],
	247: ['AC'],
	248: ['SC'],
	249: ['SD'],
	250: ['RW'],
	251: ['ET'],
	252: ['SO'],
	253: ['DJ'],
	254: ['KE'],
	255: ['TZ'],
	256: ['UG'],
	257: ['BI'],
	258: ['MZ'],
	260: ['ZM'],
	261: ['MG'],
	262: ['RE', 'YT'],
	263: ['ZW'],
	264: ['NA'],
	265: ['MW'],
	266: ['LS'],
	267: ['BW'],
	268: ['SZ'],
	269: ['KM'],
	290: ['SH', 'TA'],
	291: ['ER'],
	297: ['AW'],
	298: ['FO'],
	299: ['GL'],
	350: ['GI'],
	351: ['PT'],
	352: ['LU'],
	353: ['IE'],
	354: ['IS'],
	355: ['AL'],
	356: ['MT'],
	357: ['CY'],
	358: ['FI', 'AX'],
	359: ['BG'],
	370: ['LT'],
	371: ['LV'],
	372: ['EE'],
	373: ['MD'],
	374: ['AM'],
	375: ['BY'],
	376: ['AD'],
	377: ['MC'],
	378: ['SM'],
	380: ['UA'],
	381: ['RS'],
	382: ['ME'],
	383: ['XK'],
	385: ['HR'],
	386: ['SI'],
	387: ['BA'],
	389: ['MK'],
	420: ['CZ'],
	421: ['SK'],
	423: ['LI'],
	500: ['FK'],
	501: ['BZ'],
	502: ['GT'],
	503: ['SV'],
	504: ['HN'],
	505: ['NI'],
	506: ['CR'],
	507: ['PA'],
	508: ['PM'],
	509: ['HT'],
	590: ['GP', 'BL', 'MF'],
	591: ['BO'],
	592: ['GY'],
	593: ['EC'],
	594: ['GF'],
	595: ['PY'],
	596: ['MQ'],
	597: ['SR'],
	598: ['UY'],
	599: ['CW', 'BQ'],
	670: ['TL'],
	672: ['NF'],
	673: ['BN'],
	674: ['NR'],
	675: ['PG'],
	676: ['TO'],
	677: ['SB'],
	678: ['VU'],
	679: ['FJ'],
	680: ['PW'],
	681: ['WF'],
	682: ['CK'],
	683: ['NU'],
	685: ['WS'],
	686: ['KI'],
	687: ['NC'],
	688: ['TV'],
	689: ['PF'],
	690: ['TK'],
	691: ['FM'],
	692: ['MH'],
	852: ['HK'],
	853: ['MO'],
	855: ['KH'],
	856: ['LA'],
	880: ['BD'],
	886: ['TW'],
	960: ['MV'],
	961: ['LB'],
	962: ['JO'],
	964: ['IQ'],
	965: ['KW'],
	966: ['SA'],
	967: ['YE'],
	968: ['OM'],
	970: ['PS'],
	971: ['AE'],
	972: ['IL'],
	973: ['BH'],
	974: ['QA'],
	975: ['BT'],
	976: ['MN'],
	977: ['NP'],
	992: ['TJ'],
	993: ['TM'],
	994: ['AZ'],
	995: ['GE'],
	996: ['KG'],
	998: ['UZ'],
	1264: ['AI'],
};

/**
 * 国家Code
 */
export const COUNTRY_CODE_REG: Record<number | string, string[]> = {
	1: [
		'US',
		'AG',
		'AS',
		'BB',
		'BM',
		'BS',
		'CA',
		'DM',
		'DO',
		'GD',
		'GU',
		'JM',
		'KN',
		'KY',
		'LC',
		'MP',
		'MS',
		'PR',
		'SX',
		'TC',
		'TT',
		'VC',
		'VG',
		'VI',
	],
	7: ['RU', 'KZ'],
	20: ['EG'],
	27: ['ZA'],
	30: ['GR'],
	31: ['NL'],
	32: ['BE'],
	33: ['FR'],
	34: ['ES'],
	36: ['HU'],
	39: ['IT', 'VA'],
	40: ['RO'],
	41: ['CH'],
	43: ['AT'],
	44: ['GB', 'GG', 'IM', 'JE'],
	45: ['DK'],
	46: ['SE'],
	47: ['NO', 'SJ'],
	48: ['PL'],
	49: ['DE'],
	51: ['PE'],
	52: ['MX'],
	54: ['AR'],
	55: ['BR'],
	56: ['CL'],
	57: ['CO'],
	60: ['MY'],
	61: ['AU', 'CC', 'CX'],
	62: ['ID'],
	63: ['PH'],
	64: ['NZ'],
	65: ['SG'],
	66: ['TH'],
	81: ['JP'],
	82: ['KR'],
	84: ['VN'],
	// 86: ['CN'],
	90: ['TR'],
	91: ['IN'],
	92: ['PK'],
	93: ['AF'],
	94: ['LK'],
	95: ['MM'],
	211: ['SS'],
	212: ['MA', 'EH'],
	213: ['DZ'],
	216: ['TN'],
	218: ['LY'],
	220: ['GM'],
	221: ['SN'],
	222: ['MR'],
	223: ['ML'],
	224: ['GN'],
	225: ['CI'],
	226: ['BF'],
	227: ['NE'],
	228: ['TG'],
	229: ['BJ'],
	230: ['MU'],
	231: ['LR'],
	232: ['SL'],
	233: ['GH'],
	234: ['NG'],
	235: ['TD'],
	236: ['CF'],
	237: ['CM'],
	238: ['CV'],
	239: ['ST'],
	240: ['GQ'],
	241: ['GA'],
	242: ['CG'],
	243: ['CD'],
	244: ['AO'],
	245: ['GW'],
	246: ['IO'],
	247: ['AC'],
	248: ['SC'],
	249: ['SD'],
	250: ['RW'],
	251: ['ET'],
	252: ['SO'],
	253: ['DJ'],
	254: ['KE'],
	255: ['TZ'],
	256: ['UG'],
	257: ['BI'],
	258: ['MZ'],
	260: ['ZM'],
	261: ['MG'],
	262: ['RE', 'YT'],
	263: ['ZW'],
	264: ['NA'],
	265: ['MW'],
	266: ['LS'],
	267: ['BW'],
	268: ['SZ'],
	269: ['KM'],
	290: ['SH', 'TA'],
	291: ['ER'],
	297: ['AW'],
	298: ['FO'],
	299: ['GL'],
	350: ['GI'],
	351: ['PT'],
	352: ['LU'],
	353: ['IE'],
	354: ['IS'],
	355: ['AL'],
	356: ['MT'],
	357: ['CY'],
	358: ['FI', 'AX'],
	359: ['BG'],
	370: ['LT'],
	371: ['LV'],
	372: ['EE'],
	373: ['MD'],
	374: ['AM'],
	375: ['BY'],
	376: ['AD'],
	377: ['MC'],
	378: ['SM'],
	380: ['UA'],
	381: ['RS'],
	382: ['ME'],
	383: ['XK'],
	385: ['HR'],
	386: ['SI'],
	387: ['BA'],
	389: ['MK'],
	420: ['CZ'],
	421: ['SK'],
	423: ['LI'],
	500: ['FK'],
	501: ['BZ'],
	502: ['GT'],
	503: ['SV'],
	504: ['HN'],
	505: ['NI'],
	506: ['CR'],
	507: ['PA'],
	508: ['PM'],
	509: ['HT'],
	590: ['GP', 'BL', 'MF'],
	591: ['BO'],
	592: ['GY'],
	593: ['EC'],
	594: ['GF'],
	595: ['PY'],
	596: ['MQ'],
	597: ['SR'],
	598: ['UY'],
	599: ['CW', 'BQ'],
	670: ['TL'],
	672: ['NF'],
	673: ['BN'],
	674: ['NR'],
	675: ['PG'],
	676: ['TO'],
	677: ['SB'],
	678: ['VU'],
	679: ['FJ'],
	680: ['PW'],
	681: ['WF'],
	682: ['CK'],
	683: ['NU'],
	685: ['WS'],
	686: ['KI'],
	687: ['NC'],
	688: ['TV'],
	689: ['PF'],
	690: ['TK'],
	691: ['FM'],
	692: ['MH'],
	852: ['HK'],
	853: ['MO'],
	855: ['KH'],
	856: ['LA'],
	880: ['BD'],
	886: ['TW'],
	960: ['MV'],
	961: ['LB'],
	962: ['JO'],
	964: ['IQ'],
	965: ['KW'],
	966: ['SA'],
	967: ['YE'],
	968: ['OM'],
	970: ['PS'],
	971: ['AE'],
	972: ['IL'],
	973: ['BH'],
	974: ['QA'],
	975: ['BT'],
	976: ['MN'],
	977: ['NP'],
	992: ['TJ'],
	993: ['TM'],
	994: ['AZ'],
	995: ['GE'],
	996: ['KG'],
	998: ['UZ'],
	1264: ['AI'],
};

export const COUNTRY_TEXT: Record<string, string> = {
	AF: 'Afghanistan (‫افغانستان‬‎)',
	AL: 'Albania (Shqipëri)',
	DZ: 'Algeria (‫الجزائر‬‎)',
	AS: 'American Samoa',
	AD: 'Andorra',
	AO: 'Angola',
	AI: 'Anguilla',
	AG: 'Antigua and Barbuda',
	AR: 'Argentina',
	AM: 'Armenia (Հայաստան)',
	AW: 'Aruba',
	AU: 'Australia',
	AT: 'Austria (Österreich)',
	AZ: 'Azerbaijan (Azərbaycan)',
	BS: 'Bahamas',
	BH: 'Bahrain (‫البحرين‬‎)',
	BD: 'Bangladesh (বাংলাদেশ)',
	BB: 'Barbados',
	BY: 'Belarus (Беларусь)',
	BE: 'Belgium (België)',
	BZ: 'Belize',
	BJ: 'Benin (Bénin)',
	BM: 'Bermuda',
	BT: 'Bhutan (འབྲུག)',
	BO: 'Bolivia',
	BA: 'Bosnia and Herzegovina (Босна и Херцеговина)',
	BW: 'Botswana',
	BR: 'Brazil (Brasil)',
	IO: 'British Indian Ocean Territory',
	VG: 'British Virgin Islands',
	BN: 'Brunei',
	BG: 'Bulgaria (България)',
	BF: 'Burkina Faso',
	BI: 'Burundi (Uburundi)',
	KH: 'Cambodia (កម្ពុជា)',
	CM: 'Cameroon (Cameroun)',
	CA: 'Canada',
	CV: 'Cape Verde (Kabu Verdi)',
	BQ: 'Caribbean Netherlands',
	KY: 'Cayman Islands',
	CF: 'Central African Republic (République centrafricaine)',
	TD: 'Chad (Tchad)',
	CL: 'Chile',
	CN: 'China (中国)',
	CO: 'Colombia',
	KM: 'Comoros (‫جزر القمر‬‎)',
	CD: 'Congo (DRC) (Jamhuri ya Kidemokrasia ya Kongo)',
	CG: 'Congo (Republic) (Congo-Brazzaville)',
	CK: 'Cook Islands',
	CR: 'Costa Rica',
	CI: 'Côte d’Ivoire',
	HR: 'Croatia (Hrvatska)',
	CW: 'Curaçao',
	CY: 'Cyprus (Κύπρος)',
	CZ: 'Czech Republic (Česká republika)',
	DK: 'Denmark (Danmark)',
	DJ: 'Djibouti',
	DM: 'Dominica',
	DO: 'Dominican Republic (República Dominicana)',
	EC: 'Ecuador',
	EG: 'Egypt (‫مصر‬‎)',
	SV: 'El Salvador',
	GQ: 'Equatorial Guinea (Guinea Ecuatorial)',
	ER: 'Eritrea',
	EE: 'Estonia (Eesti)',
	ET: 'Ethiopia',
	FK: 'Falkland Islands (Islas Malvinas)',
	FO: 'Faroe Islands (Føroyar)',
	FJ: 'Fiji',
	FI: 'Finland (Suomi)',
	FR: 'France',
	GF: 'French Guiana (Guyane française)',
	PF: 'French Polynesia (Polynésie française)',
	GA: 'Gabon',
	GM: 'Gambia',
	GE: 'Georgia (საქართველო)',
	DE: 'Germany (Deutschland)',
	GH: 'Ghana (Gaana)',
	GI: 'Gibraltar',
	GR: 'Greece (Ελλάδα)',
	GL: 'Greenland (Kalaallit Nunaat)',
	GD: 'Grenada',
	GP: 'Guadeloupe',
	GU: 'Guam',
	GT: 'Guatemala',
	GN: 'Guinea (Guinée)',
	GW: 'Guinea-Bissau (Guiné Bissau)',
	GY: 'Guyana',
	HT: 'Haiti',
	HN: 'Honduras',
	HK: 'Hong Kong, China (中國香港)',
	HU: 'Hungary (Magyarország)',
	IS: 'Iceland (Ísland)',
	IN: 'India (भारत)',
	ID: 'Indonesia',
	IQ: 'Iraq (‫العراق‬‎)',
	IE: 'Ireland',
	IL: 'Israel (‫ישראל‬‎)',
	IT: 'Italy (Italia)',
	JM: 'Jamaica',
	JP: 'Japan (日本)',
	JO: 'Jordan (‫الأردن‬‎)',
	KZ: 'Kazakhstan (Казахстан)',
	KE: 'Kenya',
	KI: 'Kiribati',
	KW: 'Kuwait (‫الكويت‬‎)',
	KG: 'Kyrgyzstan (Кыргызстан)',
	LA: 'Laos (ລາວ)',
	LV: 'Latvia (Latvija)',
	LB: 'Lebanon (‫لبنان‬‎)',
	LS: 'Lesotho',
	LR: 'Liberia',
	LY: 'Libya (‫ليبيا‬‎)',
	LI: 'Liechtenstein',
	LT: 'Lithuania (Lietuva)',
	LU: 'Luxembourg',
	MO: 'Macau, China(中國澳門)',
	MK: 'Macedonia (FYROM) (Македонија)',
	MG: 'Madagascar (Madagasikara)',
	MW: 'Malawi',
	MY: 'Malaysia',
	MV: 'Maldives',
	ML: 'Mali',
	MT: 'Malta',
	MH: 'Marshall Islands',
	MQ: 'Martinique',
	MR: 'Mauritania (‫موريتانيا‬‎)',
	MU: 'Mauritius (Moris)',
	MX: 'Mexico (México)',
	FM: 'Micronesia',
	MD: 'Moldova (Republica Moldova)',
	MC: 'Monaco',
	MN: 'Mongolia (Монгол)',
	ME: 'Montenegro (Crna Gora)',
	MS: 'Montserrat',
	MA: 'Morocco (‫المغرب‬‎)',
	MZ: 'Mozambique (Moçambique)',
	MM: 'Myanmar (Burma) (မြန်မာ)',
	NA: 'Namibia (Namibië)',
	NR: 'Nauru',
	NP: 'Nepal (नेपाल)',
	NL: 'Netherlands (Nederland)',
	NC: 'New Caledonia (Nouvelle-Calédonie)',
	NZ: 'New Zealand',
	NI: 'Nicaragua',
	NE: 'Niger (Nijar)',
	NG: 'Nigeria',
	NU: 'Niue',
	NF: 'Norfolk Island',
	MP: 'Northern Mariana Islands',
	NO: 'Norway (Norge)',
	OM: 'Oman (‫عُمان‬‎)',
	PK: 'Pakistan (‫پاکستان‬‎)',
	PW: 'Palau',
	PS: 'Palestine (‫فلسطين‬‎)',
	PA: 'Panama (Panamá)',
	PG: 'Papua New Guinea',
	PY: 'Paraguay',
	PE: 'Peru (Perú)',
	PH: 'Philippines',
	PL: 'Poland (Polska)',
	PT: 'Portugal',
	PR: 'Puerto Rico',
	QA: 'Qatar (‫قطر‬‎)',
	RE: 'Réunion (La Réunion)',
	RO: 'Romania (România)',
	RU: 'Russia (Россия)',
	RW: 'Rwanda',
	BL: 'Saint Barthélemy (Saint-Barthélemy)',
	SH: 'Saint Helena',
	KN: 'Saint Kitts and Nevis',
	LC: 'Saint Lucia',
	MF: 'Saint Martin (Saint-Martin (partie française))',
	PM: 'Saint Pierre and Miquelon (Saint-Pierre-et-Miquelon)',
	VC: 'Saint Vincent and the Grenadines',
	WS: 'Samoa',
	SM: 'San Marino',
	ST: 'São Tomé and Príncipe (São Tomé e Príncipe)',
	SA: 'Saudi Arabia (‫المملكة العربية السعودية‬‎)',
	SN: 'Senegal (Sénégal)',
	RS: 'Serbia (Србија)',
	SC: 'Seychelles',
	SL: 'Sierra Leone',
	SG: 'Singapore',
	SX: 'Sint Maarten',
	SK: 'Slovakia (Slovensko)',
	SI: 'Slovenia (Slovenija)',
	SB: 'Solomon Islands',
	SO: 'Somalia (Soomaaliya)',
	ZA: 'South Africa',
	KR: 'South Korea (대한민국)',
	SS: 'South Sudan (‫جنوب السودان‬‎)',
	ES: 'Spain (España)',
	LK: 'Sri Lanka (ශ්‍රී ලංකාව)',
	SD: 'Sudan (‫السودان‬‎)',
	SR: 'Suriname',
	SZ: 'Swaziland',
	SE: 'Sweden (Sverige)',
	CH: 'Switzerland (Schweiz)',
	TW: 'Taiwan, China (中國台灣)',
	TJ: 'Tajikistan',
	TZ: 'Tanzania',
	TH: 'Thailand (ไทย)',
	TL: 'Timor-Leste',
	TG: 'Togo',
	TK: 'Tokelau',
	TO: 'Tonga',
	TT: 'Trinidad and Tobago',
	TN: 'Tunisia (‫تونس‬‎)',
	TR: 'Turkey (Türkiye)',
	TM: 'Turkmenistan',
	TC: 'Turks and Caicos Islands',
	TV: 'Tuvalu',
	VI: 'U.S. Virgin Islands',
	UG: 'Uganda',
	UA: 'Ukraine (Україна)',
	AE: 'United Arab Emirates (‫الإمارات العربية المتحدة‬‎)',
	GB: 'United Kingdom',
	US: 'United States',
	UY: 'Uruguay',
	UZ: 'Uzbekistan (Oʻzbekiston)',
	VU: 'Vanuatu',
	VA: 'Vatican City (Città del Vaticano)',
	VN: 'Vietnam (Việt Nam)',
	WF: 'Wallis and Futuna',
	YE: 'Yemen (‫اليمن‬‎)',
	ZM: 'Zambia',
	ZW: 'Zimbabwe',
};

export const COUNTRY_TEXT_REG: Record<string, string> = {
	AF: 'Afghanistan (‫افغانستان‬‎)',
	AL: 'Albania (Shqipëri)',
	DZ: 'Algeria (‫الجزائر‬‎)',
	AS: 'American Samoa',
	AD: 'Andorra',
	AO: 'Angola',
	AI: 'Anguilla',
	AG: 'Antigua and Barbuda',
	AR: 'Argentina',
	AM: 'Armenia (Հայաստան)',
	AW: 'Aruba',
	AU: 'Australia',
	AT: 'Austria (Österreich)',
	AZ: 'Azerbaijan (Azərbaycan)',
	BS: 'Bahamas',
	BH: 'Bahrain (‫البحرين‬‎)',
	BD: 'Bangladesh (বাংলাদেশ)',
	BB: 'Barbados',
	BY: 'Belarus (Беларусь)',
	BE: 'Belgium (België)',
	BZ: 'Belize',
	BJ: 'Benin (Bénin)',
	BM: 'Bermuda',
	BT: 'Bhutan (འབྲུག)',
	BO: 'Bolivia',
	BA: 'Bosnia and Herzegovina (Босна и Херцеговина)',
	BW: 'Botswana',
	BR: 'Brazil (Brasil)',
	IO: 'British Indian Ocean Territory',
	VG: 'British Virgin Islands',
	BN: 'Brunei',
	BG: 'Bulgaria (България)',
	BF: 'Burkina Faso',
	BI: 'Burundi (Uburundi)',
	KH: 'Cambodia (កម្ពុជា)',
	CM: 'Cameroon (Cameroun)',
	CA: 'Canada',
	CV: 'Cape Verde (Kabu Verdi)',
	BQ: 'Caribbean Netherlands',
	KY: 'Cayman Islands',
	CF: 'Central African Republic (République centrafricaine)',
	TD: 'Chad (Tchad)',
	CL: 'Chile',
	// CN: 'China (中国)',
	CO: 'Colombia',
	KM: 'Comoros (‫جزر القمر‬‎)',
	CD: 'Congo (DRC) (Jamhuri ya Kidemokrasia ya Kongo)',
	CG: 'Congo (Republic) (Congo-Brazzaville)',
	CK: 'Cook Islands',
	CR: 'Costa Rica',
	CI: 'Côte d’Ivoire',
	HR: 'Croatia (Hrvatska)',
	CW: 'Curaçao',
	CY: 'Cyprus (Κύπρος)',
	CZ: 'Czech Republic (Česká republika)',
	DK: 'Denmark (Danmark)',
	DJ: 'Djibouti',
	DM: 'Dominica',
	DO: 'Dominican Republic (República Dominicana)',
	EC: 'Ecuador',
	EG: 'Egypt (‫مصر‬‎)',
	SV: 'El Salvador',
	GQ: 'Equatorial Guinea (Guinea Ecuatorial)',
	ER: 'Eritrea',
	EE: 'Estonia (Eesti)',
	ET: 'Ethiopia',
	FK: 'Falkland Islands (Islas Malvinas)',
	FO: 'Faroe Islands (Føroyar)',
	FJ: 'Fiji',
	FI: 'Finland (Suomi)',
	FR: 'France',
	GF: 'French Guiana (Guyane française)',
	PF: 'French Polynesia (Polynésie française)',
	GA: 'Gabon',
	GM: 'Gambia',
	GE: 'Georgia (საქართველო)',
	DE: 'Germany (Deutschland)',
	GH: 'Ghana (Gaana)',
	GI: 'Gibraltar',
	GR: 'Greece (Ελλάδα)',
	GL: 'Greenland (Kalaallit Nunaat)',
	GD: 'Grenada',
	GP: 'Guadeloupe',
	GU: 'Guam',
	GT: 'Guatemala',
	GN: 'Guinea (Guinée)',
	GW: 'Guinea-Bissau (Guiné Bissau)',
	GY: 'Guyana',
	HT: 'Haiti',
	HN: 'Honduras',
	HK: 'Hong Kong, China (中國香港)',
	HU: 'Hungary (Magyarország)',
	IS: 'Iceland (Ísland)',
	IN: 'India (भारत)',
	ID: 'Indonesia',
	IQ: 'Iraq (‫العراق‬‎)',
	IE: 'Ireland',
	IL: 'Israel (‫ישראל‬‎)',
	IT: 'Italy (Italia)',
	JM: 'Jamaica',
	JP: 'Japan (日本)',
	JO: 'Jordan (‫الأردن‬‎)',
	KZ: 'Kazakhstan (Казахстан)',
	KE: 'Kenya',
	KI: 'Kiribati',
	KW: 'Kuwait (‫الكويت‬‎)',
	KG: 'Kyrgyzstan (Кыргызстан)',
	LA: 'Laos (ລາວ)',
	LV: 'Latvia (Latvija)',
	LB: 'Lebanon (‫لبنان‬‎)',
	LS: 'Lesotho',
	LR: 'Liberia',
	LY: 'Libya (‫ليبيا‬‎)',
	LI: 'Liechtenstein',
	LT: 'Lithuania (Lietuva)',
	LU: 'Luxembourg',
	MO: 'Macau, China(中國澳門)',
	MK: 'Macedonia (FYROM) (Македонија)',
	MG: 'Madagascar (Madagasikara)',
	MW: 'Malawi',
	MY: 'Malaysia',
	MV: 'Maldives',
	ML: 'Mali',
	MT: 'Malta',
	MH: 'Marshall Islands',
	MQ: 'Martinique',
	MR: 'Mauritania (‫موريتانيا‬‎)',
	MU: 'Mauritius (Moris)',
	MX: 'Mexico (México)',
	FM: 'Micronesia',
	MD: 'Moldova (Republica Moldova)',
	MC: 'Monaco',
	MN: 'Mongolia (Монгол)',
	ME: 'Montenegro (Crna Gora)',
	MS: 'Montserrat',
	MA: 'Morocco (‫المغرب‬‎)',
	MZ: 'Mozambique (Moçambique)',
	MM: 'Myanmar (Burma) (မြန်မာ)',
	NA: 'Namibia (Namibië)',
	NR: 'Nauru',
	NP: 'Nepal (नेपाल)',
	NL: 'Netherlands (Nederland)',
	NC: 'New Caledonia (Nouvelle-Calédonie)',
	NZ: 'New Zealand',
	NI: 'Nicaragua',
	NE: 'Niger (Nijar)',
	NG: 'Nigeria',
	NU: 'Niue',
	NF: 'Norfolk Island',
	MP: 'Northern Mariana Islands',
	NO: 'Norway (Norge)',
	OM: 'Oman (‫عُمان‬‎)',
	PK: 'Pakistan (‫پاکستان‬‎)',
	PW: 'Palau',
	PS: 'Palestine (‫فلسطين‬‎)',
	PA: 'Panama (Panamá)',
	PG: 'Papua New Guinea',
	PY: 'Paraguay',
	PE: 'Peru (Perú)',
	PH: 'Philippines',
	PL: 'Poland (Polska)',
	PT: 'Portugal',
	PR: 'Puerto Rico',
	QA: 'Qatar (‫قطر‬‎)',
	RE: 'Réunion (La Réunion)',
	RO: 'Romania (România)',
	RU: 'Russia (Россия)',
	RW: 'Rwanda',
	BL: 'Saint Barthélemy (Saint-Barthélemy)',
	SH: 'Saint Helena',
	KN: 'Saint Kitts and Nevis',
	LC: 'Saint Lucia',
	MF: 'Saint Martin (Saint-Martin (partie française))',
	PM: 'Saint Pierre and Miquelon (Saint-Pierre-et-Miquelon)',
	VC: 'Saint Vincent and the Grenadines',
	WS: 'Samoa',
	SM: 'San Marino',
	ST: 'São Tomé and Príncipe (São Tomé e Príncipe)',
	SA: 'Saudi Arabia (‫المملكة العربية السعودية‬‎)',
	SN: 'Senegal (Sénégal)',
	RS: 'Serbia (Србија)',
	SC: 'Seychelles',
	SL: 'Sierra Leone',
	SG: 'Singapore',
	SX: 'Sint Maarten',
	SK: 'Slovakia (Slovensko)',
	SI: 'Slovenia (Slovenija)',
	SB: 'Solomon Islands',
	SO: 'Somalia (Soomaaliya)',
	ZA: 'South Africa',
	KR: 'South Korea (대한민국)',
	SS: 'South Sudan (‫جنوب السودان‬‎)',
	ES: 'Spain (España)',
	LK: 'Sri Lanka (ශ්‍රී ලංකාව)',
	SD: 'Sudan (‫السودان‬‎)',
	SR: 'Suriname',
	SZ: 'Swaziland',
	SE: 'Sweden (Sverige)',
	CH: 'Switzerland (Schweiz)',
	TW: 'Taiwan, China (中國台灣)',
	TJ: 'Tajikistan',
	TZ: 'Tanzania',
	TH: 'Thailand (ไทย)',
	TL: 'Timor-Leste',
	TG: 'Togo',
	TK: 'Tokelau',
	TO: 'Tonga',
	TT: 'Trinidad and Tobago',
	TN: 'Tunisia (‫تونس‬‎)',
	TR: 'Turkey (Türkiye)',
	TM: 'Turkmenistan',
	TC: 'Turks and Caicos Islands',
	TV: 'Tuvalu',
	VI: 'U.S. Virgin Islands',
	UG: 'Uganda',
	UA: 'Ukraine (Україна)',
	AE: 'United Arab Emirates (‫الإمارات العربية المتحدة‬‎)',
	GB: 'United Kingdom',
	US: 'United States',
	UY: 'Uruguay',
	UZ: 'Uzbekistan (Oʻzbekiston)',
	VU: 'Vanuatu',
	VA: 'Vatican City (Città del Vaticano)',
	VN: 'Vietnam (Việt Nam)',
	WF: 'Wallis and Futuna',
	YE: 'Yemen (‫اليمن‬‎)',
	ZM: 'Zambia',
	ZW: 'Zimbabwe',
};

// 国家区号
export const AREA_CODE_MAP: Record<string, { name: string; id: string; code: string; icon: string }>
	= Object.keys(COUNTRY_TEXT).reduce((pre: Record<string, any>, next) => {
		pre[next] = { // TODO icon iconfont 待定
			id: next,
			name: COUNTRY_TEXT[next],
			code: Object.keys(COUNTRY_CODE).find((key) => COUNTRY_CODE[key].find((code) => code === next)) || '',
			// icon:
		};
		return pre;
	}, {});

// 国家code映射
export const COUNTRYCODE_LANG_MAP: Record<string, string> = {
	US: 'en-US',
	CA: 'en-CA',
	IE: 'en-IE',
	AU: 'en-AU',
	NZ: 'en-NZ',
	UK: 'en-GB',
	EN: 'en-UK',
};


export const COUNTRY_CONFIG = [
	{
		icon: 'https://web-data.zmlearn.com/image/kkiCLaymfhYXRWcszQWEFB/USA%402x.png',
		name: 'USA',
		local: 'en-US',
		countryCode: 'us',
	},
	{
		icon: 'https://web-data.zmlearn.com/image/6bjMqKUTQjNNFzuukWS9pk/canada%402x.png',
		name: 'Canada',
		local: 'en-CA',
		countryCode: 'ca',
	},
	{
		icon: 'https://web-data.zmlearn.com/image/eZNt5nP8W7C7iNRTNwnHjb/aierla%402x.png',
		name: 'Ireland',
		local: 'en-IE',
		countryCode: 'ie',
	},
	{
		icon: 'https://web-data.zmlearn.com/image/3HiaornFU6WidjWnHqerUH/aodaliya%402x.png',
		name: 'Australia',
		local: 'en-AU',
		countryCode: 'au',
	},
	{
		icon: 'https://web-data.zmlearn.com/image/BeQyitzd5VnYYxC2WAqQr/xinxilan%402x.png',
		name: 'New Zealand',
		local: 'en-NZ',
		countryCode: 'nz',
	},
	{
		icon: 'https://web-data.zmlearn.com/image/hT7mYm5Q8N525trbZRH3gX/Britain%402x.png',
		name: 'UK',
		local: 'en-GB',
		countryCode: 'uk',
	},
];


export const LANG = [
	{ icon: 'icon_selected', label: 'en', name: 'En', code: 'en' },
	{ icon: 'icon_selected', label: 'es', name: 'Es', code: 'es' },
	{ icon: 'icon_selected', label: 'china', name: '中文', code: 'china' },
	// { icon: 'icon_selected', label: 'Türkçe', name: 'Tr', code: 'tr' },
]

export const LangNumber: { [key: string]: string } = {
	'en': '2',
	'es': '10',
}
