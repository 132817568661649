import i18n from '@fe/i18n';
import { useCallback, useEffect, useState } from 'react';
import get from 'lodash/get';
import { useSelector } from 'react-redux';
import { ConnectState } from 'states/connect';

/**
 *
 * 字符串变量替换
 * @export
 * @param {string} str
 * @param {Record<string, any>} [data]
 * @returns
 */
function template(str: string, data?: Record<string, any>) {
	return str.replace(/\{([0-9a-zA-Z_]+)\}/g, (match, key) => {
		if (data && match && Object.prototype.hasOwnProperty.call(data, key)) {
			return data[key];
		}
		return '';
	});
}

/**
 * @description 多语言转换
 * @date 2022-02-24
 * @export
 * @returns {*}
 * demo:
 *         const {t}=useTranslation()
 *        t('header.home') // => 'home'
 */

export type UseTranslationProps = {
	t: (namespace: string, args?: unknown | string) => string;
	local: string;
	list: string[];
	setList: (list: string[]) => void;
	transData: any;
	score: number;
	evaluation: string[];
};

export function useTranslation(localConst?: string): UseTranslationProps {
	let transData: any = {};
	const local = useSelector((state: ConnectState) => state.app.local);
	// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
	// @ts-ignore
	const resource = i18n.__lanResource.get(local);
	const [list, setList] = useState<any[]>([]);
	
	const [evaluation] = useState<any[]>([]);
	let score = 4.8

	const t = useCallback(
		(namespace: string, args?: any) => {
			const str = get(resource, namespace);
			if (str) {
				if (args) {
					return template(str, args);
				}
				return str;
			}
			return namespace;
		},
		[local]
	);

	if (localConst) {
		transData = t(localConst);
		score = transData.score
	}

	useEffect(() => {
		if (transData.list) {
			setList(transData.list);
		}
	}, [transData]);

	return {
		t,
		local,
		list,
		setList,
		transData,
		score,
		evaluation
	};
}
