import Axios from '@utils/request';
import ApiHost from '@services/api-config';
import JSEncrypt from 'jsencrypt';
import globalStore from 'states/app';
import { Address, Token, UserInfo } from 'states/interface';
import { getState } from 'jumpstate';

/**
 * service name: air-student-ow-api
 * appid 13749
 */
export enum ResponseCode {
	OK = '0',
}
export type Response<T> = {
	code: string;
	configurableMessage: string;
	data: T;
	message: string;
	msgCode: string;
}
// 获取公钥
const getRsaPubKey = (function () {
	let rsaPubKey: string;
	return async () => {
		if (!rsaPubKey) {
			const res: Response<string> = await Axios.post(`${ApiHost.airGateway}/air-student-ow-api/api/auth/rsaPubKey`);
			rsaPubKey = res.data;
		}
		return rsaPubKey;
	}
})()

// 加密
const encryptRsa: (param: any, addTimestamp?: boolean) => Promise<string> = async (param, addTimestamp = false) => {
	const pubKey = await getRsaPubKey();
	try {
		const encrypt = new JSEncrypt();
		encrypt.setPublicKey(pubKey);
		if (addTimestamp) param.timestamp = Date.now().toString();
		if (param.email) {
			param.mobile = param.email
			delete param.email
		}
		const strParam = typeof param === 'string' ? param : JSON.stringify(param);
		const msg = encrypt.encrypt(strParam);
		if (!msg) throw new Error(`msg: ${msg}`);
		if (process.env.BUILD_TYPE === 'test') {
			console.log(param, msg)
		}
		return msg;
	} catch(err) {
		console.log('rsa加密出错: ', err);
		throw err;
	}
}

type UserInfoRes = Response<UserInfo>;
// 获取用户信息
export const getUserInfo = async () => {
	try {
		const res: UserInfoRes = await Axios.get(`${ApiHost.airGateway}/air-student-ow-api/api/user/baseInfo`);
		globalStore.setUserInfo(res.data);
		return res;
	} catch (err) {
		console.log('查询用户信息出错:', err);
		throw err;
	}
}

// 密码登录
interface LoginWithPsw {
	areaCode?: string;
	mobile?: string;
	email?: string;
	password: string;
}
export const loginWithPsw: (param: LoginWithPsw) => Promise<Response<Token>> = async (param) => {
	const url = `${ApiHost.airGateway}/air-student-ow-api/api/auth/login/byPassword${param?.email ? '/email' : ''}`
	const msg = await encryptRsa(param, true)
	try {
		const res: Response<Token> = await Axios.post(url, { msg });
		globalStore.setToken(res.data);
		if (res.code === ResponseCode.OK) {
			await getUserInfo();
		}
		return res;
	} catch (err) {
		console.log('密码登录出错:', err);
		throw err;
	}
}
// 验证码登录
export const loginWithSms: (param: { areaCode: string; mobile: string; validateCode: string }) => Promise<Response<Token>> = async (param) => {
	try {
		const res: Response<Token> = await Axios.post(`${ApiHost.airGateway}/air-student-ow-api/api/auth/login/byValidateCode`, param);
		globalStore.setToken(res.data);
		if (res.code === ResponseCode.OK) {
			await getUserInfo();
		}
		return res;
	} catch(err) {
		console.log('验证码登录出错:', err);
		throw err;
	}
}
export type SignUpParams = {
	areaCode?: string;
	mobile?: string; // 手机号
	email?: string;
	validateCode: string; // 验证码
	code?:	string; // 渠道code
	gradeCode?: string; // 学生年级编码
	key?: string; // 渠道key
	mark?: string;
	stuComeFrom?: string;
	subjectCode?: string; // 预约科目编码
	referUserId?: string;
}
// 验证码注册
export const signUpWithSms: (param: SignUpParams) => Promise<Response<Token>> = async (param) => {
	try {
		const url = `${ApiHost.airGateway}/air-student-ow-api/api/auth/register/byValidateCode${ param.email ? '/email' : '' }`
		const res: Response<Token> = await Axios.post(url, param, {
			headers: {
				'Accept-Language': getState().app.local
			}
		});
		globalStore.setToken(res.data);
		if (res.code === ResponseCode.OK) {
			await getUserInfo();
		}
		return res;
	} catch(err) {
		console.log('验证码登录出错:', err);
		throw err;
	}
}
type ResetParam = {
	areaCode?: string;
	mobile?: string;
	email?: string;
	password: string;
	validateCode: string;
}
// 更改密码
export const resetPassword: (param: ResetParam) => Promise<Response<boolean>> = async (param) => {
	const { password, ...others } = param;
	const passwordMsg = await encryptRsa(password);
	const url = `${ApiHost.airGateway}/air-student-ow-api/api/auth/resetPassword${param?.email ? '/email' : ''}`
	try {
		const res: Response<boolean> = await Axios.post(url, { passwordMsg, ...others });
		if (res.code === ResponseCode.OK && res.data) {
			globalStore.setToken();
			globalStore.setUserInfo();
		}
		return res;
	} catch(err) {
		console.log('验证码登录出错:', err);
		throw err;
	}
}
type UpdateUserParam = {
	userName?: string;
	newPassWordMsg?: string;
	address?: Address;
	avatar?: string;
	birthday?: string;
	email?: string;
}
// 完善个人信息
export const updateUserInfo = async (param: UpdateUserParam) => {
	const newPassWordMsg = await encryptRsa(param.newPassWordMsg);
	const token: Token = getState().app.token;
	try {
		const res: Response<boolean> = await Axios.post(
			`${ApiHost.airGateway}/air-student-ow-api/api/user/update/baseInfo`,
			{ ...param, newPassWordMsg });
		if (res.code === ResponseCode.OK && res.data) {
			if (token.isRegister) {
				globalStore.setToken({ ...token, isRegister: false });
			}
			await getUserInfo();
		}
		return res;
	} catch(err) {
		console.log('提交个人信息出错:', err);
		throw err;
	}
}
type GetSmsCodeParamV2 = {
	areaCode: string | number;
	mobile: string | number;
	vo: any;
}

type GetSmsCodeParam = {
	areaCode?: string;
	mobile: string;
}
// 获取登录验证码
export const getLoginSmsCode: (param: GetSmsCodeParamV2) => Promise<Response<boolean>> = async (param) => {
	try {
		const res: Response<boolean> = await Axios.post(`${ApiHost.airGateway}/air-student-ow-api/api/sms/login/validateCodeNew`, param);
		return res;
	} catch (err) {
		console.log('获取验证码出错:', err);
		throw err
	}
}
type GetSignUpSmsCode = {
	sendCodeRequest: any;
	vo: any;
}
// 获取注册验证码
export const getSignUpSmsCode: (param: GetSignUpSmsCode) => Promise<Response<boolean>> = async (param) => {
	try {
		const res: Response<boolean> = await Axios.post(`${ApiHost.airGateway}/air-student-ow-api/api/sms/register/validateCode`, param);
		return res;
	} catch (err) {
		console.log('获取验证码出错:', err);
		throw err
	}
}
// type ResGeetest={
// 	challenge: string;
//   seccode: string;
//   status: string;
//   validate: string;
// }
// 极验
export const geetestApi1: (param: GetSmsCodeParam) => Promise<Response<boolean>> = async (param) => {
	try {
		const res: Response<boolean> = await Axios.post(`${ApiHost.airGateway}/air-student-ow-api/api/sms/api1`, param);
		return res;
	} catch (err) {
		console.log('获取验证码出错:', err);
		throw err
	}
}
// 获取重置密码验证码
export const getResetPswCode: (param: GetSmsCodeParam) => Promise<Response<boolean>> = async (param) => {
	try {
		const res: Response<boolean> = await Axios.post(`${ApiHost.airGateway}/air-student-ow-api/api/sms/resetPassword/validateCode`, param);
		return res;
	} catch (err) {
		console.log('获取验证码出错:', err);
		throw err
	}
}
// logout
export const logout: () => Promise<Response<boolean>> = async () => {
	try {
		const res: Response<boolean> = await Axios.post(`${ApiHost.airGateway}/air-student-ow-api/api/user/logout`);
		return res;
	} catch (err) {
		console.log('退出登录:', err);
		throw err
	} finally {
		globalStore.setUserInfo();
		globalStore.setToken();
	}
}

enum Scene {
	login = 1,
	signup,
	resetPwd,
	changeEmail
}
interface EmailCodeSend { email: string; scene: Scene }
export const emailCodeSent: (data: EmailCodeSend) => Promise<Response<boolean>> = async (data) => {
	try {
		const res: Response<boolean> = await Axios.post(`${ApiHost.airGateway}/air-student-ow-api/api/auth/email/sendValidateCode`, data)
		return res
	} catch (err) {
		console.log(err)
		throw err
	}
}

export interface EmailRegistered {
	email: string;
	scene: Scene;
}
export const checkEmailRegistered: (data: EmailRegistered) => Promise<Response<boolean>> = async (data) => {
	try {
		const res: Response<boolean> = await Axios.post(`${ApiHost.airGateway}/air-student-ow-api/api/auth/email/registered`, data)
		return res
	} catch (err) {
		console.log(err);
		throw err
	}
}
