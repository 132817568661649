export const CHANNELINFO: { [key: string]: { channel_code: number; channel_keyword: string } } = {
	us: {
		channel_code: 29313,
		channel_keyword: '9eefd2d9fc60afab'
	},
	ca: {
		channel_code: 29314,
		channel_keyword: 'c1c941ed1fab1f18'
	},
	ie: {
		channel_code: 29316,
		channel_keyword: '2e44de1cb6e53a0b'
	},
	au: {
		channel_code: 29315,
		channel_keyword: '692a55f6e96b15c6'
	},
	nz: {
		channel_code: 29317,
		channel_keyword: '30fae821afa33531'
	},
	uk: {
		channel_code: 29016,
		channel_keyword: 'd71b8e40f116fba5'
	},
	en: {
		channel_code: 29016,
		channel_keyword: 'd71b8e40f116fba5'
	},
	es: {
		channel_code: 29016,
		channel_keyword: 'd480344ea6ddb166'
	},
	tr: {
		channel_code: 29016,
		channel_keyword: '3fe15a2bc47b9362'
	},
}
