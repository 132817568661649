import React, { useEffect, useImperativeHandle, useLayoutEffect, useState } from 'react';
import Button, { BaseBtnPros } from 'ac-components/ac-btn';
import { BaseModal } from 'components/dialogs/modal';
import { withPortal } from 'components/portal';
import cls from '@utils/css-select'
import { isMobile } from 'components/select/utils';
import styles from './styles.scss';
import AcIcon from 'ac-components/ac-icon';

const cx = cls(styles)

const noop = () => {/* noop */ };

const IsMobile = isMobile();

type CloseProps = {
	className?: string;
	style?: React.CSSProperties;
	onClick?(): void;
}
const CloseIcon = (props: CloseProps) => {
	const { className, onClick = noop } = props;
	return <AcIcon icon={'icon_close'} className={`${cx('close-icon')} ${className || ''}`} onClick={onClick} />
}

type ModalProps = {
	show?: boolean;
	children?: React.ReactNode | ((onClose: () => void) => React.ReactNode);
	className?: string;
	mask?: boolean; // 是否展示背景蒙层，默认 true
	maskClosable?: boolean; // 点击蒙层是否可以关闭，默认 false
	closable?: boolean | React.ReactNode; // 是否显示右上角关闭按钮，默认 true
	onClose?(): void;
	size?: 'medium' | 'mini'; // 默认 medium
}

const RawModal = (props: ModalProps) => {
	const { onClose = noop, show = true, className, size = 'medium', closable = true, children, ...otherProps } = props;
	const [visible, setVisible] = useState<boolean>(() => show);

	const onCloseHandle = () => {
		setVisible(false);
		onClose();
		document.body.classList.remove('ac-modal-opened-body');
	}

	useLayoutEffect(() => {
		setVisible(show);
		if (show) {
			document.body.classList.add('ac-modal-opened-body');
		} else {
			document.body.classList.remove('ac-modal-opened-body');
		}
	}, [show])

	useLayoutEffect(() => {
		return () => { document.body.classList.remove('ac-modal-opened-body') }
	}, [])

	if (!visible) return null;
	return (
		<BaseModal
			prefix='ac-modal'
			onDestroy={onCloseHandle}
			className={cx('ac-modal', size, className || '', { m: IsMobile })}
			{...otherProps}
		>
			{closable && (
				<span className={cx('close')} onClick={onCloseHandle}>
					{typeof closable === 'boolean' ? <CloseIcon /> : closable}
				</span>
			)}
			{typeof children === 'function' ? children(onCloseHandle) : children}
		</BaseModal>
	)
}

const Modal = withPortal<ModalProps>(RawModal);

type ButtonProps = Omit<BaseBtnPros, 'children'>;
type DisplayProps = Omit<ModalProps & {
	onOk?(): void;
	title?: React.ReactNode | null;
	okProps?: ButtonProps;
	okText?: string;
	ref?: React.Ref<{
		setOkBtnProps(props?: ButtonProps): void;
	}>;
}, never>;

const DisplayModal = withPortal<DisplayProps>(React.forwardRef<{
	setOkBtnProps(props?: ButtonProps): void;
}, DisplayProps>((props, ref) => {
	const {
		title, onOk = noop, okText,
		onClose = noop, children, ...otherProps
	} = props;
	const [okProps, setOkProps] = useState<ButtonProps | undefined>(() => props.okProps)

	useLayoutEffect(() => {
		document.body.classList.add('ac-modal-opened-body');
		return () => { document.body.classList.remove('ac-modal-opened-body') }
	}, [])

	useEffect(() => {
		setOkProps(props.okProps);
	}, [props.okProps])

	useImperativeHandle(ref, () => {
		return {
			setOkBtnProps(btnProps?: ButtonProps) {
				setOkProps(Object.assign(props.okProps || {}, btnProps || {}))
			},
		}
	}, [])

	const onCloseHandle = () => {
		onClose();
		document.body.classList.remove('ac-modal-opened-body')
	}

	return (
		<RawModal onClose={onCloseHandle} {...otherProps}>
			<div className='display-modal'>
				{title !== null && <div className='title'>{title}</div>}
				<div className='content'>
					{typeof children === 'function' ? children(onCloseHandle) : children}
				</div>
				<div className='footer'>
					<Button size='medium' {...(okProps || {})} onClick={onOk}>{okText || 'OK'}</Button>
				</div>
			</div>
		</RawModal>
	)
}));

type OperationalProps = Omit<DisplayProps & {
	onCancel?(): void;
	cancelProps?: ButtonProps;
	cancelText?: string;
	ref?: React.Ref<{
		setOkBtnProps(props?: ButtonProps): void;
		setCancelBtnProps(props?: ButtonProps): void;
	}>;
}, never>;

const OperationalModal = withPortal<OperationalProps>(React.forwardRef<{
	setOkBtnProps(props?: ButtonProps): void;
	setCancelBtnProps(props?: ButtonProps): void;
}, OperationalProps>((props, ref) => {
	const {
		onOk = noop, okText,
		onCancel = noop, cancelText,
		onClose = noop, title, children, ...otherProps
	} = props;

	const [okProps, setOkProps] = useState<ButtonProps | undefined>(() => props.okProps)
	const [cancelProps, setCancelProps] = useState<ButtonProps | undefined>(() => props.cancelProps);

	useLayoutEffect(() => {
		document.body.classList.add('ac-modal-opened-body');
		return () => { document.body.classList.remove('ac-modal-opened-body') }
	}, [])

	useEffect(() => {
		setOkProps(props.okProps);
	}, [props.okProps])

	useEffect(() => {
		setCancelProps(props.cancelProps);
	}, [props.cancelProps])

	useImperativeHandle(ref, () => {
		return {
			setCancelBtnProps(btnProps?: ButtonProps) {
				setCancelProps(Object.assign(props.cancelProps || {}, btnProps || {}))
			},
			setOkBtnProps(btnProps?: ButtonProps) {
				setOkProps(Object.assign(props.okProps || {}, btnProps || {}))
			},
		}
	}, [])

	const onCloseHandle = () => {
		onClose();
		document.body.classList.remove('ac-modal-opened-body')
	}

	return (
		<RawModal onClose={onCloseHandle} {...otherProps}>
			<div className='operation-modal'>
				{title !== null && <div className='title'>{title}</div>}
				<div className='content'>
					{typeof children === 'function' ? children(onCloseHandle) : children}
				</div>
				<div className='footer'>
					<Button size='medium' type='minor' {...(cancelProps || {})} onClick={onCancel}>{cancelText || 'Cancel'}</Button>
					<Button size='medium' {...(okProps || {})} onClick={onOk}>{okText || 'OK'}</Button>
				</div>
			</div>
		</RawModal>
	)
}));

export default Modal;
export {
	CloseIcon,
	DisplayModal,
	OperationalModal,
}
