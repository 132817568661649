/* eslint-disable no-mixed-spaces-and-tabs */
import { sendEvent, SendEventParam } from '@utils/core/track';
import AppContext from 'hooks/useModalContext';
import FreeForm from 'pages/offical/home/components/ac-form/FreeForm';
import EnTestFormModal from 'pages/offical/home/components/ac-form/EnTestFormModal';
import { SEOCONTENT } from 'pages/offical/homepage/const';
import React, { ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ConnectState } from 'states/connect';
import { seoAllPath } from 'constants/url';

type AppContainerProps = {
	children: ReactElement | ReactElement[];
};

export type AppContainerState = {
	isShow?: boolean;
	setTrigger(flag: boolean): React.SetStateAction<boolean>;
	setEvent(): void;
	isShowHeader?: boolean;
	setShowHeader(flag: boolean): React.SetStateAction<boolean>;
	isShowFooter: boolean;
	setShowFooter(flag: boolean): React.SetStateAction<boolean>;
	isShowMenu: boolean;
	setShowMenu(flag: boolean): React.SetStateAction<boolean>;
	visibleEnTestFormModal?: boolean;
	setVisibleEnTestFormModal(flag: boolean): React.SetStateAction<boolean>;
	enTestFormModalData?: {
		sourceKey?: string; // 类型
		subjectCode?: string; // 科目
		gradeCode?: string; // 年级
	};
	setEnTestFormModalData(): void;
};

/**
 * 全局容器
 * 这里处理了一些全局的逻辑
 * 预约弹窑组件注册在这里，以便在其他组件中可以调用，使用方式：
 * 		import {useModalContext} from 'hooks/useModalContext';
 * 		const {isShow,setTrigger,setEvent}=useModalContext();
 * 这里还处理了，页面的头部和底部的显示状态，包括页面的菜单显示状态
 * @param props
 * @returns
 */
export default function AppContainer(props: AppContainerProps) {
	const { children, ...rest } = props;
	const local = useSelector((state: ConnectState) => state.app.local);
	const [isShow, setTrigger] = useState(false);

	/* 测试B弹窗 -- 测试表单数据  --start*/
	const [visibleEnTestFormModal, setVisibleEnTestFormModal] = useState(false);
	const [enTestFormModalData, setEnTestFormModalData] = useState({});
	/* 测试B弹窗 -- 测试表单数据  --end*/

	const [isShowHeader, setShowHeader] = useState<AppContainerState['isShowHeader']>(true);
	const [isShowFooter, setShowFooter] = useState<AppContainerState['isShowFooter']>(true);
	const [isShowMenu, setShowMenu] = useState<AppContainerState['isShowMenu']>(true);
	const [event, setEvent] = useState<SendEventParam>({ eventId: '' });
	const { location } = useHistory();
	const [header, setHeader] = useState<React.ReactElement | null>(null);
	const [className, setClassName] = useState<string>('');
	const [extraObject, setObject] = useState<{ [key: string]: any }>({});

	const setExtraObject = (obj: Record<string, any>) => {
		setObject({
			...extraObject,
			...obj,
		});
	};
	//seo
	useEffect(() => {
		const countryCode: string = location.pathname.slice(1).split('/')[0];
		let seoContent: { title: string; desc: string; keyword: string } = SEOCONTENT[countryCode] || SEOCONTENT['default'];
		/* 单独页面的title */
		const index = seoAllPath.findIndex(v=>location.pathname.indexOf(v)>-1)
		if(index!==-1){
			seoContent = SEOCONTENT[seoAllPath[index]]
			console.log(seoContent,'=====>>>>seoContent')
		}
		if (seoContent !== undefined) {
			const title: HTMLTitleElement | null = document.querySelector('title');
			const descMeta: HTMLMetaElement | null = document.querySelector('meta[name=description]');
			const keywordMeta: HTMLMetaElement | null = document.querySelector('meta[name=keywords]');
			title && (title.innerText = seoContent.title);
			descMeta && descMeta.setAttribute('content', seoContent.desc);
			keywordMeta && seoContent.keyword && keywordMeta.setAttribute('content', seoContent.keyword);
		}
	}, [location.pathname]);

	//埋点上报
	useEffect(() => {
		if (event.eventId) {
			sendEvent({
				...event,
			});
		}
	}, [event]);

	useEffect(() => {
		return () => {
			setObject({});
		};
	}, []);

	return (
		<AppContext.Provider
			value={{
				isShow,
				setTrigger,
				setEvent,
				isShowHeader,
				setShowHeader,
				isShowFooter,
				setShowFooter,
				setShowMenu,
				isShowMenu,
				header,
				setHeader,
				className,
				setClassName,
				extraObject,
				setExtraObject,
				/* 测试B弹窗 -- 测试表单数据 */
				visibleEnTestFormModal,
				setVisibleEnTestFormModal,
				enTestFormModalData,
				setEnTestFormModalData,
			}}>
			{Array.isArray(children)
				? children.map((child, index) => {
						return React.cloneElement(child, {
							...rest,
							local,
							key: index,
						});
				  })
				: React.cloneElement(children, {
						...rest,
						local,
				  })}
			<FreeForm />
			<EnTestFormModal />
		</AppContext.Provider>
	);
}
