import React, { memo } from 'react';
import Portal from 'components/portal';
import PopoverInner from './popover-inner';
import { useTrigger, useComposeRef } from './hooks';

interface PopoverProps {
	className?: string;
	contentClassName?: string;
	children: React.ReactNode | null;
	placement?: string; // popover 位置朝向
	content: React.ReactNode;
	autoAdjustOverflow?: boolean; // 气泡被遮挡时是否自动调整位置，默认 false
	adjustDirection?: 'left' | 'right' | 'center';
	ghostTrigger?: boolean; // 是否对气泡做穿透焦点处理，默认 false；若为 true，鼠标移到气泡上会丢失焦点，无法进行点击操作等
	mouseEnterDelay?: number; // 鼠标移入后延时多少才显示，单位：毫秒
	mouseLeaveDelay?: number; // 鼠标移出后延时多少才消失，单位：毫秒
	arrowStyle?: React.CSSProperties; // 箭头样式
	innerStyle?: React.CSSProperties; // 卡片样式
	contentStyle?: React.CSSProperties;
	clickAutoHidden?: boolean;
	onVisibleChange?(visible: boolean): void;
}

type ChildProps = {
	key: string;
	ref: React.MutableRefObject<null> | null;
	onMouseEnter: any;
	onMouseLeave: any;
	onTouchStart: any;
};

function Popover({ children, ...props }: PopoverProps): any {
	const child = React.Children.only(children); // 检验仅有一个节点
	const [composeRef, pureRef] = useComposeRef(child);
	const [visible, triggerHandler] = useTrigger(child, props);
	const childProps: ChildProps = {
		key: 'target',
		ref: composeRef,
		onMouseEnter: triggerHandler.onMouseEnter,
		onMouseLeave: triggerHandler.onMouseLeave,
		onTouchStart: triggerHandler.onMouseEnter,
	};
	return [
		React.cloneElement(child as React.DetailedReactHTMLElement<any, any>, childProps),
		!visible ? null : (
			<Portal key="portal">
				<PopoverInner
					visible={true}
					className={props.className}
					contentStyle={props.contentStyle}
					placement={props.placement}
					content={props.content}
					popoverTarget={pureRef}
					onMouseEnter={triggerHandler.onMouseEnter}
					onMouseLeave={triggerHandler.onMouseLeave}
					clickAutoHidden={props.clickAutoHidden}
					// triggerHandler={triggerHandler}
					ghostTrigger={props.ghostTrigger}
					autoAdjustOverflow={props.autoAdjustOverflow}
					adjustDirection={props.adjustDirection}
					arrowStyle={props.arrowStyle}
					innerStyle={props.innerStyle}
				/>
			</Portal>
		),
	];
}

export default memo(Popover);
