import { Effect, State } from 'jumpstate';
import Logger from '@core/logger';
import { getGrayConfigBatch } from '@services/global';
import { sendLog } from '@utils/core/track';
import i18n, { getDefaultLanguage } from '@fe/i18n/dist/i18n';
import { Token, UserInfo } from './interface';
import ACLocalStorage from '@utils/ac-localStorage';

const logger = Logger.factory('state/global');

export type GlobalStoreState = {
	grayConfigs: Record<string, boolean>;
	appConfig: Record<string, any>;
	local: string;
	countryCode: string;
	user?: UserInfo;
	token?: Token;
};

/**
 * 全局数据处理
 */
const globalStore = State('app', {
	initial: {
		grayConfigs: {}, // 全局灰度配置
		appConfig: {},
		local: getDefaultLanguage(),
		countryCode: '',
		user: ACLocalStorage.getUserInfo(),
		token: ACLocalStorage.getToken()
	},
	/**
	 * 更新全局数据
	 */
	setCommonData(state: GlobalStoreState, payload: GlobalStoreState) {
		logger.info('global data change', state, payload);
		return {
			...state,
			...payload,
		};
	},
	/**
	 * 设置语言
	 * @param state
	 * @param payload
	 * @returns
	 */
	setLocal(state: GlobalStoreState, payload: string) {
		logger.info('global local change', state.local, payload);
		return {
			...state,
			local: payload,
		};
	},

	setCountryCode(state: GlobalStoreState, payload: string) {
		logger.info('global CountryCode change', state.local, payload);
		return {
			...state,
			countryCode: payload,
		};
	},
	setUserInfo(state: GlobalStoreState, payload: UserInfo | undefined) {
		logger.info('global user change', payload);
		ACLocalStorage.setUserInfo(payload);
		return {
			...state,
			user: payload,
		};
	},
	setToken(state: GlobalStoreState, payload: Token | undefined) {
		logger.info('global token change', payload);
		ACLocalStorage.setToken(payload);
		return {
			...state,
			token: payload,
		};
	},
});

/**
 * 应用级别配置
 */
export const loadAppConfig = Effect('loadAppConfig', async () => {
	const res = await getGrayConfigBatch({
		grayCodeList: ['GRAY_WORLD_OUTLOOK'],
		planCodeList: ['PLAN_PLATFORM_UPDATE'],
		map: {
			platform: window.process.platform,
			role: 'student',
		},
	}).catch((err) => {
		sendLog({
			eventId: 'fetch_appconfig_erro',
			eventParam: {
				err,
			},
		});
		return {};
	});
	globalStore.setCommonData({
		appConfig: res,
		newWorldOutlook: !!res['GRAY_WORLD_OUTLOOK'],
	});
	return res;
});
export default globalStore;
