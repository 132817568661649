import React, { useEffect, useMemo, useState, useRef } from 'react';
import cls from '@utils/css-select';
import { SelectMenuProps, Option as OptionType } from './interface';
import Input, { SearchIcon } from 'components/input';
import Option from './option';
import { isMobile } from './utils';
import styles from './styles.scss';

const cx = cls(styles);

const EmptyIcon = '';
const IsMobile = isMobile();
const optionHeight = IsMobile ? 44 : 26;

type Child = React.ReactElement<any, string | React.JSXElementConstructor<any>> | undefined;

export default function Menu(props: SelectMenuProps) {
	const {
		className,
		style,
		children,
		options,
		onSelect,
		notFoundContent,
		onChange,
		selected,
		zIndex,
		allowSearch,
		allowClearSearchValue,
		searchPlaceholder,
		onSearch,
		onClickOutSide,
	} = props;
	const menuRef = useRef<HTMLDivElement | null>(null);
	const listRef = useRef<HTMLDivElement | null>(null);
	const scrollTimer = useRef<any>();
	const [hold, setHold] = useState<OptionType | undefined>();
	const [index, setIndex] = useState<number>(0);
	const [keyword, setKeyword] = useState<string>('');

	// 输入搜索内容
	const onChangeHandle = (keyword: string) => {
		setKeyword(keyword);
		onSearch && onSearch(keyword);
	};

	// 点击选择
	const onSelectHandle = (option: OptionType, index: number) => {
		setHold(option);
		if (IsMobile) {
			setIndex(index);
			// if (listRef.current) listRef.current.scrollTop = index * optionHeight;
			// return;
		}
		onSelect && onSelect(option.value, option);
		onChange && onChange(option);
	};

	// 输入搜索后点击回车搜索
	const onEnterHandle = (keyword: string) => {
		onChangeHandle(keyword);
		onSearch && onSearch(keyword);
	};

	// 外部点击收起
	const clickOutSide = (e: MouseEvent) => {
		if (!menuRef.current) return;
		if (!e.target) return;
		if (!menuRef.current.contains(e.target as HTMLElement)) {
			onClickOutSide && onClickOutSide();
		}
	};
	useEffect(() => {
		document.addEventListener('click', clickOutSide);
		return () => document.removeEventListener('click', clickOutSide);
	}, []);

	// 移动端 滚动监听
	const onScrollHandle = (e: Event) => {
		clearTimeout(scrollTimer.current);
		scrollTimer.current = setTimeout(() => {
			const scrollTop = (e.target as HTMLDivElement).scrollTop;

			const remainder = scrollTop % optionHeight; // 余数
			let rows = Math.floor(scrollTop / optionHeight); // 行数
			if (remainder > optionHeight / 2) {
				rows += 1;
			}
			(e.target as HTMLDivElement).scrollTop = optionHeight * rows;
			setIndex(rows);
		}, 100);
	};
	useEffect(() => {
		if (!IsMobile) return;
		if (!listRef.current) return;
		listRef.current.addEventListener('scroll', onScrollHandle);
		return () => listRef.current?.removeEventListener('scroll', onScrollHandle);
	}, [IsMobile, listRef.current]);

	// 筛选结果列表 options
	const filteredOptions = useMemo<OptionType[]>(() => {
		return options ? options.filter((item) => item.label.toLowerCase().indexOf(keyword.toLowerCase()) > -1) : [];
	}, [options, keyword]);

	// 筛选结果列表 children
	const filteredChildren = useMemo<Child[]>(() => {
		if (!keyword) return children as Child[];
		const list: Child[] = [];
		React.Children.forEach(children, (Child) => {
			if (!Child) return;
			const label = Child?.props?.label || Child?.props?.option.label;
			if (label && label.toLowerCase().indexOf(keyword.toLowerCase()) > -1) {
				list.push(Child);
			}
		});
		return list;
	}, [children, keyword]);

	// 初始锁定已选中选项
	useEffect(() => {
		if (!selected) return;
		// children
		if (children) {
			const child =
				filteredChildren.find((item) => {
					if (!item) return false;
					return (
						item.props.option === selected ||
						(item.props.value === selected.value && (item.props.label || item.props.children) === selected.label)
					);
				}) || filteredChildren.find((item) => item && item.props.value === selected.value);
			const index = filteredChildren.indexOf(child);
			if (child) {
				setHold(child.props.option || { label: child.props.label || child.props.children, value: child.props.value });
				setIndex(index);
				if (listRef.current) listRef.current.scrollTop = index * optionHeight;
			} else {
				setHold(selected);
			}
			return;
		}
		// options
		if (!options) return;
		const child =
			filteredOptions.find((item) => item === selected || (item.value === selected.value && item.label === selected.label)) ||
			filteredOptions.find((item) => item.value === selected.value);
		if (child) {
			const index = filteredOptions.indexOf(child);
			setHold(child);
			setIndex(index);
			if (listRef.current) listRef.current.scrollTop = index * optionHeight;
		} else {
			setHold(selected);
		}
	}, [filteredOptions, filteredChildren, children, options, selected]);

	// Ok 点击确定选择
	const onOkHanlde = () => {
		let hold: OptionType;
		if (children) {
			const child = filteredChildren[index];
			hold = child?.props.option || { value: child?.props.value, label: child?.props.label || child?.props.children };
		} else {
			hold = filteredOptions[index];
		}
		setHold(hold as OptionType);
		onSelect && onSelect((hold as OptionType).value, hold as OptionType);
		onChange && onChange(hold as OptionType);
	};

	// 筛选结果页
	const defaultNotFoundContent = (
		<div className={cx('not-found')}>
			<img loading="lazy" src={EmptyIcon} alt="" />
			{keyword && 'Filter result is empty'}
		</div>
	);
	const empty = (children || []).length === 0 && (options || []).length === 0 ? notFoundContent || defaultNotFoundContent : null;

	return (
		<div className={cx('select-menu', className, { IsMobile, 'allow-search': allowSearch })} style={{ ...style, zIndex }} ref={menuRef}>
			{allowSearch && (
				<header className={cx('header')}>
					<Input
						onChange={onChangeHandle}
						placeholder={searchPlaceholder}
						allowClear={allowClearSearchValue}
						onEnter={onEnterHandle}
						prefix={<SearchIcon />}
						size='small'
					/>
				</header>
			)}
			<header className={cx('bar')}>
				<button onClick={onClickOutSide}>Cancel</button>
				<button onClick={onOkHanlde}>Ok</button>
			</header>
			<div className={cx('list')} ref={listRef}>
				<div className={cx('scroller')}>
					{children
						? filteredChildren.map((item, i) => {
								if (!item) return null;
								const selected = item.props.value === hold?.value;
								const hovered = i === index;
								return React.cloneElement(item, {
									key: `${String(item.props.value)}-${i}`,
									className: cx({ hovered, selected }),
									hovered,
									selected,
									onClick: () =>
										onSelectHandle(item.props.option || { label: item.props.label || item.props.children, value: item.props.value }, i),
								});
								// eslint-disable-next-line no-mixed-spaces-and-tabs
						  })
						: null}
					{!children && options
						? filteredOptions.map((item, i) => {
								const selected = item.value === hold?.value;
								const hovered = i === index;
								return (
									<Option
										className={cx({ hovered, selected })}
										key={`${String(item.value)}-${i}`}
										option={item}
										value={item.value}
										label={item.label}
										hovered={hovered}
										selected={selected}
										onClick={() => onSelectHandle(item, i)}
									/>
								);
								// eslint-disable-next-line no-mixed-spaces-and-tabs
						  })
						: null}
					{empty}
				</div>
			</div>
		</div>
	);
}
