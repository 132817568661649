import { getState } from "jumpstate";
import { generatePath as _generatePath } from "react-router-dom";
import { GlobalStoreState } from "states/app";

export default function generatePath(path: string, otherParam?: Record<string, string | number>) {
	const app: GlobalStoreState = getState().app;

	return _generatePath(path, {
		countryCode: app.countryCode,
		// userId: app.user?.userId,
		...(otherParam || {}), // { id: 12345 }
	});
}
