import request from '@utils/request';
import ApiHost from '@services/api-config';

interface RecentlyLessonInfo {
	lessonStartTime: number;
	[key: string]: any;
}

/**
 * 获取最近课程信息
 */
export const getRecentlyLessonInfo = (): Promise<RequestResponse<RecentlyLessonInfo>> => {
	return request.post(`${ApiHost.airGateway}/air-student-ow-api/api/lesson/getRecentlyLessonCard`);
};
