import React, { useMemo, useRef, useEffect, useState, memo } from 'react';
import { isOverflow, placementStack, isPlacement, getOffset, getPlacementRect } from './utils';
import classnames from 'classnames/bind';
import styles from './styles.scss';

const cx = classnames.bind(styles);
const clsPrefix = `zmkpc-popover`;
const noop = () => null;

/**
 * 获取DIV节点
 * @param target
 */
function getRect(target: React.RefObject<HTMLDivElement>) {
	let dom: HTMLDivElement | null = target.current;
	let rect = null;

	if (!(target && target.current)) {
		return null;
	}

	if (dom && dom.getBoundingClientRect) {
		rect = dom.getBoundingClientRect();
	}

	dom = null;
	return rect;
}

/**
 * 调整元素朝向，使其不超出屏幕区域
 */
function adjust(placement: string, parent: DOMRect, self: React.RefObject<HTMLDivElement>, direction: 'left' | 'right' = 'left') {
	const wh = getRect(self) || { width: 0, height: 0 }; // 元素本身宽高
	let isflow = false;
	let el: Record<string, any> | null = null;
	let pm = placement;
	let offset = {
		left: 0,
		top: 0,
	};
	const ps = placementStack();

	while (!ps.empty()) {
		offset = getOffset(pm, parent, direction);
		el = getPlacementRect(pm, offset, wh, direction);
		isflow = isOverflow(el);
		if (!isflow) {
			// 找到了使得元素不超出容器的方向
			return {
				placement: pm,
				style: {
					left: el.left,
					top: el.top,
				},
			};
		}
		pm = ps.next(pm); // 选举出下一个要校对的方向
	}

	return {
		placement: pm,
		style: offset,
	};
}

/**
 *计算 pop 气泡箭头原点位置
 * @param {*} { target, placement: pm, visible, isMounted, ref, autoAdjustOverflow }
 * @returns
 */
function getPopPostion({
	target,
	placement: pm,
	visible,
	isMounted,
	ref,
	autoAdjustOverflow,
	adjustDirection = 'left',
}: PopoverInnerProps & any) {
	let result = {
		placement: isPlacement(pm) ? pm : 'bottom',
		style: {},
	};

	if (!visible) {
		result.style = {
			display: 'none',
		};
		return result;
	}

	if (!isMounted) {
		return result;
	}
	const parent = getRect(target) as DOMRect;
	if (!parent) {
		return result;
	}

	if (autoAdjustOverflow) {
		// 自动调整朝向
		result = adjust(result.placement, parent, ref, adjustDirection);
	} else {
		// 正常流程，根据朝向计算偏移距离
		result.style = getOffset(result.placement, parent, adjustDirection);
	}

	return result;
}

interface PopoverInnerProps {
	visible: boolean;
	className?: string;
	contentStyle?: React.CSSProperties;
	placement?: string; // popover 位置朝向
	popoverTarget?: any; // 被 popover 包裹的元素的 ref
	onMouseEnter?: () => void;
	onMouseLeave: () => void;
	content: React.ReactNode | null; // 具体显示内容
	autoAdjustOverflow?: boolean; // 气泡超出页面时是否自动调整位置，默认 false
	adjustDirection?: 'left' | 'right' | 'center';
	ghostTrigger?: boolean; // 是否对气泡做穿透焦点处理，默认 false；若为 true，鼠标移到气泡上会丢失焦点，无法进行点击操作等
	arrowStyle?: React.CSSProperties; // 箭头样式
	innerStyle?: React.CSSProperties; // 卡片样式
	clickAutoHidden?: boolean; // 点击后 自动消失
}

/**
 * 移动框内部
 * @param param0
 */
function PopoverInner({
	visible,
	className = '',
	contentStyle = {},
	placement = 'bottom',
	popoverTarget,
	onMouseEnter = noop,
	onMouseLeave = noop,
	content,
	ghostTrigger,
	autoAdjustOverflow = false,
	adjustDirection = 'left',
	arrowStyle = {},
	innerStyle = {},
	clickAutoHidden,
}: PopoverInnerProps): React.SFCElement<PopoverInnerProps> {
	const [isMounted, setMounted] = useState(false);
	const ref = useRef(null);
	const popPostion = useMemo(
		() =>
			getPopPostion({
				target: popoverTarget,
				placement,
				visible,
				isMounted,
				ref,
				autoAdjustOverflow,
				adjustDirection,
			}),
		[popoverTarget, placement, visible, isMounted, autoAdjustOverflow, adjustDirection]
	);
	const cls = cx(`${clsPrefix}`, className, {
		[`${clsPrefix}-${popPostion.placement}`]: popPostion.placement,
		[`${clsPrefix}-enter`]: isMounted,
		ghost: ghostTrigger,
	});

	const contentCls = cx(`${clsPrefix}-content`);

	useEffect(() => {
		setMounted(true);
	}, []);

	return (
		<div
			ref={ref}
			className={cls}
			style={popPostion.style}
			onMouseEnter={ghostTrigger ? noop : onMouseEnter}
			onMouseLeave={ghostTrigger ? noop : onMouseLeave}>
			<div className={contentCls} style={contentStyle} onClick={clickAutoHidden ? onMouseLeave : noop}>
				<div className={cx(`${clsPrefix}-arrow`, adjustDirection)} style={arrowStyle}></div>
				<div className={cx(`${clsPrefix}-content-inner`)} style={innerStyle}>
					{typeof content === 'function' ? content() : content}
				</div>
			</div>
		</div>
	);
}
export default memo(PopoverInner);
