/**
 * 根据朝向，计算气泡相对 body 偏移后的距离 （是父组件的四条边的中点之一）
 * @param placement 气泡方向
 * @param parent
 */
export function getOffset(placement: string, parent: Record<string, any>, dir: string) {
  if (placement === 'bottom') {
    return {
      left: dir === 'left' ? parent.left : parent.left + parent.width,
      top: parent.bottom,
    };
  }

  if (placement === 'top') {
    return {
      left: dir === 'left' ? parent.left : parent.left + parent.width,
      top: parent.top,
    };
  }

  if (placement === 'left') {
    return {
      left: parent.left,
      top: parent.top + parent.height / 2,
    };
  }

  return {
    left: parent.right,
    top: parent.top + parent.height / 2,
  };
}

/**
 * 根据朝向和元素宽高，计算气泡元素最终的上下左右 rect 信息
 */
export function getPlacementRect(
  placement: string,
  offset: Record<string, number>,
  wh: DOMRect | Record<string, number>,
  dir: string
): Record<string, number> {
  if (placement === 'bottom') {
    return {
      left: dir === 'left' ? offset.left + wh.width : offset.left - wh.width,
      right: dir === 'left' ? offset.left + wh.width : offset.left,
      top: offset.top,
      bottom: offset.top + wh.height,
    };
  }

  if (placement === 'top') {
    return {
      left: dir === 'left' ? offset.left : offset.left - wh.width,
      right: dir === 'left' ? offset.left + wh.width : offset.left,
      top: offset.top - wh.height,
      bottom: offset.top,
    };
  }

  if (placement === 'left') {
    return {
      left: offset.left - wh.width,
      right: offset.left,
      top: offset.top - wh.height / 2,
      bottom: offset.top + wh.height / 2,
    };
  }

  return {
    left: offset.left,
    right: offset.left + wh.width,
    top: offset.top - wh.height / 2,
    bottom: offset.top + wh.height / 2,
  };
}

const windowContainer = {
  top: 0,
  left: 0,
  get right() {
    return window.innerWidth;
  },
  get bottom() {
    return window.innerHeight;
  },
};

const PLACEMENTS: Record<string, boolean> = {
  top: true,
  bottom: true,
  left: true,
  right: true,
};

/**
 * 识别所给元素是否超出容器
 */
export function isOverflow(el: Record<string, any>, container = windowContainer) {
  if (el.top < container.top || el.left < container.left || el.bottom > container.bottom || el.right > container.right) {
    return true;
  }

  return false;
}

/**
 * 用于选举下一个方向
 */
export function placementStack() {
  let count = 4;
  // 方向下一个
  const stack: Record<string, any> = {
    bottom: 'right',
    right: 'top',
    top: 'left',
    left: 'bottom',
  };

  return {
    next(x: string) {
      const nx = stack[x];
      if (nx) {
        stack[x] = null;
        count--;
      }
      return nx;
    },
    empty() {
      return count === 0;
    },
  };
}

/** 检查是否为合格的 */
export function isPlacement(x: string) {
  return PLACEMENTS[x];
}
