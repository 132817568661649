import { useLayoutEffect, useState } from 'react';

export default function useResizeObserver() {
    const [width, setWidth] = useState(window.innerWidth);
    const onResizeHandle = () => {
        setWidth(window.innerWidth);
    }

    useLayoutEffect(() => {
        onResizeHandle();
        window.addEventListener('resize', onResizeHandle);
        return () => {
            window.removeEventListener('resize', onResizeHandle);
        }
    }, [])

    return {
        width,
    }
}