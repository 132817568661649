import React, { useEffect, useRef } from 'react';
import cls from '@utils/css-select';
import { SelectOptionProps } from './interface';
import { isMobile } from './utils';
import styles from './styles.scss';

const IsMobile = isMobile();
const cx = cls(styles);

export default function Option (props: SelectOptionProps)  {
  const { className, style, value, label, children, disabled, onClick, option, hovered, selected } = props;
  const optionRef = useRef<HTMLDivElement|null>(null);

  // 选中时 滚动到可视区
  useEffect(() => {
    if (IsMobile) return
    if (!hovered) return
    if (!optionRef.current) return
    const list = optionRef.current.parentElement?.parentElement;
    if (list) {
      list.scrollTop = optionRef.current.offsetTop;
    }
  }, [hovered])

  return (
    <div onClick={onClick} className={cx('select-option', className, { disabled })} ref={optionRef} style={style}>
      {children || label || option?.label}
      {!IsMobile && selected && (
        <span className={cx('selected-icon')} />
      )}
    </div>
  )
}