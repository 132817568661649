import { history } from "@store/history";
import { OperationalModal } from "ac-components/modal";
import { LOGIN } from "constants/url";
import { logout } from "@services/login";
import { sendEvent } from '@core/track';

let destory: () => void = () => {
	throw new Error('Log Out 出错');
};

const btnStyle = {
	// width: 96,
	height: 36,
}

export default function logOut(transData: any) {
	sendEvent({
		eventId: 'EAF_47be7e50145651eb'
	})
	destory = OperationalModal.show({
		title: transData.title,
		size: 'mini',
		okProps: { style: btnStyle },
		okText: transData.logout,
		cancelProps: { style: btnStyle },
		cancelText: transData.cancel,
		async onOk() {
			await logout();
			destory();
			history.replace({
				pathname: LOGIN,
				state: Date.now(),
			})
		},
		onCancel() {
			destory();
		},
	})
}
