import request from '@utils/request';
import ApiHost from '@services/api-config';
import { AxiosPromise } from 'axios';

export interface IAppointmentSource {
	duration?: number; // 时长:秒
	eventType?: number; // 事件类型:1:浏览 2：注册 3：新手机号注册 4: 表单弹窗打开跟关闭时长
	landingPageId?: number | string; // 来源落地页ID 1是main-home  2是1-on-1
	languageId?: number; // 语言ID
	mobile?: string; // 手机号
	sourceKey?: string; // 自定义字段 A 是旧的 B是新测试弹窗
	ip?: string; // ip地址
	areaCode?: string | number; //地区代码
}
export interface IExistByMobile {
	areaCode?: string | number; //地区代码
	mobile?: string; // 手机号
}

/* 注册预约来源管理 */
//  保存注册来源数据
export const appointmentSource = (data?: IAppointmentSource): AxiosPromise<RequestResponse<string>> => {
	if (data?.duration) {
		data.duration = data.duration / 1000;
	}
	return request({
		method: 'post',
		url: `${ApiHost.newAirGateway}/air/landing/page/air/appointment/source`,
		data,
	});
};
// 查询手机号是否已经注册
export const existByMobile = (data?: IExistByMobile): AxiosPromise<RequestResponse<string>> => {
	return request({
		method: 'post',
		url: `${ApiHost.newAirGateway}/air/landing/page/air/appointment/source/user/existByMobile`,
		data,
	});
};
// 查询key:count 测试页出现数据
export const keyCountMap = (data?: IAppointmentSource): AxiosPromise<RequestResponse<string>> => {
	return request({
		method: 'post',
		url: `${ApiHost.newAirGateway}/air/landing/page/air/appointment/source/keyCountMap`,
		data,
	});
};
