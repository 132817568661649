import axios, { Method } from 'axios';
import Logger from '@core/logger';
import { setServerTime } from '@utils/time-utils';

const logger = Logger.factory('network/fetch');
const noop = () => null;
export const APP_HEADERS = {
	// 'App-Version': '1.0.0',
	// 'App-Name': 'KidsH5',
	// 'Api-Version': '3.0.0',
};

type RequestConfig = {
	url: string;
	headers?: Record<string, string>;
	data?: any;
	onUploadProgress?: (progressEvent: any) => void;
	code?: string | number;
	useRawData?: boolean;
	noAutoToken?: boolean;
	timeout?: number;
};

/**
 * 通用接口请求
 * @param method http method
 * @param url
 * @param headers
 * @param data post 请求时携带的 request data
 * @param code 当返回体的 res.data.code 值 === code 时才算请求成功
 * @param useRawData 请求成功时，除了返回 data 也返回 code、message、_status 等信息
 * @param noAutoToken 请求时不需要自动携带 token
 */
const request =
	(method: Method) =>
	({ url = '', headers = {}, data = {}, onUploadProgress = noop, useRawData, code = '0', noAutoToken, timeout = 10000 }: RequestConfig) => {
		let accessToken = '';
		if (!noAutoToken) {
			try {
				// TODO 设置token
				accessToken = '';
			} catch (error) {
				logger.error('get accessToken failed!', JSON.stringify(error));
			}
		}
		return axios({
			url,
			method,
			headers: {
				// accessToken,
				...APP_HEADERS,
				...headers,
			},
			params: method === 'get' ? data : null,
			data,
			onUploadProgress,
			timeout,
		})
			.then((res) => {
				if (res?.headers?.date) {
					setServerTime(res.headers.date);
				}

				const result = res.data;
				// 使得返回值中可以取到 status
				if (result && typeof result === 'object') {
					result._status = res.status;
				}
				if (res.status === 200) {
					if (useRawData) {
						return result;
					}
					if (result.code === code) {
						return result.data;
					}
					throw result;
				}
				throw result;
			})
			.catch((err) => {
				logger.error(`fetch: ${url} error, params: ${JSON.stringify(data)}`, JSON.stringify(err));
				throw err;
			});
	};

export const get = request('get');
export const post = request('post');
