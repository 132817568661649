import Axios from 'axios';
import { toast } from 'components/dialogs';
import ACLocalStorage from './ac-localStorage';
import { history } from '@store/history';
import { LOGIN } from 'constants/url';
import globalStore from 'states/app';
import { getState } from 'jumpstate';

const axios = Axios.create();

axios.defaults.headers.common['Content-Type'] = 'application/json;charset=UTF-8'
axios.defaults.timeout = 10000 // 请求的超时时间
// axios.defaults.withCredentials = true // 需要跨域携带认证

// 请求拦截器
axios.interceptors.request.use(config => {
	const token = ACLocalStorage.getToken();
	if (token?.accessToken) config.headers.accessToken = token?.accessToken;
	config.headers['Accept-Language'] = config.headers['Accept-Language'] || 'en-us' // todo 后面做国际化需要更改
	// config.headers['Accept-Language'] = getState().app.local
	return config
})
// 响应拦截器
axios.interceptors.response.use(res => {
	//极验直接返回
	if (res.config && res.config.url?.includes('/api/sms/api1')) {
		return res.data
	}
	const code: string = res.data?.code || '11'
	if (code !== '0') {
		const errorMsg = res.data?.message || 'Serve error'
		if (code === '11') {
			Promise.reject(errorMsg)
			/* 首页白名单 */
			if(window.location.pathname.includes('/main-home')){
				return
			}
			// TODO:这个地方不应该和业务耦合，目前是没有登录校验接口的，所以token失效暂时在这里处理，后续应该加个登录校验接口来处理业务逻辑
			globalStore.setUserInfo()
			globalStore.setToken()
			history.replace(LOGIN)
			return
		}
	}
	return res.data;
}, (err) => {
	toast({ message: err + '' || 'Serve error' })
	return Promise.reject(err)
})

export default axios;
