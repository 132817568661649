import { sendEvent } from '@utils/core/track';
import AcIcon from 'ac-components/ac-icon';
import classnames from 'classnames';
import React, { useState } from 'react';
import { MenuItem } from './const';
import styles from './styles.scss';

const { NODE_ENV } = process.env;

type MenuProps = {
	list: MenuItem[];
	onSelect(item: MenuItem): void;
	menu?: MenuItem;
	transData: any;
};
const jumpUrl = () => {
	if(NODE_ENV === 'production') {
		console.log('生产环境1')
		window.open('https://airclasskids.com/', '_blank');
	} else {
		console.log('非生产环境2')
		window.open('https://test-2.zhangmenkid.air-learn.com/', '_blank');
	}
};

export default function Menu(props: MenuProps) {
	const { list, onSelect, transData, menu: activeMenu } = props;
	const [curMenu, setMenu] = useState(activeMenu);

	const onClickHandle = (menu: MenuItem) => {
		if (menu?.code === 'china') {
			jumpUrl();
			return;
		}
		const { eventId } = menu;

		onSelect(menu);
		setMenu(menu);
		if (eventId) sendEvent({ eventId });
		if (menu.externalLink) window.open(menu.path||'', '_blank');
	};

	return (
		<menu className={styles.menu}>
			<ul>
				{list.map((menu) => {
					return (
						<li
							className={classnames(styles.menu_item, curMenu?.name == menu?.name ? styles.menu_item_active : '')}
							key={menu.name}
							onClick={() => onClickHandle(menu)}>
							{menu.icon && <AcIcon icon={menu.icon} />}
							{menu.svg && <img className={styles.svg_img} src={curMenu?.name == menu?.name ? menu.svg2 : menu.svg} />}
							<span>{transData[menu.label] || transData[menu.name]}</span>
						</li>
					);
				})}
			</ul>
		</menu>
	);
}
