import React from 'react';
import { BasePopup, PopupProps } from '../popup';
import { withPortal } from 'components/portal';
import cx from 'classnames';

const empty = {};

type ModalProps = PopupProps & {
	closable?: boolean; // 是否显示右上角关闭按钮，默认 true
	animationClass?: { start?: string; end?: string };
	prefix?: 'zmsp-modal' | 'zmsp-new-modal';
};

/** 通用 Modal，带关闭按钮 */
export function BaseModal(props: ModalProps) {
	const { className = '', children = null, closable = true, animationClass = {}, prefix = 'zmsp-modal' } = props || empty;
	const { start } = animationClass;
	return (
		<BasePopup {...props} className={cx(prefix, className, start)}>
			{(_onDestroy) => (
				<>
					{closable && <div className={cx(`close-btn`)} onClick={_onDestroy}></div>}
					{typeof children === 'function' ? children(_onDestroy) : children}
				</>
			)}
		</BasePopup>
	);
}

export const Modal = withPortal<ModalProps>(BaseModal);
