import { useRef, useEffect, useState } from 'react';

/** 复合 ref，不污染被包裹元素原来的 ref */
export function useComposeRef(children: React.ReactNode) {
	const ref = useRef(null) as any;
	const composeRef = useRef(null) as any;
	const cache = useRef(null) as any;

	cache.current = children;

	if (!composeRef.current) {
		composeRef.current = (node: any) => {
			const _child: any = cache.current;
			ref.current = node;
			if (_child && _child.ref) {
				if (_child.ref.current) {
					_child.ref.current = node;
					return;
				}

				if (typeof _child.ref === 'function') {
					_child.ref(node);
					return;
				}
			}
		};
	}

	return [composeRef.current, ref];
}

function fireMouseEvent(componentInstance: any, evname: any, e: any) {
	if (componentInstance && componentInstance.props) {
		const ev = componentInstance.props[evname];
		if (ev) {
			ev(e);
		}
	}
}

/**
 * 计算气泡移入移出延迟时间
 */
function getDelay(props: any, visible: boolean) {
	if (visible) {
		if (typeof props.mouseEnterDelay === 'number') {
			return props.mouseEnterDelay;
		}
		return 0;
	}

	if (typeof props.mouseLeaveDelay === 'number') {
		return props.mouseLeaveDelay;
	}

	return 250;
}

/** 提供统一的鼠标触发显隐事件 */
export function useTrigger(target: React.ReactNode, props: Record<string, any>) {
	const [visible, setVisible] = useState(false);
	const cache = useRef({}) as any;
	const mouseTriggers = useRef(null) as any;
	const timer = useRef(0) as any;

	cache.current = {
		visible,
		setVisible,
		target,
		mouseEnterDelay: props.mouseEnterDelay,
		mouseLeaveDelay: props.mouseLeaveDelay,
		onVisibleChange: props.onVisibleChange,
	};

	if (props.forceVisible !== undefined) {
		// NOTE: 强制显隐，仅用于调试
		cache.current.visible = props.forceVisible;
	}

	if (props.disabeld) {
		// popover 不可用，强制隐藏即可
		cache.current.visible = false;
	}

	// NOTE: 通过访问 cache，以下函数和对象仅需初始化生成一次
	if (!mouseTriggers.current) {
		const lazySetVisible = (v: boolean) => {
			clearTimeout(timer.current);
			if (!!v === cache.current.visible) {
				return;
			}
			const wait = getDelay(cache.current, !!v);
			// 延迟一段时间再改变显隐，防止意外失焦
			timer.current = setTimeout(() => {
				cache.current.setVisible(v);
				if (cache.current.onVisibleChange) {
					cache.current.onVisibleChange(v);
				}
			}, wait);
		};

		mouseTriggers.current = {
			onMouseEnter: (e: React.MouseEvent<HTMLDivElement>) => {
				lazySetVisible(true);
				fireMouseEvent(cache.current.target, 'onMouseEnter', e);
			},
			onMouseLeave: (e: React.MouseEvent<HTMLDivElement>) => {
				lazySetVisible(false);
				fireMouseEvent(cache.current.target, 'onMouseLeave', e);
			},
		};
	}

	useEffect(() => {
		if (props.forceVisible !== undefined && !props.disabeld) {
			// NOTE: 强制显隐，仅用于调试
			setVisible(props.forceVisible); // 强制 re-render
		}
		return () => clearTimeout(timer.current);
	}, [props.disabeld, props.forceVisible]);

	return [cache.current.visible, mouseTriggers.current];
}
