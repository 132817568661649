import { get, post } from '@core/network';
import ApiHost from './api-config';

/**
 * 获取灰度开关（批量）
 *
 * http://app-gateway-test.zmlearn.com/kids/api/grayApi/swagger-ui.html#!/gray-endpoint/findBatchGrayEffectByCodeAndParamUsingPOST
 * @param {Array<string>} grayCodeList 灰度名称列表
 * @param {object} maps 灰度查询参数
 */
export function getGrayConfig({ grayCodeList, maps = {} }: { grayCodeList: string[]; maps: Record<string, number | unknown | string> }) {
	return post({
		url: `${ApiHost.appGateWay}/kids/api/grayApi/toc/gray/findBatchGrayEffectByCodeAndParam`,
		data: {
			grayCodeList,
			maps,
		},
	});
}

/**
 * 获取灰度配置
 *
 * http://app-gateway-test.zmlearn.com/kids/api/grayApi/swagger-ui.html#!/gray-endpoint/findBatchGrayEffectByCodeAndParamUsingPOST
 * @param {Array<string>} planCodeList 灰度名称列表
 * @param {object} map 灰度查询参数
 */
export function getGrayConfigBatch({
	planCodeList,
	map = {},
	grayCodeList,
}: {
	planCodeList?: string[];
	map: Record<string, number | string>;
	grayCodeList?: string[];
}) {
	return post({
		url: `${ApiHost.appGateWay}/kids/api/grayApi/toc/gray/findMany`,
		data: {
			grayCodeList,
			planCodeList,
			map,
		},
	});
}

/**
 * 网速（老接口）
 */
export function getNetworkSpeed() {
	return get({
		url: `${ApiHost.appGateWay}/kids/lessons/load-balancing/ping`,
		headers: {
			'App-Version': '1.4.0',
			'App-Name': 'KidsPC',
			'Api-Version': '2.2.0',
		},
	});
}

/**
 * 请求皮肤
 */
export function getAppTheme() {
	return get({
		url: `${ApiHost.appGateWay}/kids/resources/pc/theme`,
		headers: {
			'App-Version': '3.0.0',
		},
	});
}

/**
 * 请求皮肤
 */
export function getApiIp() {
	return get({
		url: `${ApiHost.airClassRoot}/air-ips/api/ip`,
	});
}
