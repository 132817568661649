import React, { useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import Input from 'ac-components/ac-ipt';
import debounce from 'lodash.debounce';
import Trigger from 'rc-trigger';
import 'rc-trigger/assets/index.css';
import cls from '@utils/css-select'
import { isMobile } from 'components/select/utils';
import styles from './styles.scss';
import AcIcon from 'ac-components/ac-icon';

const cx = cls(styles)
const IsMobile = isMobile();
const noop = () => { }

const EmptyImg = 'https://web-data.zmlearn.com/image/rd5fWKowMYhDcBVQ3E9YBY/empty.png';

export type Option = {
	id: string | number;
	name: string;
	[propsName: string]: any;
}
type MenuItemProps = {
	option: Option;
	selected?: boolean;
	renderItem?: React.FC<{ option: Option; selected?: boolean }>
	| React.ReactElement<{ option: Option; selected?: boolean }>;
	onClick?(item: Option): void;
}
function MenuItem(props: MenuItemProps) {
	const { option, selected, onClick = noop, renderItem } = props;
	const itemRef = useRef<HTMLDivElement | null>(null);

	useEffect(() => {
		if (!selected) return
		const dom = itemRef.current;
		if (!dom) return
		const parent = dom.parentElement;
		if (!parent) return
		setTimeout(() => {
			parent.scrollTop = dom.offsetTop;
		}, 100)
	}, [])

	return (
		<div
			className={cx('menu_item', { actived: selected, custom: !!renderItem })}
			onClick={() => onClick(option)}
			ref={itemRef}
		>
			{
				renderItem && (
					typeof renderItem === 'function'
						? React.createElement(renderItem, { option, selected })
						: React.cloneElement(renderItem, { option, selected })
				)
			}
			{!renderItem && (
				<>
					{option.name}
					{!IsMobile && selected && (
						<span className={cx('selected-icon')} />
					)}
				</>
			)
			}
		</div>
	)
}

type BaseProps = {
	className?: string;
	style?: React.CSSProperties;
	selected?: Option;
	onChange?: (item: Option) => void;
	list?: Option[];
	renderItem?: React.FC<{ option: Option; selected?: boolean; onClick(): void }>
	| React.ReactElement<{ option: Option; selected?: boolean; onClick(): void }>;
	allowSearch?: boolean;
	placeholder?: string;
	searchPlaceholder?: string;
	notFoundContent?: React.ReactNode;
	menuTitle?: React.ReactNode;
	filters?: string[]; // 筛选匹配字段
	width?: number | string;
	height?: number | string;
}
type MenuProps = Omit<BaseProps & {
	onClose(): void;
}, never>;

function Menu(props: MenuProps) {
	const {
		list = [], onChange, selected, renderItem, width, height, allowSearch,
		searchPlaceholder, notFoundContent, menuTitle, onClose, filters = ['id', 'name']
	} = props;
	const [keyword, setKeyword] = useState<string>('');
	const [query, setQuery] = useState<string>('');
	const listRef = useRef<HTMLDivElement | null>(null);

	const onChangeQuery = (query: string) => {
		setQuery(query);
		if (!listRef.current) return
		listRef.current.scrollTop = 0;
	}
	const debounceSetQueryRef = useRef(debounce(onChangeQuery, 200));

	const onChangeHandle = (option: Option) => {
		onChange && onChange(option);
	}
	const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value;
		setKeyword(value);
		debounceSetQueryRef.current(value.trim());
	}

	const result = useMemo(() => {
		if (!query) return list;
		const safeQuery = query.trim().replace(/(\+|\.\?|\^|\$|\*|\(|\)|\{|\}|\[|\]|\\|\/|)/g, '');
		if (!safeQuery) return list;
		let regExp: RegExp | null;
		try {
			regExp = new RegExp(safeQuery, 'i');
		} catch (err) {
			regExp = null;
			console.log(`new RegExp(${safeQuery}, 'i') catch error: `, err)
		}
		if (!regExp) return list;
		return list.filter(item => {
			for (const key of filters) {
				if (item[key] && regExp?.test(`${item[key]}`)) return true;
			}
			return false;
		})
	}, [list, query, filters]);

	const menuStyle = useMemo(() => {
		const style: React.CSSProperties = {};
		if (width) style.width = width;
		if (height) style.height = height;
		return style;
	}, [width, height]);

	return (
		<menu className={cx('select_menu', { m: IsMobile })} style={menuStyle}>
			<div className={cx('menu_header')}>
				{IsMobile && menuTitle && (
					<div className={cx('menu_title')}>
						{menuTitle}
						<span className={cx('close')} onClick={onClose}>
							<AcIcon icon='icon_close' />
						</span>
					</div>
				)}
				{allowSearch && (
					<Input
						prefix={<AcIcon icon='icon_search' />}
						style={{ 'height': 36 }}
						placeholder={searchPlaceholder}
						value={keyword}
						onChange={onSearchChange}
					/>
				)}
			</div>
			<div className={cx('menu_content')} ref={listRef}>
				{
					result.map(item => {
						const actived = selected && selected.id === item.id;
						return (
							<MenuItem
								key={`${item.id}-${item.name}`}
								option={item}
								renderItem={renderItem}
								selected={actived}
								onClick={onChangeHandle}
							/>
						)
					})
				}
			</div>
			{result.length === 0 && (notFoundContent || (
				<div className={cx('menu_empty')}>
					<img src={EmptyImg} alt='Empty' />
					Sorry, no results found
				</div>
			))}
		</menu>
	)
}

export const Arrow = (props: { drop: boolean; className?: string; style?: React.CSSProperties }) => {
	const { className, drop, style } = props;
	return (
		<span className={classNames(className, cx('arrow'), { [cx('drop')]: drop })} style={style}>
			<AcIcon icon='icon_dropdown' />
		</span>
	)
}

export type SelectProps = Omit<BaseProps & {
	children?: React.ReactNode | React.FC<{ visible: boolean; value?: Option }>;
	disabled?: boolean; // 暂未实现
	action?: string[];
	onVisibleChange?: (visible: boolean) => void;
	zIndex?: number;
}, never>;
export default function Select(props: SelectProps) {
	const {
		className, style, children, width, onVisibleChange, action = ['click'],
		zIndex, selected, onChange, placeholder, disabled, ...otherProps
	} = props;
	const [visible, setVisible] = useState<boolean>(false);
	const [hold, setHold] = useState<Option | undefined>(() => selected);
	const containerRef = useRef<HTMLDivElement | null>(null);
	const onVisibleChangeHandle = (visible: boolean) => {
		setVisible(visible)
		if (IsMobile) {
			document.body.classList.toggle('ac-select-droped-body');
		}
		if (typeof onVisibleChange === 'function') {
			onVisibleChange(visible);
		}
	}
	const onChangeHandle = (option: Option) => {
		setHold(option);
		if (typeof onChange === 'function') {
			onChange(option);
		}
		onVisibleChangeHandle(false);
	}
	const renderChild = () => {
		if (typeof children === 'function') {
			return children({ visible, value: hold });
		}
		if (children) {
			return (
				<>
					{children}
					<Arrow drop={visible} />
				</>
			)
		}
		return <Input value={hold?.name} placeholder={placeholder} readonly suffix={<Arrow drop={visible} />} />
	}

	useEffect(() => {
		setHold(selected);
	}, [selected]);

	return (
		<Trigger
			popupVisible={visible}
			popup={(
				<>
					{IsMobile && <div className={cx('menu_mask')} onClick={() => onVisibleChangeHandle(false)} />}
					<Menu
						selected={hold}
						onChange={onChangeHandle}
						onClose={() => onVisibleChangeHandle(false)}
						{...otherProps}
						width={IsMobile ? undefined : (width || containerRef.current?.offsetWidth)}
					/>
				</>
			)}
			popupAlign={{
				points: ['tl', 'bl'],
				offset: [0, 3],
				overflow: { adjustX: true, adjustY: true },
			}}
			action={action}
			onPopupVisibleChange={onVisibleChangeHandle}
			destroyPopupOnHide={false}
			zIndex={zIndex}
			disabled={disabled}
		>
			<div className={classNames(className, styles.ac_select, { visible })} style={style} ref={containerRef}>
				{renderChild()}
			</div>
		</Trigger>
	)
}

