type AnimationEnum = 'fadeIn' | 'popUpCenter';

export type AnimationConfig = {
	start: string;
	end: string;
};

const DEFAULT_ANI_TYPE = 'popUpCenter';

const animationType: Record<string, AnimationConfig> = {
	fadeIn: {
		start: 'fadeIn',
		end: 'fadeOut',
	},
	popUpCenter: {
		start: 'popUpCenter-s',
		end: 'popUpCenter-e',
	},
};

/**
 *
 * @param {*} type 动画类型
 */
export function getAnimation(type: string = DEFAULT_ANI_TYPE) {
	return animationType[type || DEFAULT_ANI_TYPE];
}
