
import { PRICES, TUTORS, EN_DOWNLOAD, EN_BECOME_TUTORS, INVITE_FRIENDS, PERSONAL, LOGIN, FAQ, MAIN_HOME, PARTNER, ONE_ON_ONE_ES, ONE_ON_ONE_EN, HAPPY_KIDS_ES, HAPPY_KIDS_EN } from 'constants/url';
import logOut from '../logout';
export type MenuItem = {
	icon?: string;
	eventId?: string;
	label: string;
	name: string;
	path?: string;
	aliasPath?: string[];
	parent?: string;
	login?: boolean;
	onClick?(props?: any): void;
	code?: string;
	/** 只在移动端渲染 */
	mobileOnly?: boolean;
	/** 路由 参数 */
	pathParams?: {[key: string]: any};

	/** 外链: 跳转到第三方地址 */
	externalLink?: boolean;
	/** 移动端展示 */
	isMobile?: boolean;
	/** svg */
	svg?: string;
	/** svg 选中 */
	svg2?: string;
}

// 顺序不要改
export const MenuList: MenuItem[] = [
	{
		icon: 'icon_home',
		eventId: 'mainhome',
		label: '',
		name: 'home',
		path: MAIN_HOME,
		isMobile: true
	},
	{
		icon: 'icon_1on1',
		eventId: 'ACW_nav_1on1',
		label: '',
		name: 'one_on_one',
		aliasPath: [ONE_ON_ONE_ES,ONE_ON_ONE_EN],
		isMobile: true
	},
	{
		icon: 'icon_happykids',
		eventId: 'ACW_nav_happykids',
		label: '',
		name: 'happy_kids',
		aliasPath: [HAPPY_KIDS_ES,HAPPY_KIDS_EN],
		isMobile: true
	},
	// {
	// 	svg: 'https://web-data.zmlearn.com/image/wcH7dvKt1X2yAsxfxm6deS/icon_blog.svg',
	// 	svg2: 'https://web-data.zmlearn.com/image/wTdWF4Yx5aBy9vR7LfebiY/icon_blog%201.svg',
	// 	eventId: 'AirClassOWS_airClass_blog',
	// 	label: 'AirClass Blog',
	// 	name: 'airClassBlog',
	// 	externalLink: true,
	// 	isMobile: true,
	// 	path: 'https://blog.air-class.com',
	// },
	{
		icon: 'icon_ourtutors',
		eventId: 'ACW_nav_ourtutor',
		label: '',
		name: 'our_tutors',
		path: TUTORS,
		isMobile: true
	},
	{
		svg: 'https://web-data.zmlearn.com/image/xrXZAFoKoyXZNS4bFDvLXv/icon_prices.svg',
		svg2: 'https://web-data.zmlearn.com/image/sJtQtf3qXgYytHtitWX4z9/icon_prices%20(1).svg',
		eventId: 'ACW_nav_prices',
		label: '',
		name: 'prices',
		path: PRICES,
		isMobile: true
	},
	{
		icon: 'icon_howitwork',
		eventId: 'ACW_nav_partner',
		label: '',
		name: 'partner',
		path: PARTNER,
		isMobile: true
	},
	{
		icon: 'icon_becomeatutor',
		eventId: 'ACW_nav_becomeatutor',
		label: '',
		name: 'become_a_tutor',
		path: EN_BECOME_TUTORS,
		isMobile: true
	},
	{
		icon: 'icon_download',
		eventId: 'ACW_nav_appdownload',
		label: '',
		name: 'app_download',
		path: EN_DOWNLOAD,
		isMobile: true
	},
	{
		icon: 'icon_invitegift',
		eventId: 'ACW_nav_invitegift',
		label: '',
		name: 'invite_gift',
		path: INVITE_FRIENDS,
		login: true,
	},
	// {
	// 	icon: 'icon_setting',
	// 	eventId: 'ACW_nav_settings',
	// 	label: '',
	// 	name: 'setting',
	// 	path: PERSONAL,
	// 	login: true,
	// 	pathParams: {
	// 		activeId: 1
	// 	}
	// },
	// {
	// 	svg: 'https://web-data.zmlearn.com/image/8JRH4sf45mBEsoVikTVWic/icon_taecher.svg',
	// 	svg2: 'https://web-data.zmlearn.com/image/8JRH4sf45mBEsoVikTVWic/icon_taecher.svg',
	// 	eventId: 'AirClassOWS_more_teachonac',
	// 	label: '',
	// 	name: 'teachOnAirClass',
	// 	externalLink: true,
	// 	path:
	// 		process.env.NODE_ENV === 'development' ? 'http://tutor-fat.air-class.com/' : 'https://tutor.air-class.com/',
	// },
	{
		icon: 'icon_faqs',
		eventId: 'ACW_nav_faq',
		label: '',
		name: 'faq',
		path: FAQ,
		// login: false,
		isMobile: true
	},
	{
		icon: 'icon_logout',
		eventId: 'ACW_nav_logout',
		label: '',
		name: 'logout',
		login: true,
		onClick: logOut,
		mobileOnly: true,
	},
	{
		icon: 'icon_login',
		eventId: 'ACW_nav_login',
		label: '',
		name: 'login',
		path: LOGIN,
		login: false,
		mobileOnly: true,
		isMobile: true
	},
];

export const PersonalMenus: MenuItem[] = [
	{
		icon: 'icon_courses',
		label: '',
		name: 'courses',
		path: PERSONAL,
		pathParams: {
			activeId: 1,
		},
	},
	{
		icon: 'icon_profileinfo',
		label: '',
		name: 'profileInfo',
		path: PERSONAL,
		pathParams: {
			activeId: 2,
		},
	},
	{
		icon: 'icon_transactionrecord',
		label: '',
		name: 'transactionRecord',
		path: PERSONAL,
		pathParams: {
			activeId: 3,
		},
	},
	{
		icon: 'icon_logout',
		eventId: 'ACW_nav_logout',
		label: '',
		name: 'logout',
		login: true,
		onClick: logOut,
		mobileOnly: true,
	},
];

export const HomeMenus: MenuItem[] = [
	{
		icon: 'icon_home',
		eventId: 'mainhome',
		label: '',
		name: 'main_home',
		path: MAIN_HOME,
	},
];
