/**
 * @Description:
 * @author WZP
 * @date 2021/7/21
 */

import { get, post } from '@core/network';
import ApiHost from '@services/api-config';
import { LangNumber } from 'constants/country';
import { getState } from 'jumpstate';

/**
 * 功能集合接口
 * @param id
 * @param segmentCode
 */
export function fetchApplyTutor(id: number, data: Record<string, any>, useRawData?: boolean) {
	const lang = LangNumber[getState().app.local] || '2'

	return post({
		url: `${ApiHost.newAirGateway}/air/landing/page/air/appointment/${id}/${lang}`,
		data: {
			id,
			segmentCode: 'landingPageWithName',
			...data,
		},
		useRawData,
		code: 0,
	});
}

/**
 * 获取科目字典
 * @param id  英国落地页id: 4 美国落地页id: 5 加拿大落地页id: 6 爱尔兰落地页id: 7 新西兰落地页id: 8 澳大利亚落地页id: 9
 * @returns
 */
export const getSubjectDictInfo = (id: number) => {
	return post({
		url: `${ApiHost.newAirGateway}/air/landing/page/air/appointment/${id}/2`,
		data: {
			id,
			segmentCode: 'dictInfo',
		},
		code: 0,
	});
};

/**
 * 获取西班牙科目字典
 * @returns
 */
export const getEspanaDictInfo = () => {
	return get({
		url: `${ApiHost.newAirGateway}/air/landing/page/dict/ESP/1`,
	});
};

/**
 * 获取本地Ip
 * @param id
 * @returns
 */
export const getLocationIp = () => {
	return get({
		url: `${ApiHost.airGateway}/air-ips/api/ip`,
	});
};
