import React, { useState, useEffect } from 'react';
import { useModalContext } from 'hooks/useModalContext';
import classnames from 'classnames';
import styles from './EnTestFormModalStyle.scss';
import Modal from 'ac-components/modal';
import AcIcon from 'ac-components/ac-icon';
import Select, { Option } from 'components/select';
import { optionData, IInitFormData, IProps, ISubject, IGrade } from './enTest';
import { closeEvent } from '@utils/testForm';

// 引入国旗图标svg文件 iconfont
(() => {
	const script = document.createElement('script');
	script.setAttribute('type', 'text/javascript');
	script.setAttribute('src', 'https://web-data.zmlearn.com/iconfont/font_2911754_0be13wduvqt4/iconfont.js');
	document.getElementsByTagName('head')[0].appendChild(script);
})();

/**
 * 1-on-1 英文版本 b版本测试弹窗
 */

export default function EnTestModal(props: IProps) {
	const [optionType, setOptionType] = useState(optionData.init.type);
	const [selectText, setSelectText] = useState(''); // 点击选中某项文案
	const initFormData: IInitFormData = {
		gradeCode: '',
		subjectCode: '',
		age: '',
		init: optionData.init.type,
	};
	const [formData, setFormData] = useState(initFormData);

	const { visibleEnTestFormModal, setVisibleEnTestFormModal, className, setTrigger, enTestFormModalData, setEnTestFormModalData } =
		useModalContext();

	const onSelect = (data: any, key: string) => {
		setSelectText(data.code);
		setFormData({ ...formData, [key]: data.code });
	};

	// 点击选中某一项
	const onSelectOpt = (item: string) => {
		setSelectText(item === selectText ? '' : item);
	};

	// 点击继续
	const onNext = () => {
		if (!selectText) {
			return;
		}
		const currentData = { ...formData };
		let isNext = false;
		/*
		 * 场景一: 初始化 -- 选择考试
		 * 场景二: 初始化 -- 选择年级
		 * 场景三: 初始化 -- 选择科目 -- 选择年级
		 * 场景四: 初始化 -- 反馈收集
		 * 最终走向都是form表单
		 */
		if (optionType === optionData.init.type) {
			const index = optionData.init.list.findIndex((v: string) => v === selectText);
			const type = optionData.init.nextList[index] as string;
			currentData.init = index + '';
			setOptionType(type);
			isNext = true;
		} else if (optionType === optionData.subject.type && currentData.init === '2') {
			setOptionType(optionData.age.type);
			isNext = true;
		}
		setFormData({ ...currentData });
		setSelectText('');
		if (!isNext) {
			setTrigger(true);
			setVisibleEnTestFormModal(false);
			setEnTestFormModalData({ ...enTestFormModalData, gradeCode: formData.gradeCode, subjectCode: formData.subjectCode });
		}
	};

	const onClose = () => {
		closeEvent(enTestFormModalData.sourceKey);
		setVisibleEnTestFormModal(false);
	};

	useEffect(() => {
		if (!visibleEnTestFormModal) {
			setSelectText('');
			setOptionType(optionData.init.type);
			setFormData(initFormData);
		}
	}, [visibleEnTestFormModal]);

	return (
		<Modal
			show={props.visibleEnTestFormModal !== undefined ? props.visibleEnTestFormModal : visibleEnTestFormModal}
			onClose={onClose}
			className={classnames(styles.ac_en_test_modal, className, 'test-form-modal')}>
			<div className={styles.form_wrap}>
				<h6 className={styles.modal_title}>Get a free 1-on-1 lesson</h6>
				<h2 className={styles.question_title}>{optionData[optionType]?.title || optionData.init.title}</h2>
				<div className={styles.option_warp}>
					<div className={styles.option_content}>
						{/* 默认弹窗-- 选择学习目的 */}
						{optionType === optionData.init.type && (
							<>
								{optionData.init.list.map((item: string) => (
									<div
										className={classnames(styles.item_purpose, styles.item_com, selectText === item && styles.select)}
										key={item}
										onClick={() => {
											onSelectOpt(item);
										}}>
										{item}
										{selectText === item && <AcIcon icon={'icon_selected'} className={styles.icon} color="#fff" />}
									</div>
								))}
							</>
						)}

						{/* 选择考试 */}
						{optionType === optionData.exam.type && (
							<>
								{optionData.exam.list.map((item: string) => (
									<span
										className={classnames(styles.item_exam, styles.item_com, selectText === item && styles.select)}
										key={item}
										onClick={() => {
											onSelectOpt(item);
										}}>
										{item}
										{selectText === item && <AcIcon icon={'icon_selected'} className={styles.icon} color="#fff" />}
									</span>
								))}
							</>
						)}

						{/* 选择科目 */}
						{optionType === optionData.subject.type && (
							<>
								{optionData.subject.list.map((item: ISubject, i: number) => (
									<div
										className={classnames(styles.item_subject, styles.item_com, selectText === item.subjectCode && styles.select)}
										key={i}
										onClick={() => {
											onSelectOpt(item.subjectCode);
											setFormData({ ...formData, subjectCode: item.subjectCode });
										}}>
										<span>{item.subjectName}</span>
										<AcIcon htmlType="svg" icon={item.icon} />
										{selectText === item.subjectCode && <AcIcon icon={'icon_selected'} className={styles.icon} color="#fff" />}
									</div>
								))}
							</>
						)}

						{/* 选择年级 */}
						{optionType === optionData.grade.type && (
							<div className={styles.item_select}>
								<span className={styles.item_label}>Grade</span>
								<div>
									<Select
										className={styles.select_opt}
										selected={{ value: formData.gradeCode, label: '' }}
										placeholder="Your kid’s grade"
										onChange={(option) => onSelect({ code: option.value, text: option.label }, 'gradeCode')}
										allowSearch={false}
										size="large"
										zIndex={1000}
										showArrow={true}>
										{optionData.grade.list.map((v: IGrade) => {
											return (
												<Option value={v.gradeCode} key={v.gradeCode} option={{ label: v.gradeName, value: v.gradeCode }}>
													{v.gradeName}
												</Option>
											);
										})}
									</Select>
								</div>
							</div>
						)}

						{/* 选择年龄 */}
						{optionType === optionData.age.type && (
							<div className={styles.item_select}>
								<span className={styles.item_label}>Age</span>
								<div>
									<Select
										className={styles.select_opt}
										selected={{ value: formData.age, label: '' }}
										placeholder="Your kid’s age"
										onChange={(option) => onSelect({ code: option.value, text: option.label }, 'age')}
										allowSearch={false}
										size="large"
										zIndex={1000}
										showArrow={true}>
										{optionData.age.list.map((v: string) => {
											return (
												<Option value={v} key={v} option={{ label: v, value: v }}>
													{v}
												</Option>
											);
										})}
									</Select>
								</div>
							</div>
						)}

						{/* 收集反馈 */}
						{optionType === optionData.feedback.type && (
							<div className={styles.textarea}>
								<textarea
									placeholder="Please write your needs"
									value={formData.feedback}
									onInput={(event) => {
										const val = (event.target as HTMLInputElement).value;
										if (val) {
											setSelectText(val);
										}
										if (val.length > 300) {
											val.slice(0, 300);
										} else {
											setFormData({ ...formData, feedback: val });
										}
									}}
								/>
								<span className={classnames(styles.limit, formData.feedback?.length === 300 && styles.limit_sign)}>
									{formData.feedback?.length || 0}/300
								</span>
							</div>
						)}
					</div>
				</div>
			</div>
			<div className={styles.btn_box}>
				<span className={classnames(styles.btn, !selectText && styles.disabled)} onClick={onNext}>
					Continue
				</span>
			</div>
		</Modal>
	);
}
