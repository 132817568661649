import { createContext, useContext } from 'react';
/**
 * @description 创建context复用
 * @date 2022-02-19
 * @export
 * @param {*} value
 * @returns {*}  
 */

const AppContext = createContext<any>({});

export function useModalContext<T>() {
    return useContext<T>(AppContext);
}

export default AppContext