/* eslint-disable no-mixed-spaces-and-tabs */
import i18n from '@fe/i18n/dist/i18n';
import { history } from '@store/history';
import { sendEvent } from '@utils/core/track';
import generatePath from '@utils/generatePath';
import AcIcon from 'ac-components/ac-icon';
import classnames from 'classnames';
import { Popup } from 'components/dialogs';
import Popover from 'components/popover';
import { isMobile } from 'components/select/utils';
import { COUNTRY_CONFIG, LANG } from 'constants/country';
import { COUNTRY_HOMEPAGE, EN_HOMEPAGE,CN_HOMEPAGE , INVITE_FRIENDS, LOGIN, MAIN_HOME, STEP_APPLY_TUTOR, TINT_APPLY_TUTOR, ONE_ON_ONE_ES, ONE_ON_ONE_EN, HAPPY_KIDS_ES, HAPPY_KIDS_EN } from 'constants/url';
import useResizeObserver from 'hooks/use-resize-observer';
import { useTranslation } from 'hooks/use-translation';
import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { NavLink, useHistory } from 'react-router-dom';
import globalStore, { GlobalStoreState } from 'states/app';
import { ConnectState } from 'states/connect';
import { HomeMenus, MenuItem, MenuList, PersonalMenus } from './const';
import Menu from './menu';
import CourseRemind from './course-remind';
import styles from './styles.scss';

// const t = i18n.getMessages('header');
let destory: Function | null;
const AvatarImg = 'https://web-data.zmlearn.com/image/qhnwXiVv7CcoLqmCnm2BsR/avatar_default_large.png';

enum Mode {
	aside = 'aside', //移动端
	drop = 'drop', //PC端
}

export default function NavMenu() {
	const { location } = useHistory();
	const { countryCode, user, token, local }: GlobalStoreState = useSelector((state: ConnectState) => state.app);
	const [mode, setMode] = useState<Mode>(Mode.aside);
	const [menu, setMenu] = useState<MenuItem>(HomeMenus[0]);
	const [visible, setVisible] = useState<boolean>(false);
	const [personalMenuVisible, setPersonalMenuVisible] = useState<boolean>(false);
	const [isShowLang, setVisibleLang] = useState<boolean>(false);
	const { pathname, search } = useHistory().location;
	const { t, transData } = useTranslation('header');
	const { width } = useResizeObserver();
	const onResizeHandle = () => {
		const width = document.body.clientWidth;
		setMode(width < 768 ? Mode.aside : Mode.drop);
	};

	const getPathVal = (item: MenuItem) => {
		/* 多语言情况下 路由名称不同的处理 */
		if (Array.isArray(item.aliasPath)) return item.aliasPath.find((v) => v.includes(local));
		return item.path ? generatePath(item.path, item.pathParams || {}) : item.path;
	};

	useLayoutEffect(() => {
		onResizeHandle();
		window.addEventListener('resize', onResizeHandle);
		return () => {
			window.removeEventListener('resize', onResizeHandle);
		};
	}, []);

	useEffect(() => {
		//  判断是否登录,登录状态下，不可以跳转到登录页面
		if (token?.isRegister && pathname === LOGIN && token && !token.isRegister) {
			history.replace(generatePath(MAIN_HOME));
		}
	}, []);

	useEffect(() => {
		//兼容之前6国落地页语言的问题
		const isEn = pathname === '/en' || COUNTRY_CONFIG.find((item) => item.countryCode === pathname);
		if (isEn) {
			i18n.setLocale('en');
			globalStore.setCountryCode('en');
		}
	}, [countryCode]);

	const menuList = useMemo(() => {
		const list: MenuItem[] = [];
		MenuList.forEach((item) => {
			if (item.login === false && token) return; // 未登录才展示
			if (item.login && !token) return; // 登录才展示
			if (item.name === 'setting' && token?.isRegister) return; // 个人中心只有完善信息之后才有
			if (item.mobileOnly && mode !== Mode.aside) return; // 移动端时展示
			list.push({
				...item,
				path: getPathVal(item),
				label: item.label || item.name,
			});
		});
		return list;
	}, [token, mode]);

	const homeList = useMemo(() => {
		const list: MenuItem[] = [];
		HomeMenus.forEach((item) => {
			if (item.login === false && token) return; // 未登录才展示
			if (item.login && !token) return; // 登录才展示
			if (item.mobileOnly && mode !== Mode.aside) return; // 移动端时展示
			list.push({
				...item,
				path: getPathVal(item),
				label: item.label || item.name,
			});
		});
		return list;
	}, [token, mode]);

	const personalList = useMemo(() => {
		return PersonalMenus.map((item) => ({
			...item,
			path: getPathVal(item),
			label: item.label || item.name,
		}));
	}, []);

	/* 未登录 移动端展示下拉目录 */
	const notLoginMeunList = useMemo(() => {
		return MenuList.filter((item)=>item.isMobile)
	}, []);

	const visibleList = useMemo(() => {
		const copyMenuList = [...menuList];
		// 删除不需要的菜单
		const homeMenu = menuList.shift();
		const showMenuList = copyMenuList.splice(3, 2);
		return [homeMenu, ...showMenuList];
	}, [menuList]);

	const menuItems = useMemo(() => {
		const copyMenuList = [...menuList];
		// 删除不需要的菜单
		copyMenuList.splice(3, 2);
		// copyMenuList.splice(copyMenuList.length - 1, 1);
		return copyMenuList;
	}, [menuList]);

	const onToggleVisible = (visible: boolean, menuName?: string) => {
		switch (menuName) {
			case 'lang':
				setVisibleLang(visible);
				break;
			case 'avatar':
				setPersonalMenuVisible(visible);
				break;
			default:
				setVisible(visible);
		}

		if (visible && mode === Mode.aside) {
			document.body.classList.add(styles['ac-nav-menu-droped-body']);
		} else {
			document.body.classList.remove(styles['ac-nav-menu-droped-body']);
		}
	};

	const closeMenu = () => {
		destory && destory();
		destory = null;
		onToggleVisible(false);
	};

	const onSelectHandle = (menu: MenuItem) => {
		const { path, onClick, name, eventId } = menu;
		setMenu(menu);
		closeMenu();
		if (onClick) {
			if (name === 'logout') {
				onClick(t('profile'));
			} else {
				onClick();
			}
		}
		if (path) {
			// 跳转
			if (location.pathname === LOGIN && token) {
				history.replace(generatePath(path));
				return;
			}
			/* 跳转老师 添加埋点 */
			if(name ==='teachOnAirClass'){
				sendEvent({ eventId: eventId || '' });
			}
			history.push(generatePath(path));
		}
	};

	const onShowMenu = () => {
		onToggleVisible(true);
    console.log('token', token)
		destory = Popup.show({
			content: (
				<div className={`${styles.aside} ${styles.nav_menu_popup}`}>
					<div className={styles.aside_title}>
						<AcIcon icon="icon_close" onClick={closeMenu} />
					</div>
					<Menu
						menu={menu}
						transData={transData}
						list={ token && !token?.isRegister ? [...homeList, ...menuList.slice(0, menuList.length - 1), ...personalList] : notLoginMeunList }
						onSelect={onSelectHandle}
					/>
				</div>
			),
			mask: true,
			maskClosable: true,
			onDestroy: () => onToggleVisible(false),
		});
	};

	const renderHubspot = (code: string) => {
		// 手动控制hubspot在每次进入页面时都会重新加载渲染
		const oldScript = document.getElementById('hs-script-loader');
		if (oldScript) {
			oldScript.remove();
		}
		if(code == 'en') {
			return false
		}
		const script = document.createElement('script');
		script.type = 'text/javascript';
		script.id = 'hs-script-loader';
		script.src = '//js-eu1.hs-scripts.com/25650177.js';
		script.async = true;
		script.defer = true;
		document.getElementsByTagName('head')[0].appendChild(script);
	};

	// 切换多语言客服切换
	const service = (code: string) => {
		setTimeout(() => {
			const domList = Array.from(document.getElementsByClassName('intercom-lightweight-app'));
			domList.forEach(dom => {
				(dom as HTMLElement).style.display = code === 'en' ? 'block' : 'none';
				code === 'es' && renderHubspot(code)
			})
		})
	}

	useEffect(() => {
		renderHubspot(local)
		service(local)
		window.onload = () => {
			const timer = setInterval(() => {
				const domList = Array.from(document.getElementsByClassName('intercom-lightweight-app'));
				if(domList.length){
					service(local)
					clearInterval(timer)
				}
			}, 1000)
		}
	}, [local]);

	//语言切换
	const onSelectLang = (menu: MenuItem) => {
		i18n.setLocale(menu.code!);
		globalStore.setCountryCode(menu.code!);
		const path = pathname.split('/')[1];
		const ignorePath = [STEP_APPLY_TUTOR, TINT_APPLY_TUTOR, LOGIN];

   	/* 多语言情况下 路由名称不同的处理 */
		const aliasPath = [ONE_ON_ONE_ES, ONE_ON_ONE_EN, HAPPY_KIDS_ES, HAPPY_KIDS_EN]; // 顺序是es 占位奇数位 en占偶数位
		if (aliasPath.includes(pathname)) {
			const index = aliasPath.findIndex((v) => v.includes(pathname));
			const result = aliasPath[index].includes(menu.code || '');
			if (!result) {
			  const	newPath = index % 2 === 1 ? aliasPath[index - 1] : aliasPath[index + 1];
				history.replace(newPath + search)
			}
		} else
		if (!ignorePath.includes(pathname)) {
			history.replace(pathname.replace(`/${path}`, `/${menu.code}`) + search);
		}
		service(menu.code!)
	};

	// 进入页面设置菜单选中状态
	useEffect(() => {
		const currentMenu = menuList.find((item) => {
			if (item.path) {
				return generatePath(item.path) === pathname;
			}
			return false;
		});
		if (currentMenu) {
			setMenu(currentMenu);
		}
	}, [menuList, pathname]);

	const renderMore = () => {
		return (
			<div className={styles.more}>
				<span className={classnames(styles.title, visible ? styles.droped : '')}>{transData.more}</span>
				<span className={classnames(styles.icon, visible ? styles.droped : '')}>
					<AcIcon icon=" icon_dropdown" />
				</span>
			</div>
		);
	};

	const renderAvatarPopover = () => (
		<Popover
			content={
				<div className={`${styles.drop} ${styles.nav_menu_popup}`}>
					<Menu
						menu={menu}
						transData={transData}
						list={personalList}
						onSelect={(menu: MenuItem) => {
							onSelectHandle(menu);
						}}
					/>
				</div>
			}
			mouseLeaveDelay={30}
			contentStyle={{ marginLeft: user ? 50 : 31 }}
			onVisibleChange={(visible: boolean) => {
				onToggleVisible(visible, 'avatar');
				visible && sendEvent({ eventId: 'ACW_nav_menuhover' });
			}}
			clickAutoHidden
			// forceVisible //调试用
		>
			<div className={styles.user_info}>
				<div className={styles.avatar}>
					<img src={user?.avatar || AvatarImg} alt="avatar" />
				</div>
				<span className={classnames(styles.icon, personalMenuVisible ? styles.droped : '')}>
					<AcIcon icon=" icon_dropdown" />
				</span>
			</div>
		</Popover>
	);

	const renderMorePopover = () => (
		<Popover
			content={
				<div className={`${styles.drop} ${styles.nav_menu_popup}`}>
					<Menu
						menu={menu}
						transData={transData}
						list={token && !token?.isRegister ? [...homeList, ...menuList] : menuItems}
						onSelect={(menu: MenuItem) => {
							onSelectHandle(menu);
						}}
					/>
				</div>
			}
			mouseLeaveDelay={30}
			contentStyle={{ marginLeft: user ? 50 : 31 }}
			onVisibleChange={(visible: boolean) => {
				onToggleVisible(visible);
				visible && sendEvent({ eventId: 'ACW_nav_menuhover' });
			}}
			clickAutoHidden
			// forceVisible //调试用
		>
			<span className={styles.menu_bar}>{renderMore()}</span>
		</Popover>
	);

	/* 移动端 未登录展示 */
	// const renderMorePopoverMobile = () => (
	// 	<Popover
	// 		content={
	// 			<div className={`${styles.drop} ${styles.nav_menu_popup}`}>
	// 				<Menu
	// 					menu={menu}
	// 					transData={transData}
	// 					list={notLoginMeunList}
	// 					onSelect={(menu: MenuItem) => {
	// 						onSelectHandle(menu);
	// 					}}
	// 				/>
	// 			</div>
	// 		}
	// 		mouseLeaveDelay={30}
	// 		contentStyle={{ marginLeft: user ? 50 : 31 }}
	// 		onVisibleChange={(visible: boolean) => {
	// 			onToggleVisible(visible);
	// 			visible && sendEvent({ eventId: 'ACW_nav_menuhover' });
	// 		}}
	// 		clickAutoHidden
	// 		// forceVisible //调试用
	// 	>
	// 		<span className={styles.menu_bar}>{renderMore()}</span>
	// 	</Popover>
	// );

	const renderLoginBtn = () => (
		<div className={styles.nav_menu} onClick={() => onSelectHandle({ label: '', name: 'login', path: LOGIN })}>
			<span className={styles.login_btn}>{transData.login}</span>
		</div>
	);

	const renderLang = () => {
		return (
			<>
				<div className={styles.lang}>
					<span className={styles.icon}>
						<AcIcon icon=" icon_language" />
					</span>
					<span className={classnames(styles.title, isShowLang ? styles.droped : '')}>
						{LANG.find((item) => item.code == local.split('-')[0])?.name}
					</span>
				</div>
			</>
		);
	};

	const getPathname = () => {
		const countryPath = COUNTRY_CONFIG.map((item) => '/' + item.countryCode);
		if (pathname === EN_HOMEPAGE || pathname === CN_HOMEPAGE) {
			return false;
		}
		return !countryPath.find((item) => item === pathname);
	};

	const renderMenu = () => {
		const list = visibleList.map((item: MenuItem) => {
			// eslint-disable-next-line array-callback-return
			if (!item.path || item.externalLink) return; // 外链类型不展示到Menu
			return (
				<NavLink
					key={item.name}
					to={item.path as string}
					activeClassName={styles['nav_active']}
					onClick={() => {
						setMenu(item);
					}}>
					<span className={styles.nav_item}>{transData[item.label]}</span>
				</NavLink>
			);
		});
		return list;
	};

	return (
		<div className={styles.nav_menu}>
			{token && !token.isRegister && (
				<NavLink className={styles.invite_nav} to={generatePath(INVITE_FRIENDS)}>
					<img src={transData.header_line} alt="invite" />
				</NavLink>
			)}
			{mode === Mode.drop && (
				<>
					{(!token || token?.isRegister) && renderMenu()}
					{renderMorePopover()}
				</>
			)}
			{mode === Mode.aside && width <= 1192 && (
				<div className={styles.relative_wrap}>
					<CourseRemind />
					<span className={styles.menu_bar} onClick={onShowMenu}>
						{renderMore()}
					</span>
				</div>
			)}
			{mode === Mode.drop && (
				<div className={styles.relative_wrap}>
					<CourseRemind />
					{token ? renderAvatarPopover() : renderLoginBtn()}
				</div>
			)}
			{getPathname() && (
				<Popover
					content={
						<div className={classnames(styles.drop, styles.nav_menu_popup, styles.lang_popup)}>
							<Menu
								transData={transData}
								menu={LANG.find((item) => item.code == local.split('-')[0])}
								list={LANG}
								onSelect={onSelectLang}
							/>
						</div>
					}
					contentStyle={{ marginLeft: 27 }}
					mouseLeaveDelay={30}
					onVisibleChange={(visible: boolean) => {
						onToggleVisible(visible, 'lang');
					}}
					clickAutoHidden
					// forceVisible //调试用
				>
					<span className={styles.lang_bar}>{renderLang()}</span>
				</Popover>
			)}
		</div>
	);
}
