import React, { useEffect, useState } from 'react';
import cls from '@utils/css-select'
import styles from './styles.scss';
import { isMobile } from 'components/select/utils';

const cx = cls(styles)

type inputProps = {
	type?: 'text' | 'number' | 'password';
	size?: 'large' | 'medium';
	prefix?: React.ReactNode;
	suffix?: React.ReactNode;
	placeholder?: string;
	readonly?: boolean;
	autoFocus?: boolean;
	style?: React.CSSProperties;
	className?: string;
	error?: string;
	value?: string | number;
	onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
	onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
	pwdChange?: (oldVal: string, newVal: string) => void;
}

function changeNumberType (type: string): string {
	// fix: 想要给使用者如同原生input的感觉，但是实际操作的时候，不会将 number 赋值给 input 的 type
	return type === 'number' ? 'text' : type
}


function AcIpt (props: inputProps) {
	const {
		type = 'text',
		size = 'large',
		placeholder,
		readonly = false,
		autoFocus,
		value = '',
		prefix,
		suffix,
		error,
		style,
		className
	} = props
	const [placeholderShow, setPlaceHolderShow] = useState(true)
	const [inputType, setInputType] = useState(changeNumberType(type))
	// const [capsLock, setCapsLock] = useState(false) // 大写显示废弃
	const [iptVal, setIptVal] = useState(value)
	const suffixVision = function () {
		// return suffix || (type === 'password' && !suffix) || (type === 'text' && capsLock)
		return suffix || (type === 'password' && !suffix) || type === 'text'
	}
	// const capsLockSwitch = function (inputValue: string) {
	// 	// 正则判断大写
	// 	if (type === 'password') {
	// 		const isCaps = /[A-Z]{1,}/.test(inputValue)
	// 		if (isCaps !== capsLock) setCapsLock(isCaps)
	// 	}
	// }

	const inputChangeHandler = function (e: React.ChangeEvent<HTMLInputElement>) {
		let value = e.target.value
		if (props.type === 'number' && !/^\d+$/.test(value)) {
			value = value.replace(/[^0-9]+/g, '')
			e.target.value = value;
		}
		setIptVal(value)
		setPlaceHolderShow(!(value && placeholder))
		// capsLockSwitch(value)

		props.onChange && props.onChange(e)
	}

	const inputBlurHandler = function (e: React.FocusEvent<HTMLInputElement>) {
		props.onBlur && props.onBlur(e)
	}

	const pwdSwitchHandler = function () {
		setInputType(inputType === 'password' ? 'text' : 'password')
	}

	useEffect(() => {
		setIptVal(value)
		setPlaceHolderShow(!(value && placeholder))
	}, [value])

	useEffect(() => {
		setInputType(changeNumberType(type))
	}, [type])

	return (
		<div className={cx('ac-input',
			{
				[size]: size,
				m: isMobile(),
				error,
				'read-only': readonly,
				'ac-input_prefix': prefix,
				'ac-input_suffix' : suffixVision()
			},
			className)} style={style}>
			<div className={cx('ac-input_wrap')}>
				{ prefix ? <div className={cx('prefix')}>{prefix}</div> : '' }
				<div className={cx('content-input')}>
					<input
						type={inputType}
						value={iptVal}
						readOnly={readonly}
						onChange={inputChangeHandler}
						onBlur={inputBlurHandler}
						autoFocus={autoFocus}
						autoComplete={inputType === 'password' ? 'new-password': 'off'}
						aria-autocomplete='none'
					/>
					{ placeholderShow ? <span className={cx('placeholder')}>{placeholder}</span> : '' }
				</div>
				{ suffixVision() ? <div className={cx('suffix')}>
					{/*{ type === 'password' && capsLock ?*/}
					{/*	<i className={cx('input-icon', 'iconfont', 'icon_capslock')} /> : ''*/}
					{/*}*/}
					{ suffix ? <>{ suffix }</> : ''  }
					{ type === 'password' && !suffix ?
						<i
							className={cx('input-icon', 'pwd-icon', 'iconfont', inputType === 'password' ? 'icon_eyeclosed' : 'icon_eyeopened')}
							onClick={pwdSwitchHandler} /> : ''
					}
				</div> : '' }
			</div>
			{ error ? <span className={cx('err-msg')}>{error}</span> : '' }
		</div>
	)
}
export default AcIpt
