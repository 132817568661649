import React, { useEffect, useState } from 'react';
import Select, { SelectProps, Option, Arrow } from 'ac-components/select';
import styles from './styles.scss';
import { getLocationIp } from '@services/appointment';
import { AREA_CODE_MAP } from 'constants/country';
import { useTranslation } from 'hooks/use-translation';

const noop = () => { }

// 引入国旗图标svg文件 iconfont
(() => {
	const script = document.createElement("script");
	script.setAttribute("type", "text/javascript");
	script.setAttribute("src", "https://web-data.zmlearn.com/config/tbZXSk1oqspPFjJnW39JN/font_3023264_rrgeo8osns.js");
	document.getElementsByTagName('head')[0].appendChild(script);
})()

export type Area = {
	id: string;
	name: string;
	code: string;
	icon: string;
}

const areaList = Object.values(AREA_CODE_MAP);

export default function AreaSelect(props: SelectProps) {
	const { selected, onChange = noop, children } = props
	const [hold, setHold] = useState<Area>();
	const { transData } = useTranslation('freeForm')

	useEffect(() => {

		getLocationIp()
			.then((res) => {
				const hold = AREA_CODE_MAP[res.countryId]
				setHold(hold);
				onChange(hold);
			})
			.catch(() => {
				const hold = AREA_CODE_MAP.US
				setHold(hold);
				onChange(hold)
			});
	}, [])

	useEffect(() => {
		if (selected) {
			setHold(selected as Area);
			return
		}
	}, [selected])

	const defaultRenderItem = ({ option, selected, onClick }: { option: Option; selected?: boolean; onClick(): void }) => {
		return (
			<span className={`${styles.area_item} ${selected ? styles.selected : ''}`} onClick={onClick}>
				<span className={styles.icon}>
					<svg className="nationalflag" aria-hidden="true">
            {(option.id !== 'TW' ? <use xlinkHref={`#flag-${(option.id + '').toUpperCase()}`}></use> : '' )}
					</svg>
				</span>
				<span className={styles.name}>{option.name}</span>
				<span className={styles.code}>+{option.code}</span>
			</span>
		)
	}
	const defaultChildren = ({ visible, value }: { visible: boolean; value?: any }) => {
		return (
			<div className={styles.area_select}>
				<span className={styles.icon}>
					{value && (
						<svg className="nationalflag" aria-hidden="true">
              {(value.id !== 'TW' ? <use xlinkHref={`#flag-${(value.id + '').toUpperCase()}`}></use> : '' )}
						</svg>
					)}
				</span>
				<span className={styles.code}>{value ? '+' + value.code : ''}</span>
				<Arrow drop={visible} />
			</div>
		)
	}
	return (
		<Select
			list={areaList}
			allowSearch
			filters={['name', 'code']}
			searchPlaceholder={transData.search}
			menuTitle={transData.searchText}
			renderItem={defaultRenderItem}
			{...props}
			selected={hold}
		>
			{children || defaultChildren}
		</Select>
	)
}
