import { sendEvent } from '@utils/core/track';
import PageLoader from 'components/page-loader';
import { ComponentType } from 'react';

type ComponentState = {
    component: Promise<{ default: ComponentType<any> }>;
    eventId?: string | ((props: any) => void);
}



const PageLoad = (component: ComponentState['component'], event: ComponentState['eventId'] = '') => PageLoader({
    loader: () => component,
    onEnter: (props) => {
        if (typeof event === 'string') {
            sendEvent({
                eventId: event,
            });
        } else if (typeof event === 'function') {
            event(props);
        }
    },
})

export default PageLoad