import { fetchApplyTutor, getLocationIp, getSubjectDictInfo, getEspanaDictInfo } from '@services/appointment';
import { history } from '@store/history';
import { getChannelInfo } from '@utils/channel-util';
import { sendEvent } from '@utils/core/track';
import generatePath from '@utils/generatePath';
import Button from 'ac-components/ac-btn';
import Input from 'ac-components/ac-ipt';
import Modal from 'ac-components/modal';
import classnames from 'classnames';
import { toast } from 'components/dialogs';
import Select, { Option } from 'components/select';
import { COUNTRY_CODE, COUNTRY_TEXT } from 'constants/country';
import { AGREEMENT_POLICY, AGREEMENT_USER, APPLY_SUCCESS } from 'constants/url';
// import AreaSelect, { Area } from 'containers/ac-containers/area-select-reg';
import AreaSelect, { Area } from 'containers/ac-containers/area-select';
import useResizeObserver from 'hooks/use-resize-observer';
import { useTranslation } from 'hooks/use-translation';
import { useModalContext } from 'hooks/useModalContext';
import React, { ChangeEvent, FC, memo, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { ConnectState } from 'states/connect';
import styles from './styles.scss';
import { existByMobile, appointmentSource } from '@services/test-form';
import { closeEvent, getValByKey } from '@utils/testForm';
import classNames from 'classnames';

type Props = {
	isShow?: boolean;
	onClose?: () => void;
	title?: string;
};

/**
 * 接口请求传输
 *
 * @interface IFormDataParams
 */
interface IFormDataParams {
	subjectCode: string;
	gradeCode: string;
	areaCode: string | number;
	areaText: string;
	firstName: string;
	lastName: string;
	phone: string;
	email: string;
	expectedCommLanguage?: string;
}

type GradeItem = {
	gradeName: string;
	gradeCode: string;
	subjectList: SubjectItem[];
};

type SubjectItem = {
	subjectCode: string;
	subjectName: string;
};

type AreaCodeItem = {
	code: string;
	text: string;
};

/**
 * 接口请求传输
 *
 * @interface IFormDataParams
 */
interface IFormDataParams {
	subjectCode: string;
	gradeCode: string;
	areaCode: string | number;
	areaText: string;
	firstName: string;
	lastName: string;
	phone: string;
	email: string;
}
const TraceMap: {
	[propName: string]: { eventId: string };
} = {
	areaCode: { eventId: 'en_offWebsite0811_AreaCodeSelect' },
	gradeCode: { eventId: 'en_offWebsite0811_Grade' },
	subjectCode: { eventId: 'en_offWebsite0811_SubjectSelect' },
};
/**
 * 获取国家区号
 * @returns
 */
function handleAreaCode() {
	return Object.keys(COUNTRY_TEXT).reduce((pre: Record<string, any>, next) => {
		pre[next] = {
			text: COUNTRY_TEXT[next],
			code: Object.keys(COUNTRY_CODE).find((key) => COUNTRY_CODE[key].find((code) => code === next)) || '',
		};
		return pre;
	}, {});
}

const areacCodeMap: Record<string, AreaCodeItem> = handleAreaCode();

/**
 * 谷歌事件埋点
 * @param url
 * @returns
 */
function gtag_report_conversion(url?: any) {
	const callback = function () {
		if (typeof url != 'undefined') {
			window.location = url;
		}
	};
	window.gtag('event', 'conversion', {
		send_to: 'AW-10900060609/VC44CLv0grwDEMHzxs0o',
		event_callback: callback,
	});
	return false;
}

const serviceLanaguageList = [{lable:'Chinese',value:'zh'},{lable:'English',value:'en'}];

const FreeForm: FC<Props> = (props) => {
	const { width } = useResizeObserver();
	const { isShow, setTrigger, header, setHeader, className, setClassName, enTestFormModalData } = useModalContext();
	const [offsetWidth, setWidth] = useState<string | number>(0);
	const { local, countryCode } = useSelector((state: ConnectState) => state.app);
	const [area, setArea] = useState<Area>();
	const [areaList, setAreaList] = useState<AreaCodeItem[]>([]);
	const [areaTip, setAreaTip] = useState<string>('');
	const [alertTip, setAlertTip] = useState<string>('');
	const [phone, setPhone] = useState<string>('');
	const [phoneTip, setPhoneTip] = useState<string>('');
	const [gradeList, setGradeList] = useState<GradeItem[]>([]);
	const [subjectList, setSubjectList] = useState<SubjectItem[]>([]);
	const { channel_code: code, channel_keyword: key } = getChannelInfo(countryCode);
	const checkRef = useRef<HTMLInputElement>(null);
	const checkRef1 = useRef<HTMLInputElement>(null);
	const modalRef = useRef<HTMLElement>(null);
	const { t, transData } = useTranslation('freeForm');
	// 切换语言 英国官网
	const CID = 11;
	const emailRules = /^\w+([.-]\w+)*@\w+([.-]\w+)*\.\w+$/;
	const initFormParams: IFormDataParams = {
		areaCode: '',
		areaText: '',
		firstName: '',
		lastName: '',
		phone: '',
		email: '',
		gradeCode: '',
		subjectCode: '',
		expectedCommLanguage:'',
	};
	const [formParams, setFormParams] = useState(initFormParams);
	const [ip, setIp] = useState('');
	const cardRef = useRef<HTMLDivElement | null>(null);

	function handleInputChange(key: string, val: string) {
		// 年级科目级联
		if (key === 'gradeCode') {
			const gradeObject = gradeList.find((v) => v.gradeCode === val) || { subjectList: [] };
			setSubjectList(gradeObject?.subjectList);
			setFormParams({
				...formParams,
				subjectCode: '',
				[key]: val,
			});
			return;
		}
		setFormParams({
			...formParams,
			[key]: val,
		});
	}
	/**
	 * 预约注册
	 */
	async function handleSubmit() {
		sendEvent({
			eventId: 'en_offWebsite0811_ClickSubmit',
			eventParam: {
				result: '',
			},
		});
		// 表单校验
		if (!formParams.firstName || !formParams.lastName) {
			toast({
				message: transData.error1,
			});
			return;
		}
		// policy
		if (!checkRef.current?.checked) {
			toast({
				message: transData.error2,
			});
			return;
		}
		if (!formParams.phone) {
			toast({
				message: transData.error3,
			});
			return;
		}
		if (formParams.email !== '' && !emailRules.test(formParams.email as string)) {
			toast({
				message: transData.error4,
			});
			return;
		}
		if (!formParams.gradeCode || !formParams.subjectCode) {
			toast({
				message: transData.error5,
			});
			return;
		}
		if (!checkRef1.current?.checked) {
			toast({
				message: transData.disagreePrompt,
			});
			return;
		}

		/* 新增英语测试页 */
		if (local === 'en') {
			// 手机号是否已经注册过
			const res = await existByMobile({ areaCode: formParams.areaCode, mobile: formParams.phone });
			await appointmentSource({
				eventType: res.data ? 2 : 3,
				ip,
				sourceKey: enTestFormModalData.sourceKey,
				mobile: formParams.phone,
				areaCode: formParams.areaCode,
				landingPageId: getValByKey('landingPageId'),
				duration: getValByKey('duration'),
			});
		}
		/* 区分华侨 */
		let chineseCode = null;
		let chineseKey = null;
		let chineseBrowserLanguage = null;
		if(['OVERSEA_CHINESE_LOGIC', 'MATH_LOGIC'].includes(formParams.subjectCode) || formParams.expectedCommLanguage === 'zh' || navigator.language.includes('zh')){
			chineseCode = 29652
			chineseKey ='487aa8ece834e63f'
			chineseBrowserLanguage = navigator.language;
		}
		fetchApplyTutor(
			CID,
			{
				name: formParams.firstName + ' ' + formParams.lastName,
				mobile: formParams.phone,
				email: formParams.email,
				subjectCode: formParams.subjectCode,
				gradeCode: formParams.gradeCode,
				areaCode: formParams.areaCode,
				code: chineseCode || code,
				key: chineseKey || key,
				expectedCommLanguage:formParams.expectedCommLanguage,
				browserLanguage:chineseBrowserLanguage
			},
			undefined
		)
			.then((res) => {
				sendEvent({
					eventId: 'en_offWebsite0811_SubmitResult',
					eventParam: {
						result: res.message,
					},
				});
				gtag_report_conversion();
				window.gtag('event', 'conversion', { send_to: 'AW-328519748/ZImKCMzq5t4CEMSg05wB' });
				window.gtag('event', 'conversion', { send_to: 'AW-346007589/erxeCPOq7d4CEKXQ_qQB' });
				window.gtag('event', 'conversion', { send_to: 'AW-328316016/rUbiCJHr0t4CEPDoxpwB' });
				window.gtag('event', 'conversion', { send_to: 'AW-327291236/tAqYCJqFqd8CEOSiiJwB' });
				window.gtag('event', 'conversion', { send_to: 'AW-327291257/WXLrCPG7qd8CEPmiiJwB' });
				window.gtag('event', 'conversion', { send_to: 'AW-327802922/W5LcCOvD1t8CEKrAp5wB' });
				window.gtag('event', 'conversion', { send_to: 'AW-327843671/x22XCNyp-N4CENf-qZwB' });
				window.gtag('event', 'conversion', { send_to: 'AW-396168788/3rW2CImf_OICENSc9LwB' });
				window.fbq('track', 'Lead');

				setTrigger(!isShow);

				history.push({
					pathname: generatePath(APPLY_SUCCESS),
					search: `?channel_code=${code}&channel_keyword=${key}`,
					state: { nation: local },
				});
			})
			.catch((err) => {
				sendEvent({
					eventId: 'en_offWebsite0811_SubmitResult',
					eventParam: {
						result: err.message,
					},
				});
				toast({
					message: err.message || transData.error6,
				});
			});
	}

	/**
	 *埋点处理
	 * @param null e
	 */
	function handleTrack(e: any) {
		const { evid = '', key = '' } = e.currentTarget.dataset;
		if (!key) {
			sendEvent({
				eventId: evid,
			});
			return;
		}
		sendEvent({
			eventId: evid,
			eventParam: {
				[key.toLocaleLowerCase()]: e.currentTarget.value,
			},
		});
	}

	function handleInputTrack(evid: string, key: string, value: string) {
		if (!key) {
			sendEvent({
				eventId: evid,
			});
			return;
		}
		sendEvent({
			eventId: evid,
			eventParam: {
				[key.toLocaleLowerCase()]: value,
			},
		});
	}

	/**
	 * 区域选择 年级 科目
	 * @param e
	 */
	function onSelectChange(option: { code: string; text: string }, key: string) {
		sendEvent({
			eventId: TraceMap[key]?.eventId,
			eventParam: {
				[key.toLocaleLowerCase()]: option.code,
			},
		});
		const val = option.code;
		if (key === 'gradeCode') {
			const gradeObject = gradeList.find((v) => v.gradeCode === val) || { subjectList: [] };
			setSubjectList(gradeObject?.subjectList);
			setFormParams({
				...formParams,
				subjectCode: '',
				gradeCode: val as string,
			});
			return;
		}
		const newFormParams = {
			...formParams,
			[key]: val,
		};
		if (key === 'areaCode') {
			newFormParams.areaText = option.text as string;
		}
		console.log(newFormParams)
		setFormParams(newFormParams);
	}
	// 选择国家地区
	const onAreaChange = (item: Area) => {
		setArea(item);
		setFormParams({
			...formParams,
			areaCode: item.code,
		});
		setAreaTip('');
	};

	// 输入号码
	const onPhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		handleInputChange('phone', e.target.value);
		setPhoneTip('');
		setAlertTip('');
	};

	useEffect(() => {
		// 获取本地IP
		if (isShow) {
			getLocationIp()
				.then((res) => {
					setFormParams({
						...formParams,
						areaCode: areacCodeMap[res.countryId || 'GB'].code,
						areaText: areacCodeMap[res.countryId || 'GB'].text,
					});
					setAreaList(Object.values(areacCodeMap));
					setIp(res.ip);
				})
				.catch(() => {
					setFormParams({
						...formParams,
						areaCode: areacCodeMap['GB'].code,
						areaText: areacCodeMap['GB'].text,
					});
					setAreaList(Object.values(areacCodeMap));
				});
		}
	}, [isShow]);

	useEffect(() => {
		if (local === 'es') {
			getEspanaDictInfo()
				.then((res) => {
					setGradeList(res);
					setSubjectList(res[0]?.subjectList);
				})
				.catch((err) => {
					toast({
						message: err.message || transData.error6,
					});
				});
			return;
		}
		if (window.location.href.includes('/s/6DzjAm')) return
		// 获取年级科目
		getSubjectDictInfo(CID)
			.then((res) => {
				setGradeList(res.dictInfo);
				setSubjectList(res.dictInfo[0]?.subjectList);
			})
			.catch((err) => {
				toast({
					message: err.message || transData.error6,
				});
			});
	}, [local]);

	useEffect(() => {
		if (cardRef.current) {
			setWidth(cardRef.current.offsetWidth);
		}
	}, [cardRef.current]);

	useEffect(() => {
		//展示上报
		if (isShow) {
			sendEvent({
				eventId: 'ACW_appointmentshow',
			});
		} else {
			setClassName('');
		}
	}, [isShow]);

	useEffect(() => {
		//展示上报
		if (isShow) {
			setFormParams({ ...initFormParams, subjectCode: enTestFormModalData.subjectCode, gradeCode: enTestFormModalData.gradeCode });
		}
	}, [isShow, enTestFormModalData]);

	return (
		<Modal
			show={props.isShow !== undefined ? props.isShow : isShow}
			onClose={() => {
				closeEvent(enTestFormModalData.sourceKey);
				setFormParams(initFormParams);
				setTrigger(!isShow);
				props.onClose && props.onClose();
				sendEvent({
					eventId: 'ACW_appointmentclose',
				});
			}}
			//添加ac-modal-free-form是为了做样式隔离,组件没有prefixCls
			className={classnames(styles['ac-modal'], className, 'ac-modal-free-form')}>
			<div className={styles['ac-modal-wrap']}>
				<div className={styles['ac-modal-head']}>
					{header ||
						(width < 768 ? (
							<img className={styles.img} src={transData.phoneImg} alt="" loading="lazy" />
						) : (
							<img className={styles.img} src={transData.pcImg} alt="" loading="lazy" />
						))}
				</div>
				{header && width >= 768 && <div className={styles['ac-modal-title']}>{props.title || transData.title}</div>}
				<div className={classnames(styles['ac-modal-form'], 'ac-modal-form')}>
				<div className={styles.item_box}>
					<div className={styles['form-item']}>
						<label htmlFor="firstName">{transData.firstName}</label>
						<Input
							className={styles['input']}
							placeholder={transData.yFirstName}
							// placeholder={<span>First Name <strong>*</strong></span>}
							value={formParams.firstName}
							size="large"
							onChange={(e: ChangeEvent<HTMLInputElement>) => handleInputChange('firstName', e.target.value)}
							onBlur={() => handleInputTrack('en_offWebsite0811_NameInput', 'firstName', formParams.firstName)}
						/>
					</div>
					{/* 姓 */}
					<div className={styles['form-item']}>
						<label htmlFor="lastName">{transData.lastName}</label>
						<Input
							className={styles['input']}
							placeholder={transData.yLastName}
							// placeholder={<span>Last Name <strong>*</strong></span>}
							value={formParams.lastName}
							size="large"
							onChange={(e: ChangeEvent<HTMLInputElement>) => handleInputChange('lastName', e.target.value)}
							onBlur={() => handleInputTrack('en_offWebsite0811_LastNameInput', 'lastName', formParams.lastName)}
						/>
					</div>
					</div>
					<div className={styles.item_box}>

					{/* 年级 */}
					<div className={styles['form-item']}>
						<label htmlFor="gradeCode">{transData.grade}</label>
						<div className={styles.releative}>
							<Select
								className={styles['grade-select']}
								placeholder={transData.yGrade}
								// placeholder={<span>Grade <strong>*</strong></span>}
								searchPlaceholder={transData.search}
								selected={{ value: formParams.gradeCode, label: '' }}
								onChange={(option) => onSelectChange({ code: option.value, text: option.label }, 'gradeCode')}
								allowSearch={false}
								size="large"
								zIndex={1000}
								showArrow={true}>
								{gradeList.map((v) => {
									return (
										<Option value={v.gradeCode} key={v.gradeCode} option={{ ...v, label: v.gradeName, value: v.gradeCode }}>
											{v.gradeName}
										</Option>
									);
								})}
							</Select>
						</div>
					</div>
					{/* 项目 */}
					<div className={styles['form-item']}>
						<label htmlFor="subjectCode">{transData.subject}</label>
						<div className={styles.releative}>
							<Select
								className={styles['subject-select']}
								placeholder={transData.ySubject}
								selected={{ value: formParams.subjectCode, label: '' }}
								onChange={(option: { value: any; label: any }) => onSelectChange({ code: option.value, text: option.label }, 'subjectCode')}
								allowSearch={false}
								size="large"
								zIndex={1000}
								showArrow={true}>
								{subjectList.map((v) => {
									return (
										<Option value={v.subjectCode} key={v.subjectCode} option={{ ...v, label: v.subjectName, value: v.subjectCode }}>
											{v.subjectName}
										</Option>
									);
								})}
							</Select>
						</div>
					</div>

					</div>

					<div className={styles.item_box}>
					{/* 电话 */}
					<div className={styles['form-item']} ref={cardRef}>
						<label htmlFor="phone">{t('login.phone')}</label>
						{(areaTip || phoneTip) && <div className={styles.verify_tip}>{areaTip || phoneTip}</div>}
						<Input
							className={styles.phoneNumber}
							placeholder={t('login.phone')}
							onChange={onPhoneChange}
							type="number"
							value={phone}
							prefix={<AreaSelect selected={area} onChange={onAreaChange} width={offsetWidth} />}
							onBlur={() => handleInputTrack('ACW_pointment_phonenum', 'phone', phone)}
						/>
					</div>
					{/* 邮箱 */}
					<div className={styles['form-item']}>
						<label htmlFor="email">{t('profile.email')}</label>
						<Input
							className={classnames(styles['input'], styles['email'])}
							placeholder={t('profile.email')}
							value={formParams.email}
							size="large"
							onChange={(e: ChangeEvent<HTMLInputElement>) => handleInputChange('email', e.target.value)}
							onBlur={() => handleInputTrack('en_offWebsite0811_EmailInput', 'email', formParams.email)}
						/>
					</div>
					</div>
					{/* 服务语言 且在英语环境中展示 */}
					{
						local === 'en' && <div className={styles['form-item']}>
						<label htmlFor="expectedCommLanguage">{transData.serviceLanaguage}</label>
						<div className={classnames(styles.releative,'lanaguage')}>
							<Select
								className={styles['grade-select']}
								placeholder={transData.serviceLanaguageP}
								// placeholder={<span>Grade <strong>*</strong></span>}
								searchPlaceholder={transData.serviceLanaguageP}
								selected={{ value: formParams.expectedCommLanguage, label: '' }}
								onChange={(option) => onSelectChange({ code: option.value, text: option.label }, 'expectedCommLanguage')}
								allowSearch={false}
								size="large"
								zIndex={1000}
								showArrow={true}>
								{serviceLanaguageList.map((v) => {
									return (
										<Option value={v.value} key={v.value} option={{ ...v, label: v.lable, value: v.value }}>
											{v.lable}
										</Option>
									);
								})}
							</Select>
						</div>
					</div>
					}

				</div>
				<div className={styles['ac-modal-footer']}>
					<Button className={styles['btn']} onClick={handleSubmit}>
						{transData.requestNow}
					</Button>

					<div className={styles.agreement_box}>
					{/* 用户协议和隐私政策选项 */}
					<div className={classnames(styles['agreement-box'], styles['agreement-email'])}>
						<input
							className={classnames(styles['check-box'], styles['check-box1'])}
							ref={checkRef1}
							type="checkbox"
							value="#f0544d"
							id="check-box1"
						/>
						<label htmlFor="check-box1"></label>
						<p>{transData.agreeToReceive}</p>
					</div>

					<div className={classnames(styles['agreement-box'], styles['agreement-content'])}>
						<input
							className={styles['check-box']}
							type="checkbox"
							ref={checkRef}
							data-evid="en_offWebsite0811_Agreement"
							onFocus={handleTrack}
							value="#f0544d"
							id="check-box"
							onClickCapture={handleTrack}
						/>
						<label htmlFor="check-box"></label>

						<p>
							{transData.consentTo}
							<span>
								<a href={AGREEMENT_USER} target="_blank">
									{t('login.descText1')}
								</a>
							</span>
							{t('login.descText2')}
							<span>
								<a href={AGREEMENT_POLICY} target="_blank">
									{t('login.descText3')}
								</a>
							</span>
						</p>
					</div>
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default memo(FreeForm);
