export default {
	'en': () => import(/* webpackChunkName: "locale-es" */ './en'),
	'es': () => import(/* webpackChunkName: "locale-es" */ './es'),
	'tr': () => import(/* webpackChunkName: "locale-tr" */ './tr'),
	'en-US': () => import(/* webpackChunkName: "locale-en-US" */ './en-US'),
	'en-CA': () => import(/* webpackChunkName: "locale-en-CA" */ './en-CA'),
	'en-UK': () => import(/* webpackChunkName: "locale-en-UK" */ './en-UK'),
	'en-AU': () => import(/* webpackChunkName: "locale-en-AU" */ './en-AU'),
	'en-GB': () => import(/* webpackChunkName: "locale-en-GB" */ './en-GB'),
	'en-IE': () => import(/* webpackChunkName: "locale-en-IE" */ './en-IE'),
	'en-NZ': () => import(/* webpackChunkName: "locale-en-NZ" */ './en-NZ'),
};
