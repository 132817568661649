import React from 'react';
import { Popup } from '../popup';

const prefix = 'zmsp-toast';

type ToastProps = {
	message: string | string[] | React.ReactNode; // 消息文本，可以传入字符串，一组字符串（多行）
	wait?: number; // 持续显示时间，毫秒，默认 3000
	audio?: string; // 显示消息同时播放的音频地址
	mask?: boolean; // 是否展示蒙层，默认 false
};

/**
 * 通用 toast 组件
 * 单行 message 不换行，想换行或设置多行 message 请传入数组
 */
export function toast({ message, wait = 2500, audio, mask = false }: ToastProps) {
	const destroy = Popup.show({
		mask,
		audio,
		className: prefix,
		content: () => {
			if (typeof message === 'string') {
				return <p>{message}</p>;
			}

			if (Array.isArray(message)) {
				return (message as string[]).map((txt, i) => <p key={i}>{txt}</p>);
			}

			return message || null;
		},
	});

	const timer = setTimeout(destroy, wait);
	return () => {
		clearTimeout(timer);
		destroy();
	};
}
