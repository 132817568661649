export type DateInfo = {
	year: number;
	month: number;
	date: number;
};

export type DateLike = number | string | Date | DateInfo;

/**
 * 计时器
 * @param {Funciton} fn 执行函数
 * @param {Number} delay  延迟时间ms
 */
export const animationTimer = (fn: Function, delay: number) => {
	// eslint-disable-next-line @typescript-eslint/no-use-before-define
	let timer = requestAnimationFrame(fnLoop);
	let sTime: number;
	async function fnLoop(timeStamp: number) {
		let ret = true; // 是否继续
		if (!sTime || timeStamp - sTime > delay) {
			sTime = timeStamp;
			ret = await fn();
		}
		// 取消之前的timer对象 避免内存泄露
		cancelAnimationFrame(timer);
		if (ret) {
			timer = requestAnimationFrame(fnLoop);
		}
	}
	return () => timer;
};

/**
 *时间格式化
 * @export
 * @param {*} datetime
 * @param {*} format
 * @returns
 */
export function formatDate(datetime: Date | string | number, format: string) {
	if (!format) format = 'YYYY-MM-DD HH:mm';
	if (typeof datetime == 'string') {
		datetime = datetime.replace(/\-/g, '/');
		datetime = new Date(datetime);
	} else if (typeof datetime == 'number') {
		datetime = new Date(datetime);
	} else if (!(datetime instanceof Date)) {
		datetime = new Date();
	}

	const dateobj = datetime as Date;
	const week = ['日', '一', '二', '三', '四', '五', '六'];
	return format.replace(/YYYY|YY|MM|DD|HH|hh|mm|SS|ss|week/g, function (key: string): any {
		switch (key) {
			case 'YYYY':
				return dateobj.getFullYear();
			case 'YY':
				return (dateobj.getFullYear() + '').slice(2);
			case 'MM':
				return dateobj.getMonth() + 1 < 10 ? '0' + (dateobj.getMonth() + 1) : dateobj.getMonth() + 1;
			case 'DD':
				return dateobj.getDate() < 10 ? '0' + dateobj.getDate() : dateobj.getDate();
			case 'HH':
			case 'hh':
				return dateobj.getHours() < 10 ? '0' + dateobj.getHours() : dateobj.getHours();
			case 'mm':
				return dateobj.getMinutes() < 10 ? '0' + dateobj.getMinutes() : dateobj.getMinutes();
			case 'SS':
			case 'ss':
				return dateobj.getSeconds() < 10 ? '0' + dateobj.getSeconds() : dateobj.getSeconds();
			case 'week':
				return week[dateobj.getDay()];
			default:
				return '';
		}
	});
}

/**
 * 获取明天 date 对象
 * @param {date} todayDate 今天的日期对象
 */
export function getTomorrow(todayDate: Date) {
	const today = todayDate || new Date();
	const tomorrow = new Date(today);
	tomorrow.setDate(tomorrow.getDate() + 1);
	return tomorrow;
}

function leftPad(i: number) {
	if (i >= 10) {
		return i.toString();
	}
	return `0${i === 0 ? 0 : i}`;
}

/**
 * 毫秒数转时分秒对象
 */
export function countTime(remainingTime: number) {
	if (remainingTime < 0) {
		remainingTime = -remainingTime;
	}
	const time = remainingTime / 1000;
	const days = Math.floor(time / (24 * 3600));
	const hours = Math.floor((time - 24 * 3600 * days) / 3600);
	const minutes = Math.floor((time - 24 * 3600 * days - hours * 3600) / 60);
	const seconds = Math.floor(time - 24 * 3600 * days - hours * 3600 - minutes * 60);
	return {
		hours: leftPad(hours),
		minutes: leftPad(minutes),
		seconds: leftPad(seconds),
	};
}

/**
 * 将值转为 Date 对象，无法转为 Date 对象的一律返回 null
 * @param {number|string|Date} x
 */
export function toDate(x: DateLike): Date | null {
	if (!x) {
		return null;
	}

	if (typeof x === 'string' || typeof x === 'number') {
		const date = new Date(x);
		return date.toString() === 'Invalid Date' ? null : date;
	}

	if (x && (x as DateInfo).year > 0 && (x as DateInfo).month > 0) {
		x = x as DateInfo;
		const month = (x.month + '').padStart(2, '0');
		const day = ((x.date || 1) + '').padStart(2, '0');
		const date = new Date(`${x.year}-${month}-${day}`);
		return date.toString() === 'Invalid Date' ? null : date;
	}

	x = x as Date;
	return x && x.getFullYear ? x : null;
}

/** 是否是 dateInfo 对象 */
export function isDateInfo(x: any) {
	if (x && x.year !== undefined && x.month !== undefined && x.date !== undefined) {
		return true;
	}
	return false;
}

/** 获取 date 的年月日信息 */
export function getDateInfo(dateLike: DateLike): DateInfo | null {
	if (isDateInfo(dateLike)) {
		return dateLike as DateInfo;
	}

	const date = toDate(dateLike);
	return (
		date && {
			year: date.getFullYear(),
			month: date.getMonth() + 1,
			date: date.getDate(),
		}
	);
}

/** 获取周历上本周周一（start）和周日 (end) */
export function getCalendarWeekRange(time: DateLike) {
	const target = toDate(time) as Date;
	const year = target.getFullYear();
	const month = target.getMonth() + 1;
	const date = target.getDate();
	const _week = target.getDay();
	const week = _week === 0 ? 7 : _week;
	const weekStart = new Date(year, month - 1, date - (week - 1)); // 本周一
	const weekEnd = new Date(year, month - 1, date + (7 - week)); // 本周日
	return {
		startTime: weekStart,
		endTime: weekEnd,
	};
}

/** 换算到给定时间当天的 00:00 */
export function toOnedayStart(time: DateLike) {
	const target = getDateInfo(time);
	if (!target) {
		return null;
	}
	const month = (target.month + '').padStart(2, '0');
	const day = ((target.date || 1) + '').padStart(2, '0');
	return new Date(`${target.year}-${month}-${day}`);
}

/** 换算到给定日期所在周的周一 00:00:00 */
export function toWeekStart(time: DateLike) {
	const target = toDate(time);
	if (!target) {
		return null;
	}

	const weekRange = getCalendarWeekRange(time);
	return weekRange ? weekRange.startTime : null;
}

/** 获取月历上的第一天和最后一天，可能返回上个月最后一个周日或下个月第一个周六 */
export function getCalendarMonthRange(time: string | number) {
	const target = new Date(time);
	const year = target.getFullYear();
	const month = target.getMonth() + 1;
	const monthStart = new Date(year, month - 1, 1); // 本月 1 号
	const monthStartDay = monthStart.getDay(); // 1 号是周几
	const startTimeDate = new Date(year, month - 1, 1 - monthStartDay); // 上个月最后一个周日
	const monthEnd = new Date(year, month, 0); // 本月最后一天
	const monthEndDay = monthEnd.getDay(); // 本月最后一天是周几
	const endTimeDate = new Date(year, month, 6 - monthEndDay); // 下个月第一个周六
	return {
		startTime: startTimeDate,
		endTime: endTimeDate,
	};
}

/** 比较两个日期对象是不是同一天 */
export function isSameDay(a?: DateLike | null, b?: DateLike | null) {
	if (!a || !b) {
		return false;
	}

	if (a === b) {
		return true;
	}

	const da = toDate(a);
	const db = toDate(b);

	if (da && db && da.toLocaleDateString() === db.toLocaleDateString()) {
		return true;
	}

	return false;
}

/** 比较两个日期对象是不是同一周 */
export function isSameWeek(a: DateLike, b: DateLike) {
	if (!a || !b) {
		return false;
	}

	if (a === b) {
		return true;
	}

	const da = toDate(a) as Date;
	const db = toDate(b) as Date;
	const weekStartA = toWeekStart(da);
	const weekStartB = toWeekStart(db);

	if (weekStartA && weekStartB && weekStartA.valueOf() === weekStartB.valueOf()) {
		return true;
	}

	return false;
}

/** 比较两个日期对象是不是同一月 */
export function isSameMonth(a: DateLike | null, b: DateLike | null) {
	if (!a || !b) {
		return false;
	}

	if (a === b) {
		return true;
	}

	const da = getDateInfo(a);
	const db = getDateInfo(b);
	if (da && db && da.year === db.year && da.month === db.month) {
		return true;
	}

	return false;
}

/** 获取当前日期 n 天以后的日期值 */
export function addDays(time: number | string | Date, amount: number) {
	const target = toDate(time);
	if (!target) {
		return null;
	}

	amount = parseInt(amount as any) || 0;
	const cloneDate = new Date(target.valueOf());
	cloneDate.setDate(cloneDate.getDate() + amount);
	return cloneDate;
}

/**
 * 比较给定日期位于其月份的第几周，若返回 0 表示传入日期格式有误
 * @param {number|string|Date} time
 * @param {number} weekStartsOn 一周开始的日子是周几，默认是周日
 */
export function getNthWeekOfMonth(time: DateLike, weekStartsOn: number) {
	weekStartsOn = weekStartsOn || 0;
	let weekNumber = 1;

	const target = toDate(time);
	if (!target) {
		return 0;
	}

	// TODO: may be null
	const dateInfo = getDateInfo(target) as DateInfo;
	const monthStart = new Date(dateInfo.year, dateInfo.month - 1, 1); // 本月 1 号
	const monthStartDay = monthStart.getDay(); // 1 号是周几
	let lastDayOfFirstWeek = 0; // 首周最后一天是周几
	if (monthStartDay >= weekStartsOn) {
		lastDayOfFirstWeek = weekStartsOn + 7 - monthStartDay;
	} else {
		lastDayOfFirstWeek = weekStartsOn - monthStartDay;
	}

	if (dateInfo.date > lastDayOfFirstWeek) {
		const remainingDaysAfterFirstWeek = dateInfo.date - lastDayOfFirstWeek;
		weekNumber = weekNumber + Math.ceil(remainingDaysAfterFirstWeek / 7);
	}

	return weekNumber;
}

let _serverTime = 0;
/** 获取本地存储的服务器时间（以最近一次请求为准） */
export function getServerTime() {
	return _serverTime || Date.now();
}
/** 设置本地存储的服务器时间 */
export function setServerTime(time: number | Date) {
	if (!time) {
		return;
	}

	if (typeof time === 'number') {
		_serverTime = time;
	}

	const timeStamp = new Date(time).valueOf();
	if (timeStamp) {
		_serverTime = timeStamp;
	}
}

/** timer promisify */
export function delay(wait: number) {
	return new Promise<void>((resolve) => {
		window.setTimeout(resolve, wait);
	});
}
