import qs from 'qs';
import { history } from '@store/history';

let navigatorTimer: number;
const dispatchNavigateEvent = (callabck: Function) => {
	navigatorTimer && window.clearTimeout(navigatorTimer);
	// 发送 navigate 的事件
	const navigateEvent = new CustomEvent('navigate', { detail: 'goBack' });

	window.dispatchEvent(navigateEvent);
	navigatorTimer = window.setTimeout(() => {
		callabck && callabck();
	}, 250);
};

export function goBack() {
	dispatchNavigateEvent(history.goBack);
}

/** 结构化 query */
export function qsParse(url?: string) {
	try {
		const href = url || window.location.href;
		if (!href || typeof href !== 'string') {
			return {};
		}
		const query = href.split('?')[1];
		return qs.parse(query, { ignoreQueryPrefix: true });
	} catch (err) {
		console.error('qs parse error', err);
	}
	return {};
}

/** 将对象转为 query 字符串并 encode */
export function qsEncode(data: Record<string, any>) {
	return qs.stringify(data, { encode: true });
}

/** 添加或覆盖 querystring */
export function qsMerge(params: Record<string, any>, url?: string) {
	const _url = url || window.location.href;
	if (typeof _url !== 'string' || !params || typeof params !== 'object') {
		return '';
	}

	try {
		const oldQs = qsParse(_url);
		const newQs = qs.stringify({ ...oldQs, ...params }, { encode: true });
		const host = _url.split('?')[0];
		return `${host}?${newQs}`;
	} catch (err) {
		console.error('qs merge error', err);
	}
	return _url;
}
