import { Token, UserInfo } from "states/interface";

const CONSTANTS = {
	userInfo: 'ac-userInfo',
	token: 'ac-token',
}

function setData (key: string, value?: any) {
	if (value === null || value === undefined || value === '') {
		localStorage.removeItem(key);
		return
	}
	let strValue: any = value;
	if (typeof value === 'object') {
		strValue = JSON.stringify(value);
	}
	localStorage.setItem(key, strValue);
}
function getData(key: string) {
	const value = localStorage.getItem(key);
	try {
		return value ? JSON.parse(value) : value;
	} catch (err) {
		console.log('localStorage.ts getData:', err);
		return value;
	}

}

const ACLocalStorage = {
	setUserInfo: (userInfo: UserInfo|undefined) => setData(CONSTANTS.userInfo, userInfo),
	getUserInfo: () => getData(CONSTANTS.userInfo) as UserInfo|undefined,
	setToken: (token: Token|undefined) => setData(CONSTANTS.token, token),
	getToken: () => getData(CONSTANTS.token) as Token|undefined,
}

export default ACLocalStorage;
