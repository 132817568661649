import React from 'react';
import { Provider } from 'react-redux';
import { store } from '@store/index';

export type BasePortalProps = {
	children?: React.ReactNode | ((onDestroy: () => void) => React.ReactNode);
	withStore?: boolean; // 是否可以获取 redux store
	onDestroy?: (params?: any) => void; // 销毁时触发
};

export default class BasePortal extends React.PureComponent<BasePortalProps> {
	static defaultProps = {
		children: null,
		onDestroy: () => null,
	};

	render() {
		const { children, withStore, onDestroy } = this.props;
		const content = typeof children === 'function' ? (children as any)(onDestroy) : children;
		if (withStore) {
			return <Provider store={store as any}>{content}</Provider>;
		}
		return content;
	}
}
