
import React, { useCallback, useEffect, useRef, useState } from 'react';
import cls from '@utils/css-select';
import { SelectProps, Option as OptionType } from './interface';
import Portal from 'components/portal';
import Menu from './menu';
import Input, { ArrowIcon } from 'components/input';
import Option from './option';
import styles from './styles.scss';
import { isMobile } from './utils';

const IsMobile = isMobile();

const cx = cls(styles);

export default function Select (props: SelectProps) {
  const {
    className, style, children, onDropChange, options, onSelect, onChange, selected,
    searchPlaceholder, onSearch, onClear, suffix, size, showArrow, notFoundContent,
    disabled, clearIcon, allowClearSearchValue, allowClear, allowSearch, zIndex, placeholder,
  } = props;
  const portalRef = useRef<Function>();
  const selectRef = useRef<HTMLDivElement>(null);
  const [hold, setHold] = useState<OptionType|undefined>();
  const [visible, setVisible] = useState<boolean>(false);
  const [position, setPosition] = useState<{left?: number; top?: number; position: 'absolute'}>({ position: 'absolute' });
  const onChangeHandle = (option: OptionType) => {
    onChange && onChange(option);
    setVisible(false);
    portalRef.current && portalRef.current();
    onDropChange && onDropChange(false);
  }

  // 版面外部点击
  const onClickOutSide = () => {
    setVisible(false);
    portalRef.current && portalRef.current();
    onDropChange && onDropChange(false);
  };

  // 点击打开版面
  const onClickHandle = () => {
    setVisible(!visible);
    if (!visible) {
      portalRef.current = Portal.show({
        content: (
          <Menu
            options={options}
            style={position}
            zIndex={(!zIndex && zIndex != 0) ? 10 : zIndex}
            onChange={onChangeHandle}
            onSelect={onSelect}
            selected={hold}
            allowClearSearchValue={allowClearSearchValue}
            allowSearch={allowSearch}
            searchPlaceholder={searchPlaceholder}
            onSearch={onSearch}
            notFoundContent={notFoundContent}
            onClickOutSide={onClickOutSide}
          >{children}</Menu>
        ),
        getContainer: () => IsMobile ? null : selectRef.current?.parentElement,
      });
    } else {
      portalRef.current && portalRef.current();
    }
    onDropChange && onDropChange(!visible);
  }

  // 锁定已选项
  useEffect(() => {
    if (!selected) return;
    if (children) {
      const child = children.find(item => {
        return item.props.value === selected.value && (item.props.label || item.props.children) === selected.label;
      }) || children.find(item => item.props.value === selected.value);
      if (child) {
        setHold(child.props.option || { label: child.props.label || child.props.children, value: child.props.value });
      } else {
        setHold(selected);
      }
      return
    }
    if (!options) return
    const child = options.find(item => item === selected || (item.value === selected.value && item.label === selected.label))
      || options.find(item => item.value === selected.value);
    setHold(child || selected);
  }, [children, options, selected]);

  // 版面定位
  // useEffect(() => {
  //   if (!selectRef.current) return;
  //   setPosition({
  //     top: selectRef.current.offsetTop + selectRef.current.clientHeight + 5,
  //     left: selectRef.current.offsetLeft,
  //     position: 'absolute',
  //   })
  // }, [selectRef.current]);
	/* 主动设置了定位后，如果 select 出现在弹窗内，弹窗内容有滚动后，导致面板不会跟着滚动，注释之后正常，
	select 必须用一个单独的块级元素包裹，也就是每一个 select 都要配一个单独的父级元素，不可与其他元素公用一个父级元素 */

  return (
    <div className={cx('select', className, { disabled, visible })} style={style} onClick={onClickHandle} ref={selectRef}>
      <Input
        value={hold?.label}
        placeholder={placeholder}
        suffix={showArrow ? <ArrowIcon droped={visible} /> : null}
        disabled={disabled}
				size={size}
        readOnly
      />
    </div>
  )
}
Select.Option = Option;
