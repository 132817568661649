import React, { useEffect, useState } from 'react';
import cls from '@utils/css-select'
import styles from './styles.scss';
import { isMobile } from 'components/select/utils';
import AcIcon from 'ac-components/ac-icon';

const cx = cls(styles)

export type BaseBtnPros = {
	type?: 'main' | 'minor' | 'txt' | 'primary';
	loading?: boolean;
	disabled?: boolean;
	size?: 'large' | 'medium';
	children: React.ReactNode;
	style?: React.CSSProperties;
	className?: string;
	onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

function RenderLoadingIcon() {
	return (
		<span className={cx('loading-icon')}>
			<AcIcon icon='icon-loading' />
		</span>
	)
}

function AcBtn(props: BaseBtnPros) {

	const { type = 'main', size = 'large', loading, disabled, children, style, className } = props
	const [btnDisabled, setBtnDisabled] = useState(false)

	useEffect(() => {
		if (typeof disabled === 'boolean') setBtnDisabled(disabled)
	}, [disabled])

	useEffect(() => {
		if (typeof loading === 'boolean') setBtnDisabled(loading)
	}, [loading])

	return (
		<button
			className={cx(
				'ac-btn', { [type]: type, [size]: size, loading }, className
			)}
			disabled={btnDisabled}
			style={style}
			onClick={props.onClick}>
			{children}
			{loading ? RenderLoadingIcon() : ''}
		</button>
	)
}
export default AcBtn
