import React from 'react';
import { getState } from 'jumpstate';
import { isMobile } from 'components/select/utils';
import Logo from './logo';
import NavMenu from '../nav-menu';
import styles from './styles.scss';

const IsMobile = isMobile();

type HeaderProps = {
	title?: React.ReactNode;
	style?: React.CSSProperties;
	isShowMenu?: boolean;
	isShowHeader?: boolean;
}
export default function Header(props: HeaderProps) {
	const { title = 'Online Tutoring', style, isShowMenu = true, isShowHeader = true } = props;
	const user = getState().user;

	return isShowHeader ? (
		<header className={styles.header} style={style}>
			<div className={styles.inner}>
				<Logo title={title} />
				{IsMobile && user && user.userId && (
					<div>邀请图片占位符</div>
				)}
				{isShowMenu && <NavMenu />}
			</div>
		</header>
	) : null
}
