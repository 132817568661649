import { appointmentSource } from '@services/test-form';

const setItem = (key: string) => {
	sessionStorage.setItem(key, new Date().getTime() + '');
};

const getItem = (key: string) => {
	return sessionStorage.getItem(key) || 0;
};

/* 首页 -- 记录打开时间 */
export const setOpenHomeTime = () => {
	setItem('main-home');
};

export const getOpenHomeTime = () => {
	return getItem('main-home');
};

/* 首页 -- 记录打开注册表单时间 */
export const setOpenHomeFormTime = () => {
	setItem('main-home-form');
};

export const getOpenHomeFormTime = () => {
	return getItem('main-home-form');
};

/* 1-on-1 -- 记录打开时间 */
export const setOpen1on1 = () => {
	setItem('1-on-1');
};

export const getOpen1on1 = () => {
	return getItem('1-on-1');
};

/* 1-on-1 -- 记录表单打开时间 */
export const setOpen1on1Form = () => {
	setItem('1-on-1-form');
};

export const getOpen1on1Form = () => {
	return getItem('1-on-1-form');
};

// 根据key 获取表单打开时长
export const getValByKey = (key: string) => {
	const result = window.location.href.indexOf('main-home') !== -1;
	const time = result ? getOpenHomeFormTime() : getOpen1on1Form();
	const obj: { [key: string]: number | undefined } = {
		landingPageId: result ? 1 : 2,
		duration: time ? new Date().getTime() - Number(time) : undefined,
	};
	return obj[key];
};

// 表单关闭事件
export const closeEvent = (sourceKey: string) => {
	appointmentSource({
		eventType: 4,
		landingPageId: getValByKey('landingPageId'),
		duration: getValByKey('duration'),
		sourceKey,
	});
};
