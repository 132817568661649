/* eslint-disable no-nested-ternary */
import classNames from 'classnames';
import React, { Children, FC, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';

interface Props {
	icon: string;
	color?: string;
	size?: number;
	className?: string;
	type?: 'success' | 'warn' | 'error';
	onClick?: () => void;
	htmlType?: string; // 默认是i svg
}

const AcIcon: FC<Props> = (props) => {
	const { icon, size, type, className, color, onClick, htmlType, ...other } = props;
	const iconRef = useRef<HTMLElement>(null);

	const cls = classNames('icon', 'iconfont', icon);
	// eslint-disable-next-line no-nested-ternary
	const typeColor = type === 'success' ? '#52c41a' : type === 'warn' ? '#ffa800' : '#ff2a24';
	const style = {
		fontSize: `${size}px`,
		color: type ? typeColor : color,
	};
	return (
		<>
			{className ? (
				<span className={className} onClick={onClick} {...other}>
					<i ref={iconRef} className={cls} style={style} />
				</span>
			) : htmlType === 'svg' ? (
				<svg aria-hidden="true" onClick={onClick} className="icon" style={style} {...other}>
					<use href={`#${icon}`}></use>
				</svg>
			) : (
				<i ref={iconRef} onClick={onClick} className={cls} style={style} {...other} />
			)}
		</>
	);
};
export default AcIcon;
