import './styles/global.scss';
import React, { useEffect, useState } from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import RouterLayout from './routers';
import { configureStore } from './store';
import { initZMSDK } from '@core/track';
import localeLoaders from './locales';
import i18n, { I18nProvider } from '@fe/i18n';
import globalStore from 'states/app';
import { COUNTRYCODE_LANG_MAP, LANG } from 'constants/country';
import { history } from '@store/history';
import { LOGIN } from 'constants/url';
import { isMobile } from 'components/select/utils';

const rootEle = document.getElementById('app');

const mobile = isMobile();
if (mobile) {
	document.body.classList.add('mobile');
}

(function () {
	window.intercomSettings = {
		app_id: "u726uocn",
		name: globalStore().user?.userId || '官网'
	}
})()

// store状态
const store: any = configureStore();

const { pathname } = history.location;

/** 初始化操作，优先级最高 */
function init() {
	initZMSDK(); // 初始化埋点参数
}

// 初始化相关sdk
init();

function getDefaultCountry() {
	const pathReg = /^\/(\w+[^/])/i;
	const match = pathReg.exec(pathname);
	const lang = navigator.language.split('-')[0];
	//落地页单独处理
	if (pathname.includes('/apply-tutor') && !pathname.includes('/apply-tutor/success')) {
		if (match && match[1] && Object.prototype.hasOwnProperty.call(COUNTRYCODE_LANG_MAP, match[1].toLocaleUpperCase())) {
			return match[1].toLocaleLowerCase();
		}
	}

	// 如果有匹配的语言，则返回该语言，否则返回默认语言
	if (LANG.find(item => item.code === lang)) {
		return lang
	}
	return 'en';
}

// 匹配国家
function getDefaultCountryLang() {
	const code = getDefaultCountry();
	const lang = navigator.language.split('-')[0];
	const storageLang = localStorage.getItem('__lang')
	//落地页单独处理
	if (pathname.includes('/apply-tutor') && !pathname.includes('/apply-tutor/success')) {
		return COUNTRYCODE_LANG_MAP[code.toLocaleUpperCase()] || COUNTRYCODE_LANG_MAP['EN']
	}
	if (storageLang) {
		const langSplit = storageLang.split('-')[0]
		if (langSplit !== code) {
			return storageLang
		}
	}


	// 如果有匹配的语言，则返回该语言，否则返回默认语言
	if (LANG.find(item => item.code === lang)) {
		return lang
	}

	return code;
}

/**
 * Root节点
 *
 * @returns
 */
function App() {
	const [lang, setLang] = useState(getDefaultCountryLang());
	const [countryCode, setCountryCode] = useState(getDefaultCountry());
	// TODO: 官网的和落地页的多语言支持有冲突，需要重新设计
	useEffect(() => {
		// 系统语言变化
		i18n.on('localechange', (locale, prev) => {
			const localeSplit = locale.split('-')[0]
			if (pathname.includes('/apply-tutor') && !pathname.includes('/apply-tutor/success')) {
				setLang(locale);
				globalStore.setLocal(locale);
			} else {
				setLang(localeSplit);
				globalStore.setLocal(localeSplit);
			}
		});
		setCountryCode(countryCode);
		globalStore.setCountryCode(countryCode);
		const isRegister = store.getState().app.token?.isRegister;
		if (isRegister) { // 未填写个人信息
			history.push(LOGIN);
		}
	}, []);


	return (
		<I18nProvider lang={lang} localeLoaders={localeLoaders}>
			<Provider store={store}>
				<RouterLayout />
			</Provider>
		</I18nProvider>
	);
}

render(<App />, rootEle);
