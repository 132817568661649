import React from 'react';
import AcBtn from './ac-btn';
import AcIpt from './ac-ipt';
import AcIcon from './ac-icon';
export default function BasicComponentIndex() {
	function pwdChangeHandler(e: React.ChangeEvent<HTMLInputElement>) {
		console.log(e.target.value);
	}

	function iptSuffix() {
		return (
			<AcIcon icon={'icon_eyeclosed'} />
		)
	}

	function handlePwdTypeChange(newType: string) {
		console.log(newType);
	}
	return (
		<div style={{
			display: 'grid',
			gridTemplateColumns: `repeat(3, 1fr)`,
			gridGap: '100px',
		}}>

			<AcBtn style={{ 'width': 200 }} loading={true}>qweqwe</AcBtn>
			<AcBtn style={{ 'width': 200 }} disabled>qweqwe</AcBtn>
			<AcBtn type='minor' style={{ 'width': 200 }}>qweqwe</AcBtn>
			<AcBtn type='minor' disabled style={{ 'width': 200 }} loading={true}>qweqwe</AcBtn>
			<AcBtn type='txt' style={{ 'width': 100 }}>qweqwe</AcBtn>
			<AcBtn type='primary' style={{ 'width': 100 }}>qweqwe</AcBtn>
			<div style={{ 'padding': '20px' }}>
				<AcIpt error='sadasd' style={{ 'width': 200 }} onChange={(e: React.ChangeEvent<HTMLInputElement>) => pwdChangeHandler(e)} />
			</div>
			<div style={{ 'padding': '20px' }}>
				<AcIpt placeholder='Password' type='password' style={{ 'width': 200 }} pwdChange={handlePwdTypeChange} />
			</div>
			<div style={{ 'padding': '20px' }}>
				<AcIpt placeholder='Password' type='number' style={{ 'width': 200 }} />
			</div>
			<div style={{ 'padding': '20px' }}>
				<AcIpt style={{ 'width': 200 }} readonly />
			</div>
			<div style={{ 'padding': '20px' }}>
				<AcIpt style={{ 'width': 200 }} value='asdasd' suffix={iptSuffix()} />
			</div>
			<div style={{ 'display': 'inline-block' }}>
				<AcBtn>ash</AcBtn>
			</div>
			<div >
				<span>测试icon</span>
				<AcIcon icon={'icon_phone'} />
				<AcIcon icon={'icon_language'} color={'red'} size={100} />
			</div>

		</div>
	)
}
