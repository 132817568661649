import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import cls from '@utils/css-select';
import { InputProps } from './interface';
import styles from './styles.scss';

const cx = cls(styles);
interface InputRef {
	blur: () => void;
	focus: () => void;
	input: HTMLInputElement | null;
}

export default React.forwardRef(function Input(props: InputProps, ref: React.Ref<InputRef>) {
	const {
		className,
		style,
		onChange,
		value,
		placeholder,
		onClear,
		onEnter,
		prefix,
		allowClear,
		type,
		readOnly,
		disabled,
		suffix,
		onBlur,
		onFocus,
		maxLength,
		size = 'middle',
		...otherProps
	} = props;
	const [hold, setHold] = useState<string>(value || '');
	const inputRef = useRef<HTMLInputElement>(null);
	const customPlaceholder = !!placeholder && typeof placeholder !== 'string';
	const onlyInput = !prefix && !suffix && allowClear !== false && !customPlaceholder;
	const [inputWidth, setInputWidth] = useState<number>(0);
	const blur = () => {
		inputRef.current && inputRef.current.blur();
	};
	const focus = () => {
		inputRef.current && inputRef.current.focus();
	};
	const clickHandle = () => {
		focus();
		if (otherProps.onClick && typeof otherProps.onClick === 'function') {
			otherProps.onClick();
		}
	}
	const onChangeHandle = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = (e.target as HTMLInputElement).value;
		setHold(value);
		onChange && onChange(value);
	};
	const onKeyPressHandle = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.keyCode === 13) {
			onEnter && onEnter(hold);
		}
	};
	const onClearHandle = () => {
		setHold('');
		onClear && onClear();
	};
	const onResizeHandle = () => {
		inputRef.current?.offsetWidth && setInputWidth(inputRef.current?.offsetWidth);
	}
	useEffect(() => {
		if (!customPlaceholder) return
		window.addEventListener('resize', onResizeHandle);
		return () => window.removeEventListener('resize', onResizeHandle);
	}, [customPlaceholder])
	useEffect(() => {
		inputRef.current?.offsetWidth && setInputWidth(inputRef.current?.offsetWidth);
	}, [inputRef.current])
	useEffect(() => setHold(value || ''), [value]);
	useImperativeHandle(
		ref,
		() => ({
			blur,
			focus,
			input: inputRef.current,
		}),
		[inputRef.current]
	);
	const rawInput = (
		<input
			ref={inputRef}
			type={type || 'text'}
			value={hold}
			placeholder={customPlaceholder ? '' : placeholder as string}
			onChange={onChangeHandle}
			onKeyPress={onKeyPressHandle}
			style={onlyInput ? style : undefined}
			onFocus={onFocus}
			onBlur={onBlur}
			readOnly={readOnly}
			disabled={disabled}
			maxLength={maxLength}
			className={cx('raw-input', {
				'input': onlyInput,
				[className as string]: onlyInput && className,
				[size]: onlyInput,
				readOnly,
				disabled,
			})}
			{...(onlyInput ? otherProps : {})}
		/>
	);
	return onlyInput ? (
		rawInput
	) : (
		<div
			className={cx('input', 'compose-input', className, size, {
				'allow-clear': allowClear,
				'has-prefix': !!prefix,
				'has-suffix': !!suffix,
				readOnly,
				disabled,
			})}
			style={style}
			onClick={clickHandle}
			{...otherProps}
		>
			{prefix && <span className={cx('prefixer')}>{prefix}</span>}
			{rawInput}
			{customPlaceholder && hold.length === 0 && <span className={cx('placeholder')} style={{ width: inputWidth || 'unset'}}>{placeholder}</span>}
			{allowClear && (
				<span className={cx('clear')} onClick={onClearHandle}>
					<ClearIcon />
				</span>
			)}
			{suffix && <span className={cx('suffixer')}>{suffix}</span>}
		</div>
	);
});

const SearchImg = 'https://web-data.zmlearn.com/image/5xPqw6KufEHieWevD5CqGT/Icon_search@2x.png';
const ClearImg = 'https://web-data.zmlearn.com/image/icpWAR3pSHXe974ie2r2td/icon_close.png';
export const SearchIcon = () => (
	<span className={cx('input-search-icon')}>
		<img loading="lazy" src={SearchImg} alt="" />
	</span>
);
interface ArrowIconProps {
	droped: boolean;
}
export const ArrowIcon = (props: ArrowIconProps) => <span className={cx('input-arrow-icon', { droped: props.droped })}></span>;
export const ClearIcon = () => (
	<span className={cx('input-clear-icon')}>
		<img loading="lazy" src={ClearImg} alt="" />
	</span>
);
