
export const SEOCONTENT: { [key: string]: { title: string; desc: string; keyword: string } } = {
	us: {
		title: 'Online Tutoring - Choose from the BEST TUTORS in the USA | AirClass 1ON1',
		desc: 'AirClass offers tutoring for children K-12, in all the core subjects and Test preparation for STAR, AP exams, ISEE, SAT, ACT, GED, MCAT, ASVAB, and LSAT.',
		keyword: 'math, tutor,science, biology,chemistry,english,teaching,history,geography,sociology, STAR, AP exams, ISEE, SAT, ACT, GED, MCAT, ASVAB, LSAT'
	},
	ca: {
		title: 'Online Tutoring - Choose from the BEST TUTORS in Canda | AirClass 1ON1',
		desc: 'AirClass is a tutoring company specializing in private online tutoring. All levels students can get support with math, science, engineering, writing, test prep and more from our world-class professional private tutors.',
		keyword: 'math, tutor,science, biology,chemistry,english,teaching,history,geography,sociology, Primary, secondary education, AP exams'
	},
	ie: {
		title: 'Online Tutoring - Immersive learning with the best online Irish tutors | AirClass 1ON1',
		desc: 'AirClass is a tutoring company specializing in private online tutoring. All levels students can get support with math, science, engineering, writing, test prep and more from our world-class professional private tutors.',
		keyword: 'math, tutor,science, biology,chemistry,english,teaching,history,geography,sociology, Junior Cert，Leaving Cert, A level'
	},
	au: {
		title: 'Online Tutoring - Online Australian Studies Tutoring Near You | AirClass 1ON1',
		desc: 'AirClass is a tutoring company specializing in private online tutoring. All levels students can get support with math, science, engineering, writing, test prep and more from our world-class professional private tutors.',
		keyword: 'math, tutor,science, biology,chemistry,english,teaching,history,geography,sociology, Primary, secondary education, A level.'
	},
	nz: {
		title: 'Online Tutoring - Find private tutors in your area of New Zealand | AirClass 1ON1',
		desc: 'AirClass is a tutoring company specializing in private online tutoring. All levels students can get support with math, science, engineering, writing, test prep and more from our world-class professional private tutors.',
		keyword: 'math, tutor,science, biology,chemistry,english,teaching,history,geography,sociology, NZC, NCEA, CIE, IB'
	},
	default: {
		title: 'Online Tutoring - Choose from the BEST TUTORS | AirClass 1ON1',
		desc: 'Looking for the best online face to face tutoring?Why not join the 150,000+ parents and students who found their perfect tutor on AirClass.Access the best tutors at affordable prices thanks to our intelligent matching and 100’s of collective years of Educational expertise.',
		keyword: 'tutor,biology,chemistry,A level,english,teaching,gcse,history,geography,sociology，IB, HSC, SAT, ACT'
	},
	'/main-home': {
		title: 'AirClass| private one to one remote tutoring| online tutoring site',
		desc: 'AirClass, an online tutoring platform with experienced UK local teachers and at an affordable price can help students raise good grades, improve academic performance and improve study habits. Our tutors can tutoring at home, and we have wonderful tutoring service review',
		keyword:''
	},
	'/es/tutoria1a1': {
		title: 'All subjects & levels| GCSE, A Level, 7+, 11+ or 13+| examination',
		desc: 'AirClass 1-on-1 offer tailored tutoring to our students to maximise their chances of success. Giving you the best opportunity to choose your next school. We have a level economics teachers, sociology teachers, online latin teachers and so on. We have classes below: verbal reasoning, non-verbal reasoning, maths, English, physics, chemistry, history essay writing and all other subjects.s',
		keyword:''
	},
	'/en/1on1tutoring': {
		title: 'All subjects & levels| GCSE, A Level, 7+, 11+ or 13+| examination',
		desc: 'AirClass 1-on-1 offer tailored tutoring to our students to maximise their chances of success. Giving you the best opportunity to choose your next school. We have a level economics teachers, sociology teachers, online latin teachers and so on. We have classes below: verbal reasoning, non-verbal reasoning, maths, English, physics, chemistry, history essay writing and all other subjects.s',
		keyword:''
	},
	'/es/happy-kids': {
		title: 'Early year learning|AirClass',
		desc: "Kids classes at AirClass are aimed to help children's brain development. Our popular courses are think math, critical thinking in math, algebraic thinking and mathematical thinking. We also offer differentiated education, beginner english, beginner spelling, English grammar, English speaking to our students.",
		keyword:''
	},
	'/en/kidslearning': {
		title: 'Early year learning|AirClass',
		desc: "Kids classes at AirClass are aimed to help children's brain development. Our popular courses are think math, critical thinking in math, algebraic thinking and mathematical thinking. We also offer differentiated education, beginner english, beginner spelling, English grammar, English speaking to our students.",
		keyword:''
	},
	'/en/tutors': {
		title: 'Finding a good tutor| AirClass',
		desc: "A great tutor need to have well-experienced, have a deep grasp of education curriculum. We always match qualified, expert UK local tutors are vetted and referenced by us and all have a current Enhanced DBS certificate.",
		keyword:''
	},
	'/es/tutors': {
		title: 'Finding a good tutor| AirClass',
		desc: "A great tutor need to have well-experienced, have a deep grasp of education curriculum. We always match qualified, expert UK local tutors are vetted and referenced by us and all have a current Enhanced DBS certificate.",
		keyword:''
	}
}
