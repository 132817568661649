import { CHANNELINFO } from 'constants/channelInfo';
import { qsParse } from './url-utils';

/**
 * @description  兼容没有countryCode在CHANNELINFO中没有报错的情况
 * 这是个业务的处理，不是公共的hook
 * 不同语言的渠道信息处理
 * @date 2022-02-24
 * @export
 * @param {string} countryCode
 * @returns {*}  
 */
export function getChannelInfo(countryCode: string) {
    const { channel_code, channel_keyword } = qsParse();
    if (channel_code && channel_keyword) {
        return { channel_code, channel_keyword };
    }
    return CHANNELINFO[countryCode] ? CHANNELINFO[countryCode] : CHANNELINFO['en'];
}