import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import timezone from 'dayjs/plugin/timezone';
import generatePath from '@utils/generatePath';
import { useTranslation } from 'hooks/use-translation';
import { PERSONAL } from 'constants/url';
import { getRecentlyLessonInfo } from '@services/lesson';
import AcIcon from 'ac-components/ac-icon';
import styles from './styles.scss';
import { useSelector } from 'react-redux';
import { ConnectState } from 'states/connect';
import { GlobalStoreState } from 'states/app';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);

const isAfterToday = (time: number): boolean => {
	const tomorrow = dayjs().endOf('day');

	return dayjs(time) > tomorrow;
};

export default function CourseRemind() {
	const { t, transData } = useTranslation('header');
	const [content, setContent] = useState('');
	const { token }: GlobalStoreState = useSelector((state: ConnectState) => state.app);

	const generateRemindContent = (time: number) => {
		const formatStr = isAfterToday(time) ? 'HH:mm Do MMM' : 'HH:mm';
		const timeStr = dayjs.tz(time, 'Asia/Shanghai').local().format(formatStr);
		const content = t('header.remindContent', { time: timeStr });
		setContent(content);
	};

	const history = useHistory();
	const [visible, setVisible] = useState(false);

	// useEffect(() => {
	// 	if (!history.location.pathname.includes('/main-home')) return;

	// 	getRecentlyLessonInfo().then((res) => {
	// 		// generateRemindContent(new Date().getTime());
	// 		// setVisible(true);
	// 		if (res.data) {
	// 			generateRemindContent(res.data.lessonStartTime);
	// 			setVisible(true);
	// 		}
	// 	});
	// }, []);

	const getData = async () => {
		try {
			const res = await getRecentlyLessonInfo();
			if (res && res.data) {
				generateRemindContent(res.data.lessonStartTime);
				setVisible(true);
			}
		} catch (err) {
			console.log(err);
		}
	};

	useEffect(() => {
		if (!history.location.pathname.includes('/main-home') || !token) {
			setVisible(false);
			return;
		}
		getData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [history.location.pathname, token]);

	const handleToPersonal = (): void => {
		history.push(generatePath(PERSONAL, { activeId: '1' }));
		setVisible(false);
	};

	return (
		<>
			{visible && (
				<div className={styles.course_remind}>
					<div className={styles.course_remind_close} onClick={() => setVisible(false)}>
						<AcIcon icon="icon_close" />
					</div>
					<div className={styles.course_remind_content}>{content}</div>
					<div className={styles.course_remind_btn} onClick={handleToPersonal}>
						{transData.check}
					</div>
				</div>
			)}
		</>
	);
}
